<template>
  <a href="#" @click="click_hotelInfo" class="link link--icon link--icon--left">
    <i class="icon icon-farmers-market-vendor"></i>
    <!-- Ein Wechsel übr hotel.isCaravan klappt nicht, weil hotel leer ist?  -->
      <span>{{ $t("info_hotelinformation") }}</span>
  </a>
</template>

<script>
import dev from "@/lib/devtools";

export default {
  props: ["hotelkey", "hotel"],
  mixins: [dev],
  methods: {
    click_hotelInfo: function () {
      // dev.hotelInformationen();
      this.$store.commit("statusHotelInfoKey", this.hotelkey);
      this.$store.commit("statusHotelInfo", true);
      if (this.hotel) {
        this.$store.commit("selectedHotel", this.hotel);
      }
    },
  },
};
</script>

<style></style>
