// https://vuex.vuejs.org/guide/state.html
// state:     Variablen .. der Speicher
//              Zugriff: this.$store.state.<VariablenName>
//              $store.state.showHotelSearch
// getters:   Einfache Funktion die ein Ergebnis zurückliefern - so wie computed in den Komponenten
//              Aufruf: this.$store.getters.<Funktion>
//              this.$store.getters['getVARID']);
// mutations: Funktion die store-Variablen ändert 
//              Aufruf: this.$store.commit('<funktion>', parameter)
// actions:   Funktionen die 'mutationens' aufrufen, z.B. für asynchrone Dinge
//              Z.B.: increment (context) {  context.commit('increment') } 
//              Aufruf: this.$store.dispatch('increment')
// modules: 
 
//  entfernter Aufruf: 
// import Vuex from 'vuex';
// methodes{ 
//    ...Vuex.mapActions([
//      'setCounter' // Funktionen die man nutzen möchet auflisten
//    ]),
//  ...
//  this.setCounter(20);

//   state,      // same as `store.state`, or local state if in modules
//   rootState,  // same as `store.state`, only in modules
//   commit,     // same as `store.commit`
//   dispatch,   // same as `store.dispatch`
//   getters,    // same as `store.getters`, or local getters if in modules
//   rootGetters // same as `store.getters`, only in modules
import Vue from 'vue'
import Vuex from 'vuex'
import hop from '@/lib/hoptools'
import axios from 'axios'
import dev from '@/lib/devtools';

Vue.use(Vuex)

export default new Vuex.Store({
  mixins: [dev],
  strict: true,
  state: {
    isTest: false,

    showError: false,
    showErrorCaptcha: false,

    status: 'search', 
    // 'search' - Suche Hotel; 
    // 'select' - Auswahl Hotel; 
    // 'formular' - Formular-Dateneingabe; 
    // 'final' - Abschlussmedlung der Buchung
    // 'tooManyPeople' - Auswahl enthält mehr als ~7 Personen, Gruppenbuchung
    // 'kontakt' - Kontakt Seite^
    // 'vanotfound' - Formular nicht gefunden
    // 'notprebooking' - das Merken eines Hotels ist verfallen und nicht mehr verfügbar

    formcycle: "",
    statusGruppenFormular: false, // für Form,ular Gruppenbuchung
    statusHotelInfo: false, // für HotelDetailanzeige und zurück
    statusHotelInfoKey: '', // Key (UNID) des Hotels das angezeigt werden soll

    hotelSearchHasElements: false, // Suchseite - Hotelsuche hat Ergebnisse ...
    showHotelSearch: true,

    hotelSearchResult: {
      count: 0,
      hotels: []
    }, 

    veranstaltungen: [], // Auswahl verfügbarer Veranstaltungen; Ergebnis von cmd=vas
    vaName: '',       // Name - gewählte Veranstaltung
    vaStartDate: '', //'23.11.2020',  // Startdatum - gewählte Veranstaltung 
    vaEndDate : '', //'27.11.2020',   // Enddatum - gewählte Veranstaltung
    vaObj: {},           // ?? als Object  - gewählte Veranstaltung


    ez: '1',          // Anzahl Einzelzimmer
    dz: '-',          // Anzahl Doppelzimmer

    precode: '', // Vorreservierung

    // Für das Suchergebnis
    vaIDSearch: '',
    vaNameSearch: '',
    vaStartDateSearch: '',
    vaEndDateSearch: '',
    ezSearch: '',
    dzSearch: '',
    vaObjSearch: {},
    vaStartDateWunsch: '',
    vaEndDateWunsch: '',
    isDateWunsch: false,

    selectedHotel: {},
    bcode: "", // Buchungscode, finale Buchungsbestätigung / Rückgabe aus Notes 
    banrede: "",
    bnachname: "",
    bvorname: "",

    key_search_start: 1000,
    key_search_end: 5000,
    
    // VA_NewstartDate: '', // Datum soll von Komponente gesetzt werden 

  },
  mutations: {
    precode(state, precode){
      state.precode = precode;
    },
    showErrorCaptcha(state, showErrorCaptcha){
      state.showErrorCaptcha = showErrorCaptcha; 
    },
    showError(state, showError){
      state.showError = showError; 
    },
    status(state, status){
      state.status = status;
    },
    formcycle(state, formcycle){
      state.formcycle = formcycle;
    },
    statusGruppenFormular(state, statusGruppenFormular){
      state.statusGruppenFormular = statusGruppenFormular;
    }, 
    statusHotelInfo(state, statusHotelInfo){
      state.statusHotelInfo = statusHotelInfo;
    }, 
    statusHotelInfoKey(state, statusHotelInfoKey){
      state.statusHotelInfoKey = statusHotelInfoKey;
    },
    veranstaltungen(state, veranstaltungen){
      state.veranstaltungen = veranstaltungen;
    },
    vaName(state, vaName) {
      state.vaName = vaName;
    },
    vaStartDate(state, vaStartDate){
      // const lg = dev.getUrlParameter("lg")
      // if (lg == "en") {
      //   state.vaStartDate = dev.convertDateString(vaStartDate)
      // } else {
      //   state.vaStartDate = vaStartDate
      // } 
      // console.log("store-vaStartDate=" + vaStartDate + " / " + dev.convertDateString(vaStartDate)  )
        state.vaStartDate = vaStartDate
    },
    vaEndDate(state,vaEndDate){
      // console.log("store-vaEndDate=" + vaEndDate)
      // const lg = dev.getUrlParameter("lg")
      // if (lg == "en") {
      //   state.vaEndDate = dev.convertDateString(vaEndDate)
      // } else {
      //   state.vaEndDate = vaEndDate;
      // } 
      // console.log("store-vaEndDate=" + vaEndDate)
      
        state.vaEndDate = vaEndDate;
    },
    vaObj(state,vaObj){
      state.vaObj = vaObj;
    },
    ez(state,ez){
      state.ez = ez;
    },
    dz(state,dz){
      state.dz = dz;
    },

    vaIDSearch(state, vaIDSearch){
      state.vaIDSearch = vaIDSearch
    },
    vaNameSearch(state, vaName) {
      state.vaNameSearch = vaName;
    },
    vaStartDateSearch(state, vaStartDate){
      state.vaStartDateSearch = vaStartDate
    },
    vaEndDateSearch(state,vaEndDate){
      state.vaEndDateSearch = vaEndDate;
    },
    vaStartDateWunsch(state, vaStartDate){
      state.vaStartDateWunsch = vaStartDate
    },
    vaEndDateWunsch(state,vaEndDate){
      state.vaEndDateWunsch = vaEndDate;
    },
    isDateWunsch(state, isWunsch) {
      state.isDateWunsch = isWunsch
    }, 
    ezSearch(state,ez){
      state.ezSearch = ez;
    },
    dzSearch(state,dz){
      state.dzSearch = dz;
    },
    vaObjSearch(state,vaObj){
      state.vaObjSearch = vaObj;
    },

    selectedHotel(state,selectedHotel){
      state.selectedHotel = selectedHotel;
    },
    bcode(state, bcode){
      state.bcode = bcode;  
    },  
    banrede(state,banrede){
      state.banrede = banrede;
    },
    bnachname(state, bnachname){
      state.bnachname = bnachname;
    },
    bvorname(state,bvorname){
      state.bvorname = bvorname;
    },
    key_search_start(state, key_search_start) {
      state.key_search_start = key_search_start;
    },
    key_search_end(state, key_search_end) {
      state.key_search_end = key_search_end;
    },
    hotelSearchResult(state,hotelSearchResult){
      state.hotelSearchResult = hotelSearchResult;
    },
    hotels(state, hotels){
      state.hotelSearchResult.hotels = hotels;
      state.hotelSearchResult.count = hotels.length
    }
  },
  getters: {
    getPrecode(state){
      return state.precode
    },
    getCountEZ(state) {
      return state.ez == '-' ? 0 : state.ez; 
    },
    // getstatusGruppenFormular(state){
    //   return state.statusGruppenFormular
    // },
    getstatusHotelInfoKey(state){
      return state.statusHotelInfoKey
    },
    getCountDZ(state) {
      return state.dz == '-' ? 0 : state.dz; 
    },
    vaDate(state,typ){
      // console.log("vaDAte-Typ=" + typ);
      return typ === 'start' ? state.vaStartDate : state.vaEndDate
    },
    getCountZimmer(state) {
      let count = 0;
      if (state.ez != "-" && state.ez != "") {
          count += parseInt(state.ez);
      }
      if (state.dz != "-" && state.dz != "") {
          count += parseInt(state.dz);
      }
      return count;
    },
    getCountPersonen(state){
      var count = 0;
      if (state.ez != "-" && state.ez != "") {
          count = count + parseInt(state.ez);
      }
      if (state.dz != "-" && state.dz != "") {
          count = count + (2 * parseInt(state.dz));
      }
      return count;
    },
    getRoomNights(state) {
      let countZ = 0;
      if (state.ez != "-" && state.ez != "") {
          countZ += parseInt(state.ez);
      }
      if (state.dz != "-" && state.dz != "") {
          countZ += parseInt(state.dz);
      }

      let countR = 0;
      if (state.vaStartDate == "" || state.vaStartDate == undefined || state.vaEndDate == "" || state.vaEndDate == undefined) {
        countR = 0;
    } else {
      var dat1 = dev.getDateStringDE(dev.getDate(state.vaStartDate)).split(".");
      var dat2 = dev.getDateStringDE(dev.getDate(state.vaEndDate)).split(".");
  
      var von = new Date(dat1[1] + "/" + dat1[0] + "/" + dat1[2]);
      var bis = new Date(dat2[1] + "/" + dat2[0] + "/" + dat2[2]);
      // console.log("von=" + von);
      // console.log("bis=" + bis);
  
      var dif = (bis.getTime() - von.getTime());
      // console.log("1. Dif=" + dif);
      countR = dif / (1000 * 60 * 60 * 24);
  
    }
    // countZ *
      return countZ *  countR;
    },
    getVAID: function (state) {
      let id = "";
      state.veranstaltungen.forEach(element => {
          if (element.name == state.vaName || element.nameen == state.vaName) {
              id = element.rid;
          }
      });
      return id;
    },
      // gibt die Anzahl der gebuchten Tage zurück 
    getBookingDays: function (state) {
      if (state.vaStartDate == "" || state.vaStartDate == undefined || state.vaEndDate == "" || state.vaEndDate == undefined) {
          return 0;
      }
      // dev.logg("state.vaStartDate == " + state.vaStartDate)
      var dat1 = dev.getDateStringDE(dev.getDate(state.vaStartDate)).split(".");
      var dat2 = dev.getDateStringDE(dev.getDate(state.vaEndDate)).split(".");

      var von = new Date(dat1[1] + "/" + dat1[0] + "/" + dat1[2]);
      var bis = new Date(dat2[1] + "/" + dat2[0] + "/" + dat2[2]);
      // console.log("von=" + von);
      // console.log("bis=" + bis);

      var dif = (bis.getTime() - von.getTime());
      // console.log("1. Dif=" + dif);
      dif = dif / (1000 * 60 * 60 * 24);
      return dif;
  },
  },
  actions: { 
    sortHotelsByFilter({state, getters}, prop, direct){
      if (!Array.isArray(state.hotelSearchResult.hotels)) throw new Error("FIRST ARGUMENT NOT AN ARRAY");
      if( state.hotelSearchResult.hotels.length == 0 ) {
        return;
      }
      let hotelSearchResult = state.hotelSearchResult; 
      let clone = state.hotelSearchResult.hotels.slice(0); 
      
      function compareEZ(a,b) {
        return parseInt(b.ez, 10) - parseInt(a.ez, 10);
      }
      function compareEZAuf(a,b) {
        return parseInt(a.ez, 10) - parseInt(b.ez, 10);
      }
      function compareName(a,b) {
        return a.namesearch > b.namesearch ? 1 : a.namesearch < b.namesearch ? -1 : 0; 
      }
      function compareEntfernung(a,b) { 
        if (state.vaObj.isZoo) {
          return parseFloat(a.kmkongress.replace(',','.')*10, 10) - parseFloat(b.kmkongress.replace(',','.')*10, 10);
        } else {
          return parseFloat(a.kmmesse.replace(',','.')*10, 10) - parseFloat(b.kmmesse.replace(',','.')*10, 10);
        }
      }
      function compareKat(a,b) {
        return parseInt(b.rating, 10) - parseInt(a.rating, 10);
      }
      function compareKatAuf(a,b) {
        return parseInt(a.rating, 10) - parseInt(b.rating, 10);
      }

      if( prop == "Entfernung") {
        // console.log("sort Entfernung");
        clone.sort(compareEntfernung);
      } else if( prop == "Name" ){
        // console.log("sort Name");
        clone.sort(compareName);
      } else if( prop == "Preis" ){
        // console.log("sort Preis absteigend");
        clone.sort(compareEZ);
      } else if( prop == "PreisAuf"){
        // console.log("sort Preis aufsteigend");
        clone.sort(compareEZAuf);
      } else if( prop == "Kategorie") {
        // console.log("sort Kategorie absteigend");
        clone.sort(compareKat);
      } else {
        // console.log("sort Kategorie aufsteigend");
        clone.sort(compareKatAuf);
        // KategorieAuf = Kategorie Aufsteigend
      }
      this.commit('hotels', clone);

    },
    pushNewDate(state){
      this.commit('key_search_start');
      this.commit('key_search_end');
    },
    veranstaltungen(state, veranstaltungen){
      this.commit('veranstaltungen', veranstaltungen);
    },
    setSearchData(state){
      this.commit('vaNameSearch', state.vaName)
      this.commit('vaStartDateSearch', state.vaStartDate)
      this.commit('vaEndDateSearch', state.vaEndDate)
      this.commit('dzSearch', state.dz)
      this.commit('ezSearch', state.ez)
    },
    changeVAName({state,commit, getters}, vaName) {
      let vas = state.veranstaltungen;
      if (vas === undefined) {
        console.error("state.veranstaltungen == undefined");
      } else {
        // console.log("suche nach " + vaName);
        let va = vas.find( (o,i) => {
          if(o.name.trim() == vaName.trim() || o.nameen.trim() == vaName.trim())
              return true;
         }); 
        // console.log("setze VA-Daten für >>" + vaName + "=>" + JSON.stringify(va));
         commit('vaName', vaName);
         commit('vaStartDate', dev.getDateStringDE(dev.getDate(va.start)));
         commit('vaEndDate', dev.getDateStringDE(dev.getDate(va.ende)));
        //  commit('vaStartDate', null);
        //  commit('vaEndDate', null);
         commit('vaObj', va);
      }
    },
    setCounter(state, newCount) {
      state.commit('increment', newCount);
    }
  }
})
