<template>
  <div>
    <!-- <div class="row"> -->
    <div class="card js-card">
      <div class="card__title-row card__title-row--highlight">
        <div class="card__title__left">{{ $t("final_eingang") }}</div>
        <div class="card__title-row__right">
          <span class="card__action-button card__action-button--bookmark btn btn--unstyled"><i aria-label="Bookmark" class="icon icon-rating-star"/></span>
        </div>
      </div>
      

      <div class="card__content-row">
        <div class="row">

<!-- verbunden mit formcycle        -->
          <!-- <div class="alert alert--danger" role="alert">
            <strong>{{ $t("final_hinweis_beginn") }}</strong>  {{ $t("final_hinweis_ende") }}<hr> 
          <a @click="click_openFormular" href="javascript:"  class="link link--icon link--icon--left">
            <i class="icon icon-download-thick-bottom" aria-label="Download"></i>&#160; 
            <span>{{ $t("final_button_öffneKKFormular") }}</span></a>
          </div> -->

          <h4>{{ $store.state.banrede }} {{ $store.state.bnachname }}, {{ $store.state.bvorname }} </h4>
        </div>

        <div class="row">
          <div class="col-3">{{ $t("final_buchungscode") }}:</div>
          <div class="col-9">{{ $store.state.bcode }}</div>
        </div>

        <div class="row">
          <div class="col-3">{{ $t("final_gewählteVeranstaltung") }}:</div>
          <div class="col-9">{{ $store.state.vaName }}</div>
        </div>

        <div class="row">
          <div class="col-3">{{ $t("final_gewähltesHotel") }}:</div>
          <div class="col-9">{{ $store.state.selectedHotel.name }}</div>
        </div>

        <div class="row">
          <div class="col-3">{{ $t("final_Anreise") }}:</div>
          <div class="col-9">
            {{ vaStartDate }}
          </div>
        </div>

        <div class="row">
          <div class="col-3">{{ $t("final_Abreise") }}:</div>
          <div class="col-9">{{ vaEndDate }}</div>
        </div>

        <div class="row">
          <div class="col-3">{{ $t("final_AnzahlEZ") }}:</div>
          <div class="col-9">{{ $store.state.ez }}</div>
        </div>

        <div class="row">
          <div class="col-3">{{ $t("final_AnzahlDZ") }}:</div>
          <div class="col-9">{{ $store.state.dz }}</div>
        </div>

        <div class="row">
          <div class="col-3">{{ $t("final_Gesamtpreis") }}:</div>
          <div class="col-9">{{ gesamtPreis() }}</div>
        </div>
        <div v-if="$store.state.isDateWunsch" class="row">
          <hr>
          <div class="col-12">{{ $t("anreise_wunsch_1") }} {{ $store.state.vaStartDateWunsch }} {{ $t("anreise_wunsch_2") }} {{ $store.state.vaEndDateWunsch }} {{ $t("anreise_wunsch_3") }} </div>
        </div>


        <!-- <div class="row"> 
          <div class="col-3">...</div>
          <div class="col-9">{{ $t("hotel_tax")}}</div>
        </div> -->

        <br>
        <!-- <p>
          <strong>Achtung !</strong><br>
          Bitte öffnen Sie das folgende Dokument, um die Buchung mit Eingabe Ihrer
          Kreditkartendaten zu bestätigen. Erst mit mit diesem Schritt wird Ihre
          Buchung gültig !
        </p> -->
        <!-- <button
          @click="click_openFormular"
          class="btn btn--primary"
        >
          Öffne Formular Kreditkartendaten
        </button> -->
        <!-- <br><br>
        <div class="alert alert--success" role="alert">
          <strong>Ihre Buchung wurde erfolgreich durchgeführt.</strong>
        </div> -->
        <!-- <br> -->
        <!-- <button class="btn btn--primary" @click="click_starteHoteSuche">ein weiteres Hotel suchen ... </button> -->

        <a href="javascript:" @click="$store.commit('status', 'search')"   class="link link--icon link--icon--left">
            <i class="icon icon-farmers-market-vendor"></i> 
            <span>{{ $t("button_neueSuchanfrage") }}</span>
          </a>
        <br><br><div class="hinweis" v-if="$store.state.formcycle==''">
          <!-- <template > -->
               {{ $t("head_infotext") }}
          <!-- </template> -->
          </div>
      </div>
    </div>

  </div>
</template>

<script>
import dev from '@/lib/devtools'
import hop from '@/lib/hoptools'
import Vue from "vue";
import con from "./../../hop_connection";

export default {
  mixins: [dev, hop],
  props: ["va"],
  data() {
    return {
    }
  },
  computed: {
    vaEndDate: function(){
      return dev.getDateStringDE(dev.getDate( this.$store.state.vaEndDate ))
    },
    vaStartDate: function(){
      return dev.getDateStringDE(dev.getDate( this.$store.state.vaStartDate ))
    }
  },
  methods: {
    click_openFormular: function(id) {
      // .getKKFormularURL(window.location.href) 
      // console.log("Sprache=" + this.$i18n.locale);
      var url = con.getNotesURL(window.location.href) + "/print?openForm" + "&id=" + this.$store.state.bcode + "&la=" + this.$i18n.locale;     // con.getURLPrintFormular("id123", "de");
      // console.log("öffne URL=> " + url);
      this.createPopupWin(url, 'Hotel Online Portal', 550, 650);
    },
    createPopupWin: function(pageURL, pageTitle, popupWinWidth, popupWinHeight) { 
      let left = (screen.width - popupWinWidth) / 2; 
      let top = (screen.height - popupWinHeight) / 4; 
      let myWindow = window.open(pageURL, pageTitle,  'resizable=yes, width=' + popupWinWidth + ', height=' + popupWinHeight + ', top=' + top + ', left=' + left); 
    } 
  }
};
</script>

<style>
.abstandFinal {
  margin-left: 10px;
  margin-right: 10px;
}
.hinweis {
  color: rgb(255, 38, 0);
  font-weight: bold;
}
</style>
