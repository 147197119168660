<template>
  <div>
    <label
      class="label mindestBreite"
      for="room"
    >{{label}}</label>
      <!-- :disabled="$store.state.status!='search'" -->
    <select
      id="room"
      :value="zValue"
      @change="onChange" 
      class="select"
    >
      <!-- js-select -->
      <option
        v-for="z in roomseelect"
        :key="z"
      >
        {{ z }}
      </option>
    </select>

    <!-- <button @click="click">K</button> -->
  </div>
</template>

<script>
export default {
  props: ["typ", "label", 'value'],
  data() {
      return {
        zz: '',
          roomseelect : this.typ == 'ez' ? ["-", "1", "2", "3", "4", "5"] : ["-", "1", "2", "3"]    
      }
  },
  computed: {
    zValue(){
      return this.typ === 'ez' ? this.$store.state.ez : this.$store.state.dz
    }
  },
  methods: { 
    click: function() {
        console.log("Val=" + this.zValue);
    },
    onChange: function(event) {
        // console.log("room changed:" + this.typ + " -> " + event.target.value);
        this.$store.commit(this.typ,event.target.value);
      }
    }
};
</script>

<style>
.mindestBreite{
  width: 80px;
}
</style>
