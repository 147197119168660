<template>
  <div class="d-flex">
    <!-- http://localhost/.../DomNav/TOOLS/Webhotel.nsf/vCode/10002/$FILE/10002.jpg?open -->
    <!-- <button class="btn" @click="checkCaptcha"> TEST </button> -->
    <div>
        <img :src="imgSrc" @click="newCaptcha">
    </div>
    <div style="margin-left: 10px; margin-right:10px">
        <span class="icon">{{ $t("captcha_info") }}</span>     
    </div>
    <!-- v-bind:class="[showError && captchaInput!=code ? 'is-invalid':'']" -->
    <div  class="form-group"   v-bind:class="[showError ? 'is-invalid':'']">
        <!-- && captchaInput==code       -->
        <input id="captcha" class="input input--text" style="width: 70px;" type="text" v-model="captchaInput" @change="checkCaptcha" autocomplete="off">
    </div>
  </div>
</template>

<script>
import axios from 'axios';  
import con from "./../hop_connection";

export default {
    data() { 
        return {
            imgSrc : "",
            // code : "",
            captchaInput :"",
            captchaSessionID: '',
        }
    },
    computed: {
        showError: function(){
            return this.$store.state.showError;
        }
    },
    methods : {
        checkCaptcha: function() {
            this.$emit('input', {"input" :this.captchaInput, "session" : this.captchaSessionID})
            // if (this.captchaInput == this.code && this.code != '') {
            //    this.$emit('input', true)
            // } else {
            //    this.$emit('input', false)
            // }
        },
        captcha: function() {
                // let url =  this.$store.getters['getHotelOverviewURL'] + "&cmd=vas";
            let url = con.getNotesURL(window.location.href) + '/cmd?openAgent&cmd=getCaptcha'
            // console.log("CaptchaURL = " + url);
            axios.request({
                method: "get",
                url: url,
                crossDomain: true
            }).then(  resp => {  
                // axios.get(url).
                // console.log("VA2 Daten wurden geladen ...., url=" + url + " => " + resp.data.vas);
                if(resp.data  != undefined) {
                        // console.table(resp.data);
                        this.imgSrc = con.getNotesURL(window.location.href) + "/vCode/" + resp.data.captcha + "/$FILE/" + resp.data.captcha + ".jpg?open"
                        this.captchaSessionID = resp.data.session;
                        // this.code = resp.data.code
                }
            })
        },
        newCaptcha: function() {
            this.captcha();
        }  
    },
    mounted() {
        this.captcha();
    }

}
</script>

<style>

</style>