<template>
    <a href="javascript:" @click="clickAdresse()" class="">
        <i class="icon icon-pin"></i> 
       <span style="padding-left: 5px">{{getHTMLAdresse}}</span>
    </a>
  <!-- <span><i class="icon icon-pin" aria-label="Leaf"><small @click="clickAdresse">{{getHTMLAdresse}}</small></i></span> -->
</template>

<script>
export default {
    props:['adresse','hotelname'],
    computed: {
        getHTMLAdresse: function(){
            try {
                let h2 = this.adresse.replace(/(?:\\[r])+/g, "");
                h2 = h2.replace(/(?:\\[n])+/g, "[ENTER]");
                h2 = h2.split("[ENTER]");
                let h = h2[1] + ", "+ h2[2];
                return h;
                
            } catch (error) {
                return "";                
            }
        }
    },
    methods: {
        clickAdresse: function(){
            if(this.hotelname != '' && this.hotelname != undefined) {
            // openGoogleMapURL
                window.open('https://www.google.de/maps/dir/' + this.hotelname + ',Leipzig/Leipziger Messe GmbH,Messe-Allee,Leipzig','_blank');
            }
        },
    }
}
</script>

<style>

</style>