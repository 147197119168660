<!-- eslint-disable no-constant-condition -->
<template>
  <span>
    <label class="label">{{ label }}</label>
    <div class="datepicker js-datepicker" :id="typ">
        <!-- :disabled="$store.state.status!='search'" -->
      <input
        :id="id1"
        type="text"
        class="input"
        autocomplete="off"
        :value="dateValue"
        @change="changeValue"  
        :key="getKey"     
      >
       
      <!-- @change="$emit('valuechanged',$event.target.readDateValue())" -->
      <input type="hidden" :id="id2">
    </div>

    <!-- <button @click="clickR">R</button>
    <button @click="clickS">S</button> -->
    
  </span>
</template>

<script>
/*global LM, $, Datepicker */
import dev from '@/lib/devtools'
import hop from '@/lib/hoptools'

export default {
  mixins: [dev, hop],
  props: ["label", "value", "typ","id"],
  data() {
    return {
      // dateValue: null,
      idpicker: this.typ,
      datepicker: null,
      dateLabelDE : {
              previousMonth : 'Vorheriger Monat',
              nextMonth     : 'Nächster Monat',
              months        : ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
              weekdays      : ['Sontag', 'Montag', 'Dienstag','Mittwoch','Donnerstag', 'Sonnabend'],
              weekdaysShort : ['So','Mo','Di','Mi','Do','Fr','Sa']
            },
      dateLabelEN : {
              previousMonth : 'previous month',
              nextMonth     : 'next month',
              months        : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
              weekdays      : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Saturday'],
              weekdaysShort : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            },
      dp: {}
    }; 
  },
  watch: {
     lng: function(n,o){
        // dev.logg("sprache => " + n);
      if( this.datepicker == null ){
        return;
      }
      let dv = dev.getDate(this.dateValue);
      // das sind die Sprachlabels für die Datumsauswahldialoge
       if(n == 'de') {
          this.datepicker.pikaday.i18n = this.dateLabelDE;
          this.datepicker.opts.i18n = this.dateLabelDE;
          this.datepicker.pikaday._o.i18n = this.dateLabelDE;
          //  this.dateValue = dev.getDateStringDE(dv)
       } else {
          this.datepicker.pikaday.i18n = this.dateLabelEN;
          this.datepicker.opts.i18n = this.dateLabelEN;
          this.datepicker.pikaday._o.i18n = this.dateLabelEN;
          //  this.dateValue = dev.getDateStringEN(dv)
       }
     },
     dateValue: function(n,o){
        // let s1 = n + ' '//dev.getDateStringDE(dev.getDate(this.dateValue));
        // dev.logg(n + " ==== >" +  dev.getDateStringDE(this.datepicker.pikaday.getDate()));

        // this.datepicker.pikaday.gotoDate(dev.getDate(n))
        // this.datepicker.pikaday.setDate(dev.getDate(n))
     },
  },
  computed:{
    vaStartDate: function(){
      return this.$store.state.vaStartDate;
    },
    vaEndDate: function(){
      return this.$store.state.vaEndDate;
    },
    lng: function(){
       return this.$i18n.locale;
    },
    id1() {
      return this.id + "1";
    },
    id2() {
      return this.id + "2";
    },
    getKey() {
      // + this.$i18n.locale
      return this.typ ==='start' ? this.$store.state.key_search_start : this.$store.state.key_search_end 
      // return this.$i18n.locale
    },
      // ...mapGetters(['vaDate']),
    dateValue(){
      let ds = this.typ ==='start' ? this.$store.state.vaStartDate : this.$store.state.vaEndDate; 
      let da = dev.getDate(ds)
      return dev.getDateStringDE( da )
    },
  },
  methods:{
 
      clickR: function(){
          // console.log("Datum = " + document.getElementById(this.id1).value );
      },
      clickS: function() {
          // console.log("Datum = " + document.getElementById(this.id1));
      },
      changeValue: function(event){
        let typ = this.typ === 'start' ? 'vaStartDate' : 'vaEndDate'
        this.$store.commit(typ,(dev.getDate(event.target.value)));
      },
      handleChange(e, val) {
          // console.log("new DatePickerValuie =" + val);
      }
  },
  mounted(){
      if( typeof LM !== 'undefined' ){
         if (this.datepicker === null) {
            let dphtml = document.getElementById(this.idpicker);
            this.datepicker = new LM.Datepicker($(dphtml), 'de')
          }
      }
  },
  destroyed() {
    this.dp.pikaday = null;
  }
};
</script>

<style>
/* .brei{
  width: 130px;
} */
</style>
