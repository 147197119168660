<template>
     <a href="javascript:return false" @click="openGoogleMapURL()" class="link link--icon link--icon--left">
        <i class="icon icon-pin"></i> 
        <span>{{ $t("link_googlemap") }}</span>
    </a>
</template>

<script>
export default {
    props:['hotel'],

    methods:{
       openGoogleMapURL: function(hotel) {
        // https://www.google.com/maps/search/Leipzig,' + hotel
            window.open('https://www.google.de/maps/dir/' + this.hotel.name + ',Leipzig/Leipziger Messe GmbH,Messe-Allee,Leipzig','_blank');
        },
    }
}
</script>

<style>

</style>