<template>
  <span class="form-group" v-bind:class="[showError && selected=='' ? 'is-invalid':'']">

    <span class="custom-radio">
      <input :id="id1" type="radio" :name="group" value="Herr" v-model="selected" :disabled="anreise=='1'">
      <label class="label" :for="id1">{{ $t("reg_Herr") }} *</label> <!-- class = aLeft abstandAnrede -->
    </span>

    <span class="custom-radio">
      <input :id="id2" type="radio" :name="group" value="Frau" v-model="selected" :disabled="anreise=='1'">
      <label class="label" :for="id2">{{ $t("reg_Frau") }} *</label> <!-- class = aLeft abstandAnrede -->
    </span>
   <!-- <form_titel></form_titel> -->
    <!-- <span class="custom-radio">
      <input :id="id3" type="radio" name="group1" value="diverse" v-model="selected" >
      <label :for="id3">diverse</label> 
    </span> -->
<!-- <button class="btn" @click="click">{{ showError }}</button> -->
  </span>
</template>

<script>
// v-on:input="updateValue($event.target.value)
// import form_titel from "@/components/formular/form_titel";

export default {
  name: "anrede-eingabefeld",
  props: ["anreise"],
  data() {
    return {
      group: null,
      id1: null,
      id2: null,
      id3: null,
      selected: "",
    };
  },
  comments: {
    // form_titel
  },
  computed: {
    showError: function(){
      return this.$store.state.showError;
    }
  },
  watch: {
      v3: function(a,b){
      if(this.anreise){
        this.selected = b
      }
    },
    selected: function(newVal) {
      //   console.log("watched .." + newVal);
      this.$emit("input", newVal);
    },
  },
  methods: {
    click: function(){
      this.$store.commit('showError',!this.$store.state.showError);
      // console.log(this.selected);
    },
    updateValue: function(value) {
      // console.log(value);
      //   this.$emit('input', value)
    },
  },
  mounted() {
    this.id1 = this._uid + "1";
    this.id2 = this._uid + "2";
    this.id3 = this._uid + "3";
    this.group = this._uid + "g"
  },
};
</script>

<style>
.abstandAnrede {
  margin-top: 3px;
}
</style>
