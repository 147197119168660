<template>
  <div>
    <div class="row">

      <div class="col-12 col-md-6 form-group" v-bind:class="[showError && pAnrede=='' ? 'is-invalid':'']">
        <div v-if="$store.state.selectedHotel.isCaravan">        
          <label class="label">{{$t("Stellplatz")}}</label>
        </div>
        <div v-else>
          <label class="label">{{personlabel}}</label>
        </div>
      </div>

      <div class="col-12 col-md-4 form-group">
         <form_anrede v-model="pAnrede"  v-bind:anreise="anreise=='1'" v-bind:v3="v3" />
      </div>
      <!-- <div class="col-12 col-md-1">
        <label for="titel" class="label">{{ $t("reg_titel") }}</label>
      </div>-->
      <div class="col-12 col-md-2">
          <form_titel v-on:selectData="selectUserTitle" v-model="Titel" extratitel="1"></form_titel>
      </div> 
      <!-- <div class="col-12 col-md-1 form-group">
      </div> -->

    <!-- </div> -->

    <!-- <div class="d-flex">
      <div class="label">{{personlabel}}</div>
      <form_anrede class="aLeft" v-model="pAnrede"></form_anrede>
    </div> -->

    <!-- <div class="row"> -->
      <div class="col-12 col-md-6 form-group"  v-bind:class="[showError && Nachname=='' ? 'is-invalid':'']">
        <label class="label" :for="idN">{{ $t("reg_name") }} *</label>
        <input :id="idN" class="input input--text" v-model="Nachname" :disabled="anreise=='1'">
      </div>
      <div class="col-12 col-md-6 form-group" v-bind:class="[showError && Vorname=='' ? 'is-invalid':'']">
        <label class="label" :for="idV">{{ $t("reg_vorname") }} *</label>
        <input :id="idV" class="input input--text" v-model="Vorname" :disabled="anreise=='1'">
      </div>
    </div>

    <!-- <button class="btn" @click="click">.</button> -->
  </div>
</template>

<script>
import form_anrede from "./form_anrede";
import form_titel from "@/components/formular/form_titel";

export default {
  props: ["personlabel","anreise","v1","v2","v3","v4"],
    watch: {
    v1: function(a,b){
      if(this.anreise){
        this.Vorname = b
      }
    },
    v2: function(a,b){
      if(this.anreise){
        this.Nachname = b
      }
    },
    v3: function(a,b){
      if(this.anreise){
        this.pAnrede = b
      }
    },

      anreise: function(a,b){
        if(a){
          this.pAnrede = this.$store.state.banrede
          this.Vorname = this.$store.state.bvorname
          this.Nachname = this.$store.state.bnachname
        }
      },

    pAnrede: function() {
     var usr = { "Anrede" : this.pAnrede, "Vorname" : this.Vorname, "Nachname" : this.Nachname, "Titel": this.Titel };
     this.$emit("input", usr) 
    },
    Nachname: function() {
     var usr = { "Anrede" : this.pAnrede, "Vorname" : this.Vorname, "Nachname" : this.Nachname, "Titel": this.Titel };
     this.$emit("input", usr) 
    },
    Vorname: function(){
     var usr = { "Anrede" : this.pAnrede, "Vorname" : this.Vorname, "Nachname" : this.Nachname, "Titel": this.Titel };
     this.$emit("input", usr) 
    },
    Titel: function(){
     var usr = { "Anrede" : this.pAnrede, "Vorname" : this.Vorname, "Nachname" : this.Nachname, "Titel": this.Titel};
     this.$emit("input", usr) 
    }
  },

  components: {
    form_anrede,
    form_titel
  },
  computed: {
    showError: function(){
      return this.$store.state.showError;
    }
  },
  data() {
    return {
      content: { 
        Anrede : this.pAnrede,
        Vorname: this.Vorname,
        Nachname: this.Nachname,
        Titel: this.Titel,
      },
      idV: null,
      idN: null,
      seleced: null,
      pAnrede: "",
      Vorname: "",
      Nachname: "",
      Titel:"",
    }
  },
  methods: {
    click: function() {
      alert("button=" + this.Nachname);
      return;
    },
    selectUserTitle: function(a,b) {
        // this.Titel = b

        // console.log("OK-Titel: " + a)
        this.Titel = a;
        if(this.Titel.indexOf('<')>=0) {
          this.Titel = ""
        }
        // this.$emit("selectData", this.Titel) ;
        var usr = { "Anrede" : this.pAnrede, "Vorname" : this.Vorname, "Nachname" : this.Nachname, "Titel": this.Titel};
       this.$emit("input", usr) 
    }
  },
  mounted() {
      this.idV = this._uid + "V";
      this.idN = this._uid + "N";
  }
};
</script>

<style></style>
