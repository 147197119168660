<template>
  <div>
    <div class="card-list__items">
      <div class="card js-card">
        
        <div class="card__content-row">
          
          <div class="h2 card__title">{{ VATitel }}</div>
          <p>
            {{ $t("Zeitraum") }}: {{ vaStartDateSearch }} - {{ vaEndDateSearch }} <br> 
            {{ $t("Zimmer") }}: {{ $store.state.ez!='-' ? $store.state.ezSearch + ' x ' + $t("Einzelzimmer") : ''  }}
              {{ $store.state.ezSearch!='-' && $store.state.dzSearch!='-' ? ' / ' : '' }}
              {{ $store.state.dzSearch!='-' ?  $store.state.dzSearch + ' x ' + $t("Doppelzimmer") : ''  }}
          </p>
          <!-- <br> -->
          {{ VAInfo }}
          <template v-if="$store.state.hotelSearchResult.hotels.length>0">
            <hr>
            <ul class="card__link-list">
              <li class="card__link-list__item">
                <a href="javascript:" @click="click_changeBuchung" class="link link--icon link--icon--left">
                  <i class="icon icon-farmers-market-vendor"></i> 
                  <span>{{ $t("button_BuchungÄndern") }}</span>
                </a>
              </li>
            </ul>
          </template>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import dev from '@/lib/devtools'
import hop from '@/lib/hoptools'

export default {
  mixins: [dev, hop],
  props: [""],
  computed: {
    vaStartDateSearch: function() {
      return dev.getDateStringDE(dev.getDate( this.$store.state.vaStartDateSearch ))
    },
    vaEndDateSearch: function() {
      return dev.getDateStringDE(dev.getDate( this.$store.state.vaEndDateSearch ))
    },
    VAEnd: function() {
      return ""      
    },
    VAInfo: function() {
      return this.$i18n.locale == 'de' ? this.$store.state.vaObj.vainfode : this.$store.state.vaObj.vainfoen    
    },
    VATitel: function() {
      return this.$i18n.locale == 'de' ? this.$store.state.vaObj.name : this.$store.state.vaObj.nameen    
    }
  },
  methods:{
    click_changeBuchung: function(){

        this.$store.commit('vaName',this.$store.state.vaNameSearch)
        this.$store.commit('vaStartDate',this.$store.state.vaStartDateSearch);
        this.$store.commit('vaEndDate',this.$store.state.vaEndDateSearch);
        // this.$store.commit('vaIDSearch',this.$store.getters.getVAID);
        this.$store.commit('dz',this.$store.state.dzSearch);
        this.$store.commit('ez',this.$store.state.ezSearch);
        this.$store.commit('vaObj',this.$store.state.vaObjSearch);

        this.$store.commit('status', 'search');

    }
   },
};
</script>

<style></style>
