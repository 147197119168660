<template>
    <table>
    <tr class="tr_boarder">
        <!-- <td><i class="icon icon-pin iconabstand"></i></td>                   -->
        <td>{{ $t("hotel_Messegelände") }}</td>
        <td class="table_padding_left"><nobr>{{ formatKM(hotel.kmmesse) }}</nobr></td>
    </tr>
    <tr class="tr_boarder">
        <!-- <td><i class="icon icon-presentation-speaker iconabstand"></i></td> -->
        <td>{{ $t("hotel_Kongresshalle") }}</td>
        <td class="table_padding_left"><nobr>{{ formatKM(hotel.kmkongress) }}</nobr></td>
    </tr>
    <tr class="tr_boarder">
        <td>{{ $t("hotel_Stadtzentrum") }}</td>
        <td class="table_padding_left"><nobr>{{ formatKM(hotel.kmstadt) }}</nobr></td>
    </tr>
    <tr class="tr_boarder">
        <td>{{ $t("hotel_Hauptbahnhof") }}</td>
        <td class="table_padding_left"><nobr>{{ formatKM(hotel.kmzug) }}</nobr></td>
    </tr>ed
    <tr class="tr_boarder">
        <td>{{ $t("hotel_Flughafen") }}</td>
        <td class="table_padding_left"><nobr>{{ formatKM(hotel.kmflug) }}</nobr></td>
    </tr>
</table>
</template>

<script>
export default {
props: ['hotel'],
methods: {
    formatKM: function (km) {
        var formatter = new Intl.NumberFormat('de-DE', {
            style: 'decimal',
            minimumFractionDigits: 1
        });
        let ret = formatter.format(km.replace(',', '.')); 
        return ret + " km";
    }
}
}
</script>

<style>

</style>