<template>


 <div class="card js-card">

<div v-if="formlarcomplete">
    <div class="alert alert--info abstand" role="alert">
      <strong>{{ $t("gruppe_abschluss1")}}</strong><br>
      {{ $t("gruppe_abschluss2")}}<br>
    </div>
    <br>
    <button class="btn btn--primary abstand" @click="goBack">{{ $t("zurück") }}</button>
</div>
<div v-else>

    <!-- <div class="card__title card__title--highlight"> -->
      <!-- <div class="card__title__left abstandlinks">
      </div> -->
       <br> 
       <div style="margin-top:10px; margin-left:10px; font-size:22px; font-weight:bold">{{$t("gruppe_AnfrageLabel")}} 
            {{ $store.state.vaName }}
       </div>
       <div style="margin-top:0px; margin-left:10px;"> 
            <b>{{VeranstaltungsOrt}}</b>
       </div>
    <div class="card__body abstandlinks">
      <!-- Form -->
      <form style="margin-right:8px">

<!-- VA-Ort: <br> -->
<br>
{{$t("gruppe_Info1")}}
<br>
<br>

<!-- Buchungsanforderung: -->
        <!-- <div class="row">
           <div class="col-12 form-group">
            <label for="Buchungsanforderung" class="label">{{ $t("gruppe_Buchungsanforderung") }}</label>
            <textarea id="Buchungsanforderung" class="textarea" v-model="Buchungsanforderung"/>
          </div>
        </div> -->

<!-- Anzahl EZ -->
        <div class="row">
          <div class="col-12 col-md-6 form-group" v-bind:class="[showError && AnzahlEZ=='' ? 'is-invalid':'']">
            <label for="AnzahlEZ" class="label">{{ $t("gruppe_AnzahlEZ") }} *</label>
             <input id="AnzahlEZ" class="input input--text" v-model="AnzahlEZ">
          </div>

<!-- Anzahl DZ -->
         <div class="col-12 col-md-6 form-group" v-bind:class="[showError && AnzahlDZ=='' ? 'is-invalid':'']">
            <label for="AnzahlDZ" class="label">{{ $t("gruppe_AnzahlDZ") }} *</label>
             <input id="AnzahlDZ" class="input input--text" v-model="AnzahlDZ">
          </div>

        </div>

<!-- Anreise -->
        <div class="row">
           <div class="col-12 col-md-6 form-group">
                  <hop_datepicker typ="start" :label="$t('search_Anreise')" :key="$store.state.key_search_start" :value="von" />
            </div>
<!-- Abreise -->
           <div class="col-12 col-md-6 form-group">
                 <hop_datepicker typ="end" :label="$t('search_Abreise')" :key="$store.state.key_search_end" :value="bis" />
          </div>
        </div>

<!-- Bemerkung zur An/Abreise -->
        <div class="row">
           <div class="col-12 form-group">
            <label for="BemerkungAnAbreise" class="label">{{ $t("gruppe_BemerkungAnAbreise") }}</label>
            <textarea id="BemerkungAnAbreise" class="textarea" v-model="BemerkungAnAbreise"/>
          </div>
        </div>

<!-- Hotelkategorie: ** // *** // **** // ***** -->
        <div class="row">
          <div class="col-12 col-md-6 form-group">
            <label for="Hotelkategorie" class="label">{{ $t("gruppe_Hotelkategorie") }}</label>
            <select id="Hotelkategorie" class="input input--text" v-model="Hotelkategorie">
              <option v-for="l in HotelkategorieAuswahl" :key="l">
                {{l}}
              </option>
            </select>
          </div>

                    <div class="col-12 col-md-6 form-group">
            <label for="LageHotel" class="label">{{ $t("gruppe_LageHotel") }}</label>
            <select id="LageHotel" class="input input--text" v-model="LageHotel">
              <option v-for="l in LageHotelAuswahl" :key="l">
                {{l}}
              </option>
            </select>
          </div>

        </div>

<!-- Lage des Hotels: Optionen als Auswahl: Nähe Messegelände/Congress Center Leipzig, Nähe KONGRESSHALLE am Zoo, Innenstadt<br> -->

<!-- Sonderwünsche -->
        <div class="row">
           <div class="col-12 form-group">
            <label for="Sonderwuensche" class="label">{{ $t("gruppe_Sonderwuensche") }}</label>
            <textarea id="Sonderwuensche" class="textarea" v-model="Sonderwuensche"/>
          </div>
        </div>

<br>
<h3>{{$t('gruppe_Kontaktdaten')}}</h3>

<!-- Name: -->
<!-- Vorname: -->
        <div class="row">
           <div class="col-12 col-md-6 form-group"  v-bind:class="[showError && Name=='' ? 'is-invalid':'']">
            <label for="Name" class="label">{{ $t("gruppe_Name") }} *</label>
             <input id="Name" class="input input--text" v-model="Name">
          </div>

           <div class="col-12 col-md-6 form-group"  v-bind:class="[showError && Vorname=='' ? 'is-invalid':'']">
            <label for="Vorname" class="label">{{ $t("gruppe_Vorname") }} *</label>
             <input id="Vorname" class="input input--text" v-model="Vorname">
          </div>
        </div>

<!-- Firma:  -->
        <div class="row">
           <div class="col-12 form-group" v-bind:class="[showError && Firma=='' ? 'is-invalid':'']">
            <label for="Firma" class="label">{{ $t("gruppe_Firma") }} *</label>
             <input id="Firma" class="input input--text" v-model="Firma">
          </div>
        </div>

 <!-- Feld Anschrift weg -->
<!-- dafür PLZ, Ort   -->
        <div class="row">
           <div class="col-12 col-md-6 form-group"  v-bind:class="[showError && PLZ=='' ? 'is-invalid':'']">
            <label for="PLZ" class="label">{{ $t("gruppe_PLZ") }} *</label>
             <input id="PLZ" class="input input--text" v-model="PLZ">
          </div>

           <div class="col-12 col-md-6 form-group"  v-bind:class="[showError && Ort=='' ? 'is-invalid':'']">
            <label for="Ort" class="label">{{ $t("gruppe_Ort") }} *</label>
             <input id="Ort" class="input input--text" v-model="Ort">
          </div>
        </div>

<!-- Straße, Land,  hinzufügen -->
        <div class="row">
           <div class="col-12 col-md-6 form-group"  v-bind:class="[showError && Strasse=='' ? 'is-invalid':'']">
            <label for="Strasse" class="label">{{ $t("gruppe_Strasse") }} *</label>
             <input id="Strasse" class="input input--text" v-model="Strasse">
          </div>

           <div class="col-12 col-md-6 form-group">
            <label for="Land" class="label">{{ $t("reg_land") }}</label>
            <select id="Land" class="input input--text" v-model="Land">
              <option v-for="l in laender" :key="l">
                {{l}}
              </option>
            </select>
             
          </div>
        </div>

<!-- Adresse:  -->
        <!-- <div class="row">
           <div class="col-12 form-group" v-bind:class="[showError && Adresse=='' ? 'is-invalid':'']">
            <label for="Adresse" class="label">{{ $t("gruppe_Adresse") }} *</label>
            <textarea id="Adresse" class="textarea" v-model="Adresse"/>
          </div>
        </div> -->

        
<!-- E-Mail:  -->
<!-- Telefonnummer: -->
        <div class="row">
           <div class="col-12 col-md-6 form-group" v-bind:class="[showError && EMail=='' ? 'is-invalid':'']">
            <label for="EMail" class="label">{{ $t("gruppe_EMail") }} *</label>
             <input id="EMail" class="input input--text" v-model="EMail">
          </div>

           <div class="col-12 col-md-6 form-group" v-bind:class="[showError && Telefonnummer=='' ? 'is-invalid':'']">
            <label for="Telefonnummer" class="label">{{ $t("gruppe_Telefonnummer") }} *</label>
             <input id="Telefonnummer" class="input input--text" v-model="Telefonnummer">
          </div>
        </div>

<br>


  </form>

     <hop_captcha v-model="captcha"/>

      <!-- <hop_captcha v-model="captcha"/> -->
  <div class="flow">
   <button class="btn btn--primary" @click="absenden"> {{ $t("reg_buttonabsenden") }} </button>
      <button class="btn btn--primary" style="margin-bottom:10px; margin-left:10px" @click="goBack">{{ $t("zurück") }}</button>
        <br /><br /> 
    </div>

    </div>
    </div>
  </div>


</template>

<script>
import dev from '@/lib/devtools'
//  <hop_datepicker typ="start" :label="$t('search_Anreise')" :key="$store.state.key_search_start" :value="von" :id="'anreiseDP'" />
import hop_datepicker from "./../../search/hop_datepicker";
import hop from "../../../hop_connection";
import axios from 'axios';
import hop_captcha from '../../captcha.vue'

export default {
    mixins: [dev],
    props:['hotelkey','hotel'],
    data () {
        return{
            formlarcomplete: false,

             captcha: {input: '', session: ''},
            // captchaFehler: false,
//    von: this.$store.state.VA_startDate, //"05.02.2019",
//       bis: this.$store.state.VA_endDate, //"07.02.2019",
             von:"",
             bis:"",

            Buchungsanforderung : "",
            // this.$store.state.ezSearch
            AnzahlEZ : "0",
            // this.$store.state.dzSearch
            AnzahlDZ : "0",
            Anreise: "",
            Abreise: "",
            BemerkungAnAbreise: "",
            Hotelkategorie: "****",
            HotelkategorieAuswahl: ["**", "***","****","*****"],
            LageHotel: this.$t("gruppe_AuswahlVorgabe"), //this.$store.state.vaObj.isZoo =='' ? "Nähe Messegelände/Congress Center Leipzig" : "bitte wählen ...",
            // LageHotelAuswahl: this.$t("gruppe_AuswahlLageHotel"),
            Sonderwuensche:"",


           // VeranstaltungsOrt: this.$t("gruppe_AuswahlVorgabe"), //this.$store.state.vaObj.isZoo !='' ? "Veranstaltrungsort: Kongresshalle am Zoo" : "Veranstaltrungsort: Messegelände / Congress Center",
            Name: "",
            Vorname: "",
            Firma: "",
            Adresse:  "",
            EMail: "",
            Telefonnummer: "",

            PLZ: "",
            Land: "Deutschland",
            Strasse: "",
            Ort: "",

            laender: this.$t("laender")  // ["Ägypten", "Äthiopien", "Albanien", "Algerien", "Andorra", "Angola", "Argentinien", "Armenien", "Aruba", "Aserbaidschan", "Australien", "Bangladesch", "Belarus", "Belgien", "Bosnien-Herzegowina", "Brasilien", "Britische Jungferninseln", "Bulgarien", "Burkina Faso", "Chile", "Costa Rica", "Dänemark", "Deutschland", "Dominikanische Republik", "Ecuador", "El Salvador", "Eritrea", "Estland", "Finnland", "Frankreich", "Französisch Polynesien", "Gambia", "Georgien", "Ghana", "Gibraltar", "Griechenland", "Großbritannien", "Guatemala", "Honduras", "Hong Kong", "Indien", "Indonesien", "Iran", "Irland", "Island", "Israel", "Italien", "Japan", "Jordanien", "Kamerun", "Kanada", "Kasachstan", "Katar", "Kenia", "Kirgisistan", "Kolumbien", "Kroatien", "Kuba", "Kuwait", "Lettland", "Libanon", "Liechtenstein", "Litauen", "Luxemburg", "Madagaskar", "Malawi", "Malaysia", "Mali", "Malta", "Marokko", "Mazedonien", "Mexiko", "Monaco", "Mongolei", "Namibia", "Nepal", "Neuseeland", "Niederländische Antillen", "Niederlande", "Nigeria", "Nordkorea", "Norwegen", "Österreich", "Oman", "Pakistan", "Palästina", "Peru", "Philippinen", "Polen", "Portugal", "Republik Moldau", "Republik Montenegro", "Republik Serbien", "Ruanda", "Rumänien", "Russland", "San Marino", "Saudi-Arabien", "Schweden", "Schweiz", "Senegal", "Simbabwe", "Singapur", "Slowakei", "Slowenien", "Spanien", "Sri Lanka", "Sudan", "Südafrika", "Südkorea", "Syrien", "Taiwan", "Tansania", "Thailand", "Trinidad und Tobago", "Tschad", "Tschechische Republik", "Türkei", "Tunesien", "Turkmenistan", "Uganda", "Ukraine", "Ungarn", "Uruguay", "Usbekistan", "Vatikanstadt", "Venezuela", "Vereinigte Arabische Emirate", "Vereinigte Staaten von Amerika", "Vietnam", "Volksrepublik China", "Zypern"],

        }
    },
    computed: {
       showError: function(){
            return this.$store.state.showError;
    },
    LageHotelAuswahl: function(){
        return this.$t("gruppe_AuswahlLageHotel");
    },
    VeranstaltungsOrt: function(){
      // return  this.$t("gruppe_AuswahlVorgabe");
          if( this.$store.state.vaObj.isZoo !='') {
              return this.$t("Veranstaltungsort_Kongresshalle") ;// "Veranstaltungsort: Kongresshalle am Zoo";
          }  else {
              return this.$t("Veranstaltungsort_Messegelände");
          }
    }
   },
    methods: {
        goBack: function(){
             this.$store.commit('statusGruppenFormular',false)
            this.scrollToTop();
        },
        getFormData: function() {
            let fd = '{'; 

            fd += `"veranstaltung": "${this.convertString(this.$store.state.vaName)}" ,`;
            fd += `"rva": "${this.$store.state.vaObj.rid}" ,`;
            fd += `"Buchungsanforderung": "${this.convertString(this.Buchungsanforderung)}" ,`;
            fd += `"AnzahlEZ":  "${this.AnzahlEZ}" , `;
            fd += `"AnzahlDZ":  "${this.AnzahlDZ}" , `;
            fd += `"Anreise":   "${dev.getDateStringDE(dev.getDate(this.$store.state.vaStartDate))}" , `;
            fd += `"Abreise":   "${ dev.getDateStringDE(dev.getDate(this.$store.state.vaEndDate))}" , `;
            fd += `"BemerkungAnAbreise":"${this.convertString(this.BemerkungAnAbreise)}" , `;
            fd += `"Hotelkategorie":    "${this.Hotelkategorie}" , `;
            fd += `"LageHotel": "${this.convertString(this.LageHotel)}" , `;
            fd += `"Sonderwuensche":    "${this.convertString(this.Sonderwuensche)}" , `;
            fd += `"Name":  "${this.convertString(this.Name)}" , `;
            fd += `"Vorname":   "${this.convertString(this.Vorname)}" , `;
            fd += `"Firma":     "${this.convertString(this.Firma)}" , `;
            fd += `"Adresse":   "${this.convertString(this.Adresse)}" , `;
            fd += `"EMail": "${this.convertString(this.EMail)}" , `;
            fd += `"Telefonnummer": "${this.convertString(this.Telefonnummer)}" , `;
            fd += `"sprache": "${this.$i18n.locale}" ,`;


            fd += ` "PLZ": "${this.convertString(this.PLZ)}" , `;
            fd += ` "Land": "${this.convertString(this.Land)}" , `;
            fd += ` "Strasse": "${this.convertString(this.Strasse)}" , `;
            fd += ` "Ort": "${this.convertString(this.Ort)}" , `;

               // fd += `"":"${this.}" , `;

            fd += `"captchaInput" : "${this.captcha.input}", `
            fd += `"captchaSession" : "${this.captcha.session}", `
            fd += ` "status" : "ok"`;
            fd += `}`;
            return fd;
        },
    convertString: function(v) {
       let ret   = encodeURI(v);
      return ret;
    },
       isFormOK: function(){
           if(this.Firma == "") return false;
           if(this.Vorname == "") return false;
           if(this.Name == "") return false;
        //    if(this.Adresse == "") return false;
           if(this.EMail == "") return false;
           if(this. Telefonnummer == "") return false;

           if(this.AnzahlEZ == "") return false;
           if(this.AnzahlDZ == "") return false;

           if(this.Ort == "") return false;
           if(this.PLZ == "") return false;
           if(this.Strasse == "") return false;

           if( this.captcha.input ==  '' ) return false;
        
            return  true
        },

      absenden: function(){
            // console.log(this.$store.state.vaEndDate);
                // this.captchaFehler = false

            if(this.isFormOK() ) {
                this.$store.commit('showError',false);
            } else {
                this.$store.commit('showError',true);
                console.log("Abbruch! Eingabedaten fehlen ...");
                return;
            }

            let formData  = JSON.parse(this.getFormData()); 
            let url = hop.getHOPUrl(window.location.href) + '&cmd=gruppe';
            // console.log(url);
            
            let charset = "utf-8"; 
            axios.post(url, formData, {headers: {
                'content-type': 'text/json;  charset=utf-8'
            }}).then( resp => {
              //  let ret = JSON.parse( resp.data);
              if(resp.data.status == "ok") {
                //   this.$store.commit('banrede', this.BAnrede );
                //   this.$store.commit('bnachname', this.BNachname ); 
                //   this.$store.commit('bvorname', this.BVorname );
                //   this.$store.commit('bcode', resp.data.buchungscode);
                //   this.$store.commit('status','final') //emit("submitBooking", formData);      
                
               this.formlarcomplete = true;
              } else if( resp.data.status .indexOf("Captcha") !== -1 ) {
                     this.$store.commit('showError',true);
            } else  { 
                // this.$t('fehlerhinweis')
                // console.log(resp.data.status + "/" + this.strRight(resp.data.status, 'Fehler=' ));
               // alert("-" + "-" + this.strRight(resp.data.status, 'Fehler=' ));
              }
            });

        },
        formularBack: function() {
             this.$store.commit('statusGruppenFormular', false);
             console.log("statusGruppenFormular => false");
        }
    },
    components:{
        hop_datepicker,
        hop_captcha
    }

}
</script>

<style>
.abstand{
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>