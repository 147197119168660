<template>
  <div class="card js-card">

    <!-- Titel -->
    <div class="card__title-row card__title-row--highlight">
      <div class="card__title__left">
        <span style="margin-left: 10px"><big>{{ hotel.name }}</big></span>
        <span style="margin-left: 12px"
          v-if="hotel.rating >= 1"
          class="icon icon-rating-star" />
        <span style="margin-right: 2px"
          v-if="hotel.rating >= 2"
          class="icon icon-rating-star" />
        <span style="margin-right: 2px"
          v-if="hotel.rating >= 3"
          class="icon icon-rating-star" />
        <span style="margin-right: 2px"
          v-if="hotel.rating >= 4"
          class="icon icon-rating-star" />
        <span style="margin-right: 2px"
          v-if="hotel.rating >= 5"
          class="icon icon-rating-star" />
        <span style="margin-right: 10px"></span>
      </div>
    </div>

    <!-- Body -->
    <div class="card__body">
      <div class="container-fluid">

        <div class="row justify-content-md-center">
          <!-- Bild -->
          <!-- <template v-if="showImage"> -->
          <div v-if="hotel.bilder.titel" class="hotelInfoImage col-12 col-md-4 col-sm-1 hidden-phone" @click="click_hotelInfo">
            <img class="hotelTitelImage image image--rounded lazy" :src="srcImageTitel">
          </div>
          <div v-else class = "col-4 col-sm-1 hidden-phone"> 

          </div>
          <!-- </template> -->

          <!-- Inhalt Mitte -->
          <div class="col-12 col-md-5 hotelInfoBox">
             <hotel_mitte :hotel="hotel"/>
          </div>

          <!-- rechts  -->
          <hotel_rechts class="col-12 col-md-3 hotelInfoBox" :hotel="hotel"></hotel_rechts>

        </div>
      </div>

      <!-- Inhalt Links -->
      
      <!-- <button @click="button1">.</button> -->
    </div>
  </div>

</template>

<script>
import Vue from "vue"
import hop from "./../../hop_connection";
import hotel_mitte from './hop_result_hotel_mitte';
import hotel_rechts from './hop_result_hotel_rechts';

// import i18n from "./../i18n";

export default {
  props: ["hotel"],
  data: function() {
    return {
      slide: "",
      proxy: "DataShare2",
    };
  },
  components: {
    hotel_mitte,
    hotel_rechts,
  },
  methods: {
     click_hotelInfo: function() {
            this.$store.commit('statusHotelInfoKey', this.hotel.hotelkey);
            this.$store.commit('statusHotelInfo', true);
            this.$store.commit('selectedHotel', this.hotel);
      },
    buchen: function() {
      // i18n.t("Entfernung-Messe")
        this.$store.commit('selectedHotel', this.hotel);
        this.$store.commit('status','formular');
        this.scrollToTop();
    },
  replaceAll: function (target, search, replacement) {
      // var target = this;
        return target.replace(new RegExp(search, 'g'), replacement);
    },
    getHTMLAdresse: function(html){
      let h2 = this.replaceAll(html,'\\r','').split('\\n')
      let h = "<small>" +  h2[1] + ", "+ h2[2] + '</small>';
      return h
    }
  },
  computed: {
    showImage: function() {
      if (this.hotel.bilder.titel === ''){
        return true;
      }
      if( window.innerWidth <= 1023 ){
        return false;
      } else {
          return true;
      }
    },
    hotelInfoTitel: function() {
      return this.hotel.name;
    },
    hotelInfoDataRight: function() {
      var ret = "Preis EZ: " + hop.formatPreis(this.hotel.ez);
      ret += "<br>Preis DZ: " + hop.formatPreis(this.hotel.dz);
      return ret;
    },
    hotelInfoAdresse: function() {
      var s = this.hotel.adresse;
      var r = this.replaceAll(s, "\\\\n", "<br>");
      r = this.replaceAll(r, "\\\\r", "&#09;");
      console.log(r);
      return r;
    },
    srcImageTitel: function() {
      // console.log(this.hotel.bilder.show.length + " Bilder gefunden ..");
      var src = this.hotel.bilder.path + this.hotel.bilder.titel;
      if (hop.isDomino() == false) {
        src =
          window.location.protocol + "//" + window.location.hostname +
          this.hotel.bilder.path +
          this.hotel.bilder.titel;
      }
      return src;
    },
  },
};
</script>

<style>
.hotelInfoBox {
  /* border: solid 1pt; */

  /* text-align: left; */
  padding-left: 310px;
  font-size: 0.8em;
}
.hotelInfoRight {
  /* border: solid 1pt; */

  text-align: right;
  float: right;
  /* padding-left: 310px; */
  font-size: 0.8em;
}
.hotelTitelImage {
  /* width: 100%;
  height: 100%; */
  margin: 10px 0px 10px 0px;
}
.hotelInfoImage { 
  /* border: solid 1pt; */
    
    text-align: left;
}
.fClear {
  clear: both;
}
.fLeft {
  float: left;
}

.fRight {
  float: right;
}
.buchenAbstand {
  margin-bottom: 5px;
}

@media screen and (min-width: 0px) and (max-width: 960) {
  .hotelTitelImage { display: none; }  /* show it on small screens */
}
@media screen and (min-width: 961) and (max-width: 1024px) {
  .hotelTitelImage { display: block; }   /* hide it elsewhere */
}

</style>