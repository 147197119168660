<template>
  <div>
      <!-- d-flex oder row -->
      <h1 class=col-12>{{ $t("reg_Buchungsdetails") }}</h1>

<hop_timer/>

      <div class="card js-card">
 
       <!-- Card Title -->
      <div class="card__title-row card__title-row--highlight">
        <div class="card__title__left">
            <span style="margin-left: 10px"><big>{{ hotel.name }}</big></span>
            <span style="margin-left: 12px"
              v-if="hotel.rating >= 1"
              class="icon icon-rating-star" />
            <span style="margin-right: 2px"
              v-if="hotel.rating >= 2"
              class="icon icon-rating-star" />
            <span style="margin-right: 2px"
              v-if="hotel.rating >= 3"
              class="icon icon-rating-star" />
            <span style="margin-right: 2px"
              v-if="hotel.rating >= 4"
              class="icon icon-rating-star" />
            <span style="margin-right: 2px"
              v-if="hotel.rating >= 5"
              class="icon icon-rating-star" />
            <span style="margin-right: 10px"></span>
        </div>
      </div>
      <!-- / Card Title -->

      <!-- Card Content -->
      <div class="card__content-row flow">
       <hop_txt_hoteladresse v-if="hotel.adresse!='' && hotel.adresse!=undefined" :adresse="hotel.adresse" :hotelname="hotel.name" style="margin-right:5px"/>
       /
       <hop_btn_hotelinfo :hotelkey="hotel.hotelkey"/>
        <div class="row">
            <div class="col-6">
                {{ vaStartDateSearch }} - {{ vaEndDateSearch }}
                ({{$store.getters.getBookingDays}} {{ $t("Nächte") }})
                
                <div v-if="hotel.isCaravan">
                    <div style="padding-top:5px" v-if="$store.state.ezSearch!='-'">{{$store.state.ezSearch}} x {{ $t("Stellplatz") }}</div>
                    <div v-if="$store.state.dzSearch!='-'">{{$store.state.dzSearch}} x {{ $t("Stellplatz") }}</div>
                </div>
                <div v-else>
                    <div style="padding-top:5px" v-if="$store.state.ezSearch!='-'">{{$store.state.ezSearch}} x {{ $t("Einzelzimmer") }}</div>
                    <div v-if="$store.state.dzSearch!='-'">{{$store.state.dzSearch}} x {{ $t("Doppelzimmer") }}</div>
                </div>
            </div>    

            <div class="col-6" style="text-align: right;">
                <div  style="position: absolute; bottom:0; right:10px">
                    <b>{{ $t("reg_gesamtbetrag") }}: </b> <span class="preis">{{ gesamtPreis() }}</span> <br>
                    <div v-if="!hotel.isCaravan">
                      <div v-if="hotel.isGastro" class="hotelInfos" > {{ $t("reg_zusatzmitfruestueck1line") }}</div>
                      <div v-else class="hotelInfos">{{ $t("reg_zusatzohnefruestueck1line") }}</div>
                      <div class="hotelInfos"><br>{{ $t("hotel_tax")}}</div>
                    </div>
                </div>
            </div>  

        </div>
         <div class="row">



            <!-- <div class="col-2" style = "text-align: right;">{{ $t("reg_Aufenthalt") }}:</div> -->
            <div class="col-12">
                <div style="padding-top:5px" class="hotelInfos">
                        {{ getInfoText }}<br>
                        {{ $t("reg_infoLine2") }} {{ $t("reg_infoLine3") }}<br>
                        <div v-if="hotel.isCaravan">
                          {{ $t("reg_infoLine4Caravan") }}<br><br>
                        </div>
                        <div v-else>
                          {{ $t("reg_infoLine4") }}<br><br>
                        </div>
                </div>
            </div>

          <div class="col-12" style="text-align: right;"> 

          <div v-if="!hotel.isCaravan" class="accordion js-accordion">
            <div class="accordion__title js-accordion-trigger">
               <div style="padding-top:5px" class="hotelInfosArc">
              {{ $t('hotel_taxhead') }}
               </div>
            </div>
            <div class="accordion__body">
              <div class="accordion__body__inner"> 
                <div style="padding-top:5px" class="hotelInfosArc">
                {{ $t('hotel_taxinfo') }} 
                <a target="blank" :href="`${$t('hotel_taxlink')}`"> {{$t('hotel_linktext')}}</a><br>
                </div>
              </div> 
            </div> 
          </div>

          </div>
          
        </div>
    </div>
    <!-- / Card Content --> 

  </div>
      <br>
      <span>
          <!-- @click="$store.commit('status', 'select')" -->
          <a href="javascript:" @click="$store.commit('status', 'search')" class="link link--icon link--icon--left">
              <i class="icon icon-farmers-market-vendor"></i> 
              <span>{{ $t("button_neueSuchanfrage") }}</span>
          </a> /
          <a href="javascript:" @click="$store.commit('status', 'select')" class="link link--icon link--icon--left">
              <i class="icon icon-real-estate-location-house-pin"></i> 
              <span>{{ $t("button_anderesHotelwählen") }}</span>
          </a>
      </span> 



  </div>
</template>

<script>
    /* eslint-disable */ 
import dev from '@/lib/devtools'
// import hop_slideshow from './hop_reg_hotel_slideshow';
import hop_btn_hotelinfo from './../../buttons/hop_btn_hotelInfo';
import hop_timer from '@/components/info/hop_timer.vue';
// import hop_btn_googlemap from './../../buttons/hop_btn_googlemap';
// import hop_txt_hoteltitel from './../../buttons/hop_txt_hoteltitel'
import hop_txt_hoteladresse from './../../buttons/hop_txt_hoteladresse';

export default {
   mixins: [dev ],
    computed:{

        vaEndDateSearch: function(){
          return dev.getDateStringDE(dev.getDate( this.$store.state.vaEndDateSearch ))
        },
        vaStartDateSearch: function(){
          return dev.getDateStringDE(dev.getDate( this.$store.state.vaStartDateSearch ))
        },
        hotel: function() {
            return this.$store.state.selectedHotel
        },
        getInfoText: function(){
            let rn = this.$store.getters.getRoomNights
            // let isDe = false;
            // if (this.$i18n.locale == 'de') { 
            //    isDe = true; 
            // }
            let isDe = this.$i18n.locale == 'de' ? true : false;
            // console.log(this.$i18n.locale + "/" + isDe);
            if( rn >= 31) {
                 if(isDe) {
                    return this.$store.state.vaObj.stornoAb31de
                 }  else {
                    return this.$store.state.vaObj.stornoAb31en
                 }
            } else if( rn>= 6 ) {
                 if(isDe) {
                    return this.$store.state.vaObj.stornoAb6de
                 }  else {
                    return this.$store.state.vaObj.stornoAb6en                     
                 }
            } else {
                 if(isDe) {
                    return this.$store.state.vaObj.stornoBis5de
                 }  else {
                    return this.$store.state.vaObj.stornoBis5en                     
                 }
            }
        //   return this.$store.state.vaObj.stornoBis5de  +"/" + this.$i18n.locale + "/" + rn
        }
    },
    components:{
        // hop_slideshow,
        hop_btn_hotelinfo,
        hop_txt_hoteladresse,
        hop_timer
        // hop_btn_googlemap,
        // hop_txt_hoteltitel
    },
    methods: {
       formatPreis: function (p) {
        var formatter = new Intl.NumberFormat('de-DE', {
          // style: 'currency',
          // currency: 'EUR',
          minimumFractionDigits: 2
        });
        let r = formatter.format((p+'').replace(',','.'))
        return r + " EUR";
     }, 
        replaceAll: function (target, search, replacement) {
          // var target = this;
          try {
            return target.replace(new RegExp(search, 'g'), replacement);            
          } catch (error) {
            console.error(error);
            return target
          }
        },

        getHTMLAdresse: function(html){
            let h2 = html.split('\\n');
            // für r und n - https://stackoverflow.com/questions/20023625/javascript-replace-not-replacing-text-containing-literal-r-n-strings
            let h = h2[1].replace(/(?:\\[rn])+/g, "") + ", "+ h2[2].replace(/(?:\\[rn])+/g, "");
            return h
        },
        // openHotelURL2: function(hotel) {
        // https://www.google.com/maps/search/Leipzig,' + hotel
        //     window.open('https://www.google.de/maps/dir/' + hotel + ',Leipzig/Leipziger Messe GmbH,Messe-Allee,Leipzig','_blank');
        // },
        
    },
    mounted() {
       let timer_counter = 0;
       let lm_timer = setInterval( () => {
           timer_counter++;

           if( typeof LM !== 'undefined'){
              clearInterval(lm_timer);
                $('.js-accordion').each(function () {
                  new LM.Accordion( $(this) );
                })
           }
       })
    }
    
}
</script>

<style>
.hotelInfos{
    font-size: 0.7em;
    color: var(--brand-color)  ;
      /* text-align: right; */
}
.hotelInfosArc{
    font-size: 0.7em;
    color: var(--brand-color)  ;
    text-align: left;
}

.preis {
    font-weight: bold;
    font-size: 1.6em;
    margin-left: 10px;
    color:var(--color-brand-primary)  ;
}
.my-fix-column {
    width: 360px;
    /* background-color: greenyellow;  */
} 
/* .btnPos{
    position: relative;
    top: -20px;
} */
/* https://stackoverflow.com/questions/41914954/how-to-force-a-fixed-column-width-with-a-bootstrap-grid-and-keep-the-other-ones */
@media (max-width: 3000px) {
  .btnPos{ 
    position: relative;
    top: -10px;
    /* color: yellow; */
  }
}
@media (max-width: 1023px) {
  .btnPos{ 
    position: relative;
    top: 0px;
    /* color: red; */
  }
}
@media (max-width: 690px) {
  .my-fix-column {
    display: none; 
  }

} 

.Xborder{
    border: 1px solid red;
}

</style>