<template>
  <div class="distanceButtom">
    <div v-if="isLoadedVA" class="row">
   <!-- vaName: {{$store.state.vaName}}
   Lng: {{getLanguage()}} -->
        <!-- <button class="btn" @click="mixtest">.</button>
        <span v-if="isShow"> -->
          <div>
            <vas :vers="$store.state.veranstaltungen" v-on:changeva="changeVA" />
          </div>
          <div class="distance">
            <datepicker :key="key1" idpicker="idpicker_von" :label="$t('search_Anreise')" v-on:changedate="changeDate" />
          </div>
          <div class="distance">
            <datepicker :key="key2" idpicker="idpicker_bis" :label="$t('search_Abreise')" v-on:changedate="changeDate" />
          </div>
          <div class="distance">
            <room class="room" typ="ez" value="1"  />
          </div>
          <div class="distance">
            <room class="room" typ="dz" value="-"  />
          </div>
        <!-- </span> -->

    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import datepicker from '@/components/search2/datepicker';
import room from '@/components/search2/room';
import vas  from '@/components/search2/vas';
import dev from '@/lib/devtools'
import hop from '@/lib/hoptools'

export default {
  mixins: [hop, dev],
    props:[],
    data() {
        return {
            isShow: true,
            keyCounter: 0,
            isLoadedVA: false,
            editableVA: true,
            von: null, // this.$store.state.VA_startDate, //"05.02.2019",
            bis: null, //this.$store.state.VA_endDate, //"07.02.2019",
        }
    },
    components: {
        datepicker,
        room,
        vas
    },
    computed:{
      key1: function(){
        return "key1" + this.keyCounter
      },
      key2: function(){
        return "key2" + this.keyCounter
      }
    },
    methods:{
      changeVA: function(n) {
        // dev.logg("new change VA => " + n);
        this.$store.dispatch("changeVAName", n);

        let va = this.getVAByName(n); 
        this.$store.commit('vaObj',va);
        // setTimeout( () => {
              // this.$forceUpdate();
            // }, 10);
      },
      changeDate: function(n){
        let dv =  dev.getDate(n.value);
        if(n.idpicker == 'idpicker_von') {
            this.$store.commit('vaStartDate', dev.getDateStringDE(dv));
        } else {
            this.$store.commit('vaEndDate', dev.getDateStringDE(dv) );
        }
      },
      mixtest: function(){
        this.isShow = !this.isShow;
      }
    },
    mounted() {
      dev.logg('mounted search')
        if (false == this.$store.state.veranstaltungen.length) {
            this.loadVA();
        } else {
          this.isLoadedVA = true;
        }
    }
}
</script>

<style>
.flexbox {
  display: flex;
  flex-direction: unset;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
}

.flexboxelement {
  /* background: #779ee2;
  color: #02112a; */
  text-align: center;
  /* width: 24%; */
  margin: 0 0 1% 0;
  padding: 5px 0 5px 0;
}
.room{
  width: 80px;
}
.label{
  text-align: left;
}
.distance{
  margin-left: 10px;
}
.distanceButtom{
  margin-bottom: 25px;
}
</style>