<template>
    <div>
        <template v-if="$store.state.formcycle=='1'">
            <br>
            <div class="card js-card">
            <div class="card__title-row card__title-row--highlight">
                <div class="card__title__left">{{ $t("Formcycle_header") }}</div>
                    <div class="card__title-row__right">
                    <span class="card__action-button card__action-button--bookmark btn btn--unstyled"><i aria-label="Bookmark" class="icon icon-rating-star"/></span>
            </div>
      </div>

      <div class="card__content-row">
        <div class="row">
            <span class="formcycleHint">{{$t('Formcycle_Ausfall')}}</span>            
        </div>
      </div>

            </div>
        </template>
        <template v-else>
            <div id="formcycle">
            </div>
        </template>
    </div>
</template>

<script>
import $ from 'jquery';
import hop from '../../hop_connection';

export default {
    props: ['bnr'],
    methods: { 
        getUrlParameter: function (sParam) {
                var sPageURL = window.location.search.substring(1);
                var sURLVariables = sPageURL.split('&');
                var sParameterName, i;

                for (i = 0; i < sURLVariables.length; i++) {
                    sParameterName = sURLVariables[i].split('=');

                    if (sParameterName[0] === sParam) {
                        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                    }
                }
        }
    },
    mounted() {
        // console.log("formcycle ...");
        let url = hop.getFormcyvleURL(); //conf.url_formcycle;
        let nr = '';
        if (this.bnr != ''){
            nr = this.bnr
        } else {
            nr = this.getUrlParameter("bnr")
        }
        let lng = this.$i18n.locale;
        // console.log(nr + '-Link-' + lng + ': ' + url + '&bnr=' + nr + '&lang=' + lng);

        $(document).ready(function(){
			var content = $('#formcycle');
			content.children().remove();
            console.log(url + '&bnr=' + nr + '&lang=' + lng);   
			//Erzeugen des iFrame
			var iframe = $('<iframe>', {
                src: url + '&bnr=' + nr + '&lang=' + lng,
                id: 'xm-form',
                width:'100%',
                frameborder: 0,
                scrolling: 'no'
			}).appendTo(content);

			//Automatische Größenänderung des iFrame 
			window.addEventListener("message", 
            function(post){	   	  				   	  			
                if (iframe.length <= 0) return;
                iframe.height(post.data.height);
            },
			false);
		})
    }  
}
</script>

<style>

.formcycleHint {
    color: red;
}
</style>