<template>
  <div> 
      <div class="alert alert--warning" role="alert">
        <strong>{{ $t("gruppenbuchung_info") }}</strong>
        <hr>
        <a  @click="click_Gruppenbuchung" href="javascript:console.log('')" class="link link--icon">
         <i class="icon icon-farmers-market-vendor"></i> 
          <span>{{ $t("button_Gruppenbuchung") }}</span>
         </a>
            
            <a href="javascript:" @click="$store.commit('status', 'search')" class="link link--icon link--icon--left">
                <i class="icon icon-farmers-market-vendor"></i> 
                <span>{{ $t("button_neueSuchanfrage") }}</span>
            </a>
      </div>

  </div>
</template>

<script>
export default {
    methods: {
            click_Gruppenbuchung: function() { 
                this.$store.commit('vaNameSearch',this.$store.state.vaName)
                this.$store.commit('vaStartDateSearch',this.$store.state.vaStartDate);
                this.$store.commit('vaEndDateSearch',this.$store.state.vaEndDate);
                this.$store.commit('vaIDSearch',this.$store.getters.getVAID);
                this.$store.commit('statusGruppenFormular', true);
            }
    }
}
</script>

<style>

</style>