<template>
    <!-- flex-row -->
    <div v-if="isLoadedVA">
      <div class="row">
          <!-- class="row" -->
        <div class="abstandBox">
          <hop_fgroup_vaselect :vers="$store.state.veranstaltungen" v-on:changeva="changeVA" :editable="editableVA"></hop_fgroup_vaselect>
        </div>
      </div>

      <div class="row">
          <div class="abstandBox">
          <small>
          {{ $t("hinweis_buchung1") }} {{ displayZeiten }}<br>
          {{ $t("hinweis_buchung2") }}
        </small>
        </div>
      </div>

      <div class="row">
         <div class="col-12 trenner"> </div>
        <div class="abstandBox">
          <br>
          <h4 style="color: var(--color-brand-primary)"> 
            {{ $t("gruppe_Buchungshinweis") }} 
          </h4>
        </div>
      </div>

      <div class="row">
        <!-- erzwungener Zeilenumbruch bei kleiner Größe -->
      <div class="col-12 trenner"></div>

        <div class="abstandBox">
          <hop_datepicker typ="start" :label="$t('search_Anreise')" :key="$store.state.key_search_start" :value="von" id="anreiseDP" />
        </div>
        <div class="abstandBox">
          <hop_datepicker typ="ende" :label="$t('search_Abreise')" :key="$store.state.key_search_end" :value="bis" id="abreiseDP"/>
        </div>
    
        <!-- erzwungener Zeilenumbruch bei kleiner Größe -->
      <div class="col-12 trenner"></div>

        <div class="abstandBox">     
          <hop_zimmer typ="ez" :label='$t("ez")'  value="1"/>   
      </div>

      <div class="abstandBox">     
          <hop_zimmer typ="dz" :label='$t("dz")' value="-" />   
      </div>

        <!-- <button class="btn btn--primary" @click="click">.</button> -->
  
      </div>
  </div>
</template>

<script>
import hop_fgroup_vaselect from "./hop_fgroup_vaselect";
import hop_datepicker from "./hop_datepicker";
import hop_zimmer from "./hop_zimmer";
// import con from "./../../hop_connection";
import i18n from "./../../i18n";
import dev from '@/lib/devtools'
import hop from '@/lib/hoptools'

export default {
   mixins: [dev,hop],
  props: ["newsearch","isLoaded"],
  data() {
    return {
      editableVA: true,
      isLoadedVA: false,
      show: true,
      von: this.$store.state.VA_startDate, //"05.02.2019",
      bis: this.$store.state.VA_endDate, //"07.02.2019",
      hotelsFound: 0,
      isHOPSearch: false // Suchergebnis, nach der 1. Suche = true
    };
  },
  watch:{
    newsearch: function(){
      this.isHOPSearch = false;
    },
    isLoadedVA: function(n,o) {
       this.$emit("isVALoaded", n) 
    }
  },
  components: {
    hop_fgroup_vaselect,
    hop_datepicker,
    hop_zimmer
  },
  computed: {
    displayZeiten: function(){
    return this.$store.state.vaObj.start  + " - " + this.$store.state.vaObj.ende
    }
  },
  methods: {
     changeVA: function(n) {
        // dev.logg("new change VA => " + n);
        this.$store.dispatch("changeVAName", n);

        let va = this.getVAByName(n); 
        this.$store.commit('vaObj',va);

        this.von = this.$store.state.VA_startDate //"05.02.2019",
        this.bis = this.$store.state.VA_endDate //"07.02.2019",
        // setTimeout( () => {
              // this.$forceUpdate();
            // }, 10);
      },
    click: function(){

       setTimeout( () => {
              this.$forceUpdate();
            }, 10);
      this.$store.commit('key_search_start','3445');
      // console.log(this.$store.state.key_search_start);
    }
  },
  mounted(){
      if (false == this.$store.state.veranstaltungen.length) {
          this.loadVA();
        } else {
            this.isLoadedVA = true;
            let rid = dev.getUrlParameter('va');
            if (rid!='' && rid!=undefined){ 
              this.$store.commit('vaName', this.$store.state.vaNameSearch);
              this.$store.dispatch('changeVAName',this.$store.state.vaNameSearch);
              // dev.logg('OK single VA')
              // console.table(this.$store.state.vaNameSearch);
              this.editableVA = false;
              // this.$store.dispatch('pushNewDate');
          }
        }
     }
};
</script>
<style>
.border {
  border: solid 0px;
}
.abstandBox{
  margin-left: 10px;
}
.trenner{
  display: none;
}
@media only screen and (max-width: 64em) and (min-width: 0em)  { 
  .trenner{
    display: block;
  }
}
</style>