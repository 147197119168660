<template>
  <div>

    <div v-if="editable" class="form-group">
      <label class="label" @click="la_click" for="veranstaltung">{{ $t("search_Veranstaltung") }}</label>
        <!-- $store.state.veranstaltungen -->

      <template v-if="checkDE">
<!-- DE -->
        <!-- :value="getVASelect" -->
      <select
        id="veranstaltung"
        ref="VA"
        @change="changeVASelect"
        :value="selectedVA"
        class="select input input--text">
        <option v-for="va in vers" :key="va.rid">
          <!-- <div v-if="va.selectable='0'"> -->
          {{ va.name }}
          <!-- </div> -->
        </option>
      </select>

      </template>

      <template v-else>
        <!-- EN -->
<!--         :value="getVASelect"  -->
      <select
        id="veranstaltung"
        ref="VA"
        @change="changeVASelect"
        :value="selectedVA"
        class="select input input--text">
        <option v-for="va in vers" :key="va.rid">
          <!-- <div v-if="va.selectable='0'"> -->
          {{ va.nameen }}        
            <!-- </div> -->
        </option>
      </select>

      </template>

          <!-- </template>   -->
      <!-- <button @click="click"></button> -->
    </div> 
    <div v-else class="form-group">
      <label class="label" for="veranstaltung">{{ $t("search_Veranstaltung") }}</label>
      <div v-if="checkDE"> {{ $store.state.vaObj.name }}</div>
      <div v-else> {{ $store.state.vaObj.nameen }} </div>
    </div> 

  </div>
</template>
<script>
import hop from '@/lib/hoptools'
import dev from '@/lib/devtools'
import i18n from '../../i18n';

export default {
  mixins: [dev, hop],
  props:['vers','editable'],
  data() {
    return {
      selectedVA: (dev.getUrlParameter("lg")=="en" | dev.getUrlParameter("lng")=="en") ? this.vers[0].nameen : this.vers[0].namede ,
      show: false,
      veranst: {}
    };
  },
  computed: {   
    checkDE: function(){
      return this.$i18n.locale == 'de' ? true : false;
    },
    changeLang: function(){
       if(this.isDE()){
         return "DE";
      } else {
         return "EN";
      }
    }
  } ,
  watch:{
    '$i18n.locale'(newLocale, oldLocale) {
      // Code, der bei Änderungen von this.$i18n.locale ausgeführt wird
      // console.log('Locale geändert-:', newLocale);
      // Weitere Aktionen ausführen...
    },
    selectedVA: function(n,o){
      // console.log("selectedVA-N:" + n + " Old:" + o);
    },
    changeLang: function(n, o){
      // console.log("Old=" + o + " New=" + n + " selectedVA=" + this.selectedVA + " store=" + this.$store.state.vaNameSearch);
      let v1 = this.getVAByName(this.selectedVA)
      let newname = ""
      setTimeout( ()=>{if (n == "DE"){
         newname = v1.name;
      } else {
         newname = v1.nameen;
      }
        this.selectedVA = newname;
        this.$emit('changeva',  newname); 
        this.$store.dispatch('changeVAName',newname);
        this.$store.dispatch('pushNewDate');
      },500)

    }
  },
  methods: {
    la_click: function() {
      
    },
    changeVASelect: function(event){
        this.$emit('changeva',  event.target.value); 
        this.$store.dispatch('changeVAName',event.target.value);
        this.$store.dispatch('pushNewDate');
    },
    click: function() {
         this.$forceUpdate();
    },
  },
  mounted() {
    if( this.$store.state.vaNameSearch != "") {
         this.selectedVA = this.$store.state.vaNameSearch
        // console.log("mounted=" + this.selectedVA)
         try {
          this.$store.commit('vaName', this.$store.state.vaNameSearch);
//          console.log("changeVAName1")
          this.$store.dispatch('changeVAName',this.$store.state.vaNameSearch);
        //   this.$store.commit('vaStartDate', this.$store.state.vaStartDateSearch)
        //   this.$store.commit('vaEndDate', this.$store.state.vaEndDateSearch)
        //   this.$store.dispatch('pushNewDate');
          
         } catch (error) {
           console.log("error"); 
         }
    }

   
  }
};
</script>

<style>
/* .vaSelect {
  align-content: left;
} */
</style>