  import dev from '@/lib/devtools.js'

  export default {
    maxLevel : 10,  
    methods: {
      hello: function(){
        console.log("hello from mixin!");
        console.log(this.$store.state.vaName);
        console.log(window.location.href);
      }
    },
    // ging nicht .. ?
    hotelInformationen: function(){
      this.$store.commit('statusHotelInfoKey', this.hotelkey);
      this.$store.commit('statusHotelInfo', true);
      if (this.hotel) {
          this.$store.commit('selectedHotel', this.hotel);
      }

     },
    logg: function(txt){
       if (false == this.isDomino()) {
         console.log(txt);
       }
    },
    getMonthLabel: function(i){
      switch (i) {
         case 'Jan':
            return 0
         case 'Feb':
            return 1
         case 'Mar':
            return 2
         case 'Apr':
            return 3
         case 'May':
            return 4
         case 'Jun':
            return 5
         case 'Jul':
            return 6
         case 'Aug':
            return 7
         case 'Sep':
            return 8
         case 'Oct':
            return 9
         case 'Nov':
            return 10
         case 'Dec':
            return 11
        default:
            0
      }
    },
    //** aus "14.03.2022" wird ein JS-DateValue */
    getDate: function (datval_) {
      let datval = datval_.toString()
       if(datval == null) {
          return datval
       }
       
      //  console.log("input-datval=" + datval.toString(0));
      // let v = datval.split(".");
      // // return new Date(v[2], v[1] - 1, v[0])  
      // if( datval instanceof Date){
      //    return datval;
      // }
      if(datval.indexOf('/')>0){
         let v = datval.split("/");
         return new Date(v[2], v[0] - 1, v[1])   

      } else if(datval.indexOf('00:')>0){
         let v = datval.split(" ");
      //  dev.logg('00:' + datval + " ====> " + v[3] + "," +  this.getMonthLabel(v[1]) + "," + v[2])
         return new Date(v[3], this.getMonthLabel(v[1]), v[2] ) 

      } else {
         let v = datval.split(".");
         
         let d = new Date(v[2], v[1] - 1, v[0]) 
         // dev.logg("getDate: " + datval + "=>" + d.toDateString());      
         return new Date(v[2], v[1] - 1, v[0])   
      }
   }, 
    // ** macht aus JS-Date ein String, z.B.: '14-03-2021' */
    getDateStringEN: function(dat) {
       let m = ("0" + (dat.getMonth() + 1)).substr(-2);
       let d = ("0" + (dat.getDate() )).substr(-2);
      //  return dat.getFullYear() + "-" + m + "-" + d;
       return  m + "/" + d + "/" +  dat.getFullYear();
    },
    // ** macht aus JS-Date ein String, z.B.: '14.03.2021' */
    getDateStringDE: function(dat) {
      let m = ("0" + (dat.getMonth() + 1)).substr(-2);
      let d = ("0" + (dat.getDate() )).substr(-2);
      return  d + "." + m + "." +  dat.getFullYear();
    },
    // ** ändert  "04.11.2023" in "11.04.2023" */
    convertDateString: function(dateString){
try {
         let dateParts = dateString.split('.');
         [dateParts[0], dateParts[1]] = [dateParts[1], dateParts[0]];
         return dateParts.join('.')
   
} catch (error) {
 //  this.logg(error)
   return dateString
}    },
    getUrlParameter: function (sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        var sParameterName, i;
    
        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
    
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
      },
      isDomino: function () {
         return (window.location.href.indexOf('8080')>-1 || window.location.href.indexOf('localhost')>-1 ) ? false : true 
         // return window.location.port == 80 || window.location.port == 443;
      },
      logJson: function (level_, v_) {
        if (typeof v_ === "object") {
           for (var key in v_) {
              if (key == '_renderProxy' || key == "offsetParent") {
                 break
              }
              if (level_ !== key) {
                 if (Array.isArray(v_)) {
                    this.logJson(level_ + "['" + key + "']", v_[key]);
                 } else {
                    this.logJson(level_ + "." + key, v_[key]);
                 }
              }
           }
        } else {
         this.logg(level_ + "=" + v_);
        }
     },
     logJsonL: function (level_, v_, levelDeep_) {
      if (typeof v_ === "object") {
         for (var key in v_) {
            if (key == '_renderProxy' || key == "offsetParent") {
               break
            }
            if (level_ !== key) {
               if (Array.isArray(v_)) {
                  if (levelDeep_<this.maxLevel){
                     this.logJson(level_ + "['" + key + "']", v_[key], levelDeep_++ ); 
                  }
               } else {
                  if (levelDeep_<this.maxLevel){
                    this.logJson(level_ + "." + key, v_[key], levelDeep_++ );
                  }
               }
            }
         }
      } else {
         this.logg(level_ + "=" + v_);
      }
   },
  };