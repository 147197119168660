import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue'
import i18n from './i18n' 
import store from './lib/store.js'
// import va_store from './lib/va_store.js'
import axios from 'axios';
import VueGtag from "vue-gtag";
// import lmMixin from './lib/tools'

Vue.use(VueGtag, {
  config: { id: "GTM-M5WP4L3" }
});

/*global LM,$ */

Vue.config.productionTip = false
// Vue.config.devtools = true;
// Vue.use(i18n);

var vm = new Vue({
  // mixins: [lmMixin],
  i18n,
  store,
  axios,
  render: h => h(App)
}).$mount('#app');

Vue.mixin({
  methods: {
    testABC: function(){
      return "ABC-1"
    },
    gesamtPreis: function(){
      try {
        let preis = this.$store.state.selectedHotel.ez.replace(',','.') * this.$store.getters.getCountEZ + this.$store.state.selectedHotel.dz.replace(',','.') * this.$store.getters.getCountDZ;
        preis = preis * this.$store.getters.getBookingDays;
        return this.formatPreis(preis)  
      } catch (error) {
        console.error(error);
      }
    },
    formatPreis: function (p) {
      var formatter = new Intl.NumberFormat('de-DE', {
        // style: 'currency',
        // currency: 'EUR',
        minimumFractionDigits: 2
      });
      let r = formatter.format((p+'').replace(',','.'))
      return r + " EUR";
     },
     scrollToTop: function(){
        $('.js-scroll-top').each(function () {
          let lmw = new LM.ScrollTop( $(this) );
          lmw.scrollToTop();
      });  
     },
    getHOPUrl: function(href){
      return this.getNotesURL(href) + "/cmd?openAgent"
    },
    getHOPUrlJ: function(href){
      return this.getNotesURL(href) + "/jcmd?openAgent"
    },
    testAlert: function(what){
      alert(what);
    },
    getKKFormularURL: function(href){
        return this.getNotesURL() + "/print?openForm"; 
    },
    getNotesURL: function(href){
      let url = href;
      if(url == ''){
        url = window.location.href;
      }
      let path = '';
      if(url.indexOf( ".nsf" )>0) {
        path = url.substring( 0, url.indexOf( ".nsf" ) + 4 );
      } else {
        path = '/proxy/LM/QAs/webHotel.nsf';
      }
      return path;
    },
    getUrlParameter: function (sParam) {
      var sPageURL = window.location.search.substring(1);
      var sURLVariables = sPageURL.split('&');
      var sParameterName, i;
  
      for (i = 0; i < sURLVariables.length; i++) {
          sParameterName = sURLVariables[i].split('=');
  
          if (sParameterName[0] === sParam) {
              return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
          }
      }
    },
    convertString: function(v) {
      let ret   = encodeURI(v);
      return ret;
     },
  // cl: function(lng){
  //   if(lng == '') {
  //     if( this.$i18n.locale == 'en') {
  //       this.$i18n.locale == 'de'
  //     } else {
  //       this.$i18n.locale == 'en'
  //     }
  //   } else {
  //     this.$i18n.locale == lng
  //   }
  // },


    isBundy: function(){ 
      // i18n.locale = 'en';
      var typ = this.getUrlParameter('typ');
      // console.log("Typ = " + typ);
      if(typ == "d1")
          return true;
      if (document.location.host.indexOf("asterix") >= 0) 
          return false;
      if (document.location.host.indexOf("troubadix") >= 0) 
          return false;
      if (document.location.host.indexOf("messe") >= 0) 
          return false;
      if (document.location.host.indexOf("172.23") >= 0) 
          return false;
      if (document.location.host.indexOf("172.24") >= 0) 
          return false;
      return true;
    },
    openCenteredWindow: function(url, height, width, name, parms) {
      var left = Math.floor( (screen.width/2 - width/2) );
      var top = Math.floor( (screen.height/2 - height/2) );
      var winParms = "top=" + top + ",left=" + left + ",height=" + height + ",width=" + width;
      if (parms) { winParms += "," + parms; }
      var win = window.open(url, name, winParms);
      // if (parseInt(navigator.appVersion) >= 4) { win.window.focus(); }
      return win;
    },
    replaceAll: function (target, search, replacement) {
      // var target = this;
      return target.replace(new RegExp(search, 'g'), replacement);
    },
    strRight: function (seperator) {
      var pos = this.indexOf(seperator);
      return (pos == -1 | seperator == '') ? '' : this.substr(pos + seperator.length);
    },
    strLeft: function (seperator) {
      var pos = this.indexOf(seperator);
      var result = this.substring(0, pos);
      return result;
    },
    formatKM: function (km) {
      var formatter = new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 1
      });
      return formatter.format(km.replace(',', '.')) + " km";
    },
    testMix: function (d) {
      // console.log("Mix->" + d);
    }
  }
});


// console.log("vm-" + typeof(vm));
// console.log(i18n.t("laender")[1]);