<template>
    <div class="abstandlinks abstandoben">
       <table>
            <tr class="tr_boarder">
                <td>{{ $t("hotel_Messegelände") }}</td>
                <td class="table_padding_left"><nobr>{{ formatKM(hotel.kmmesse) }}</nobr></td>
            </tr>
            <tr class="tr_boarder">
                <td>{{ $t("hotel_Kongresshalle") }}</td>
                <td class="table_padding_left"><nobr>{{ formatKM(hotel.kmkongress) }}</nobr></td>
            </tr>
            <tr class="tr_boarder">
                <td>{{ $t("hotel_Stadtzentrum") }}</td>
                <td class="table_padding_left"><nobr>{{ formatKM(hotel.kmstadt) }}</nobr></td>
            </tr>
            <tr class="tr_boarder">
                <td>{{ $t("hotel_Hauptbahnhof") }}</td>
                <td class="table_padding_left"><nobr>{{ formatKM(hotel.kmzug) }}</nobr></td>
            </tr>
            <tr class="tr_boarder">
                <td>{{ $t("hotel_Flughafen") }}</td>
                <td class="table_padding_left"><nobr>{{ formatKM(hotel.kmflug) }}</nobr></td>
            </tr>
        </table>
        <br>

        <hop_btn_hotelinfo :hotelkey="hotel.hotelkey" :hotel="hotel" />
        <br>
        
        <hop_btn_googlemap :hotel="hotel" style="padding-top:5px"/>
        <br><br>

    </div>
</template>

<script>
import hop_btn_hotelinfo from './../buttons/hop_btn_hotelInfo';
import hop_btn_googlemap from './../buttons/hop_btn_googlemap';

export default {
    props: ['hotel'],
    components: {
         hop_btn_hotelinfo,
         hop_btn_googlemap
    },
    methods:{
        HotelInfo: function(){
            console.log('TODO / Zulieferung Inhalt')
        },
        openHotelURL1: function(hotel) {
               window.open('https://www.google.de/maps/search/' + hotel + ',Leipzig','_blank');
        },
        openHotelURL2: function(hotel) {
               window.open('https://www.google.de/maps/dir/' + hotel + ',Leipzig/Leipziger Messe GmbH,Messe-Allee,Leipzig','_blank');
        },
        formatKM: function (km) {
            var formatter = new Intl.NumberFormat('de-DE', {
                style: 'decimal',
                minimumFractionDigits: 1
            });
            let ret = formatter.format(km.replace(',', '.')); 
            return ret + " km";
        },
        hotelInfoAdresse: function() {
            let s = this.hotel.adresse;
            let r = s;
            r = this.replaceSubstring(r, "\\n", "<br>");
            r = this.replaceSubstring(r, "\\r", "&#09;");
            console.log(r);
            return r;
        },
        
        replaceSubstring: function( string, search, replace ) {
            let s = string;
            while (s.indexOf( search )>-1) {
                s = s.replace(search,replace);
            }
            return s;
        }

    }
}
</script>

<style>
.tr_boarder{
    border-bottom: 1px solid lightgray;
}
.table_padding_left{
     padding-left: 10px
}
.abstandoben{
    padding-top: 10px;
}
.abstandlinks {
    margin-left: 10px;
}
.iconabstand{
    padding-right: 5px;
}
</style>