<template>
  <div>
    <div v-if="isEnabled" class="alert-wrapper">

      <div class="alert alert--info" role="info">

    <div v-if="!isError">
        <!-- <small>{{ $store.state.vaName}}</small><br> -->
        <small>{{ $store.state.selectedHotel.name }}</small
        ><br />
        {{ $t("timer_vorgemerkt") }} {{ timerM }}:{{ timerS }} <br />
        <a href="javascript:" @click="refreshTimer()">
          <small>{{ $t("timer_refresh") }}</small>
        </a>
      </div>

      <div v-if="isError">
        <small>{{ $store.state.selectedHotel.name }}</small><br />
        {{$t('timer_expired')}} <small><a href="javascript:" @click="prerequest()">{{ $t("timer_retry") }}</a></small>
      </div>

      <!-- <div>
        <a href="javascript:" @click="checkBooking()">check booking</a>
      </div> -->

      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import hop from "@/lib/hoptools";
import dev from "@/lib/devtools";
export default {
  mixins: [dev, hop],
  props: {
    // bnrcode: {
    //   type: String,
    //   default() {
    //     return "";
    //   },
    // },
  },
  data() {
    return {
      bnrcode: "",
      isEnabled: false,
      isError: false,
      timerSecond: 0,
      timerMinutes: 10,
      timerS: "00",
      timerM: "10",
    };
  },
  methods: {
    initTimer: function () {
      this.timerSecond = 0;
      this.timerMinutes = 10;
      this.timerS = "00";
      this.timerM = "10";
    },
    refreshTimer: function () {
      let url = this.getHOPUrl(window.location.href) + "&cmd=timerreset";
      url += "&va=" + this.$store.getters.getVAID;
      url += "&an=" +  dev.getDateStringDE(dev.getDate(this.$store.state.vaStartDate));
      url += "&ab=" + dev.getDateStringDE(dev.getDate(this.$store.state.vaEndDate));
      url += "&ez=" + this.$store.state.ez;
      url += "&dz=" + this.$store.state.dz;
      url += "&hotel=" + this.$store.state.selectedHotel.name;
      url += "&code=" + this.bnrcode;
      url += "&ti=" + this.timerMinutes.toString();
      axios.get(url).then((resp) => {
        if (resp.data.status == "ok") {
          this.isEnabled = true;
          this.isError = false;
          this.initTimer();
          this.countDownTimer();
        } else {
          this.$store.commit('status',"notprebooking")
        //   this.isEnabled = false;
        //   this.isError = true;
        }
      });
    },
    countDownTimer: function () {
      if (this.isEnabled) {
        if (this.timerSecond > 0) {
          this.timerSecond--;
        } else if (this.timerMinutes > 0) {
          this.timerSecond = 59;
          this.timerMinutes--;
        }
        this.timerS = this.timerSecond < 10  ? "0" + this.timerSecond.toString() : this.timerSecond.toString();
        this.timerM = this.timerMinutes < 10 ? "0" + this.timerMinutes.toString() : this.timerMinutes.toString();
        if (this.timerSecond + this.timerMinutes == 0) {
          this.isError = true;
          this.isEnable = false;
        } else {
          setTimeout(() => {
            this.countDownTimer();
          }, 1000);
        }
      }
    },
    checkBooking: function () {
      let url = this.getHOPUrl(window.location.href) + "&cmd=checkbooking";
      url += "&va=" + this.$store.getters.getVAID;
      url += "&an=" +  dev.getDateStringDE(dev.getDate(this.$store.state.vaStartDate));
      url += "&ab=" + dev.getDateStringDE(dev.getDate(this.$store.state.vaEndDate));
      url += "&ez=" + this.$store.state.ez;
      url += "&dz=" + this.$store.state.dz;
      url += "&hotel=" + this.$store.state.selectedHotel.name;
      url += "&code=" + this.bnrcode;
      axios.get(url).then((resp) => {
        if (resp.data.status != "ok") {
            this.$store.commit('status',"notprebooking")
        }
      });
    },
    prerequest: function(){
        this.initTimer();
        let url = this.getHOPUrl(window.location.href) + "&cmd=prerequest";
        url += "&z=" + this.$store.getters.getCountZimmer;
        url += "&va=" + this.$store.getters.getVAID;
        url += "&an=" + dev.getDateStringDE(dev.getDate(this.$store.state.vaStartDate));
        url += "&ab=" + dev.getDateStringDE(dev.getDate(this.$store.state.vaEndDate));
        url += "&ez=" + this.$store.state.ez;
        url += "&dz=" + this.$store.state.dz;
        url += "&hotel=" + this.$store.state.selectedHotel.name;
        url += "&code=" + this.bnrcode;

        url += "&ti=" + this.timerMinutes.toString();

        // console.log('url=' + url);
        axios.get(url).then((resp) => {
        // console.log(resp.data);
        if (resp.data.status == "ok") {
            this.bnrcode = resp.data.code;
            this.$store.commit('precode',this.bnrcode);
            this.isEnabled = true;
            this.isError = false;
            this.countDownTimer();
        } else {
            this.isEnabled = false;
            this.isError = true;
            this.$store.commit('status',"notprebooking")
        }
        });
    }
  },
  mounted() {
      this.prerequest()
  },
  beforeDestroy(){
  //  console.log("beforeDestroy:" + this.bnrcode); 
   if(this.bnrcode != "") {
      let url = this.getHOPUrl(window.location.href) + "&cmd=timerdelet";
      url += "&code=" + this.bnrcode;
  
     axios.get(url).then((resp) => {
         if (resp.data.status == "ok") {
          //  console.log("Y-" + this.bnrcode);
       } else {
          //  console.log("N-" +this.bnrcode);
       }
     });
   }
  }
};
</script>

<style>
</style>