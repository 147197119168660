<template>
  <div>

<div v-if="showHint" class="alert alert--warning" role="alert">
  <strong>Lade!</strong> Hoteldaten ...
</div>

<div v-else>  

  <div class="card js-card">
    <!-- Card Title -->
    <div class="card__title-row card__title-row--highlight">
      <div class="card__title__left">
          <span style="margin-left: 10px"><big>{{ hotel.name }}</big></span>
          <span style="margin-left: 12px"
            v-if="hotel.rating >= 1"
            class="icon icon-rating-star" />
          <span style="margin-right: 2px"
            v-if="hotel.rating >= 2"
            class="icon icon-rating-star" />
          <span style="margin-right: 2px"
            v-if="hotel.rating >= 3"
            class="icon icon-rating-star" />
          <span style="margin-right: 2px"
            v-if="hotel.rating >= 4"
            class="icon icon-rating-star" />
          <span style="margin-right: 2px"
            v-if="hotel.rating >= 5"
            class="icon icon-rating-star" />
          <span style="margin-right: 10px"></span>
      </div>
    </div>
    <!-- / Card Title -->

    <!-- Card Content -->
    <div class="card__content-row flow">

      <template v-if="hotelkey!='' && hotelkey!=undefined">
          / <small @click="click_hotelInfo">
            <i class="icon icon-farmers-market-vendor"></i>
             {{ $t('info_hotelinformation')}}
          </small>
      </template>

      <hop_slideshow :hotel="hotel" :hotelinfos="hotel.Hotelausstattung"/> 

    </div>
    <!-- / Card Content -->
  </div>


    <!-- <hop_txt_hoteltitel :hotel="hotel"/> -->
  <!-- <hop_txt_hoteladresse :adresse="hotel.adresse" class="adressenPosition"/> -->
      <div class="row">
        <div class="col-12">
        </div>
      </div>

    <br>

    <div class="row">
      <div class="col-6">
        <div v-if="hotel.isCaravan">
          <h2>{{$t('info_lageStellplatz')}}</h2>
        </div>
        <div v-else>
          <h2>{{$t('info_lagehotel')}}</h2>
        </div>
      </div>
    <div class="col-6">
      <button class="btn btn--primary" style="margin-bottom:10px; margin-left:100px" @click="goBack()">{{ $t("zurück") }}</button>
    </div>

    </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <hop_txt_tab_entfernungen :hotel="hotel" />
          <br>
          <hop_txt_hoteladresse v-if="hotel.adresse!='' && hotel.adresse!=undefined" :adresse="hotel.adresse" :hotelname="hotel.name"/>
        <!-- <hop_btn_googlemap :hotel="hotel"/> -->
        </div>
        <div class="col-12 col-md-8">
          <googlemaps  :mapConfig="mapConfig" apiKey="AIzaSyCyTMsQML37Rc0wsHEAeQgmG8NwrW7lqCw" :adresse="getHTMLAdresse(hotel.adresse)" :title="hotel.name"></googlemaps>
        </div>
        <!-- {{getHTMLAdresse(hotel.adresse)}} -->
      </div>
    <br>

  <template v-if="hotel.kurzbeschreibung!=''">
    <br>
    <div v-if="hotel.isCaravan">
      <h2>{{$t('info_Stellplatzbeschreibung')}}</h2>
    </div>
    <div v-else>
      <h2>{{$t('info_hotelbeschreibung')}}</h2>
    </div>
    <p>{{hotel.kurzbeschreibung}}</p>
  </template>

  <template v-if="isNotEmpty(hotel.Hotelausstattung)">
    <br>
    <h2>{{$t('info_hotelaustattung')}}</h2>
    <p class="text-col">
      <ul class="list" >
        <li v-for="it in hotel.Hotelausstattung" :key="it" ><span> {{it}}</span></li>
      </ul>
    </p>
  </template>

  <template v-if="isNotEmpty(hotel.Zimmerausstattung)">
    <br>
    <h2>{{$t('info_zimmerausstattung')}}</h2>
    <p class="text-col">
      <ul class="list">
        <li v-for="it in hotel.Zimmerausstattung" :key="it"><span>{{it}}</span></li>
      </ul>
    </p>
  </template>

  <template v-if="isNotEmpty(hotel.Gastronomie)">
    <br>
    <h2>{{$t('info_gastronomie')}}</h2>
    <p class="text-col">
      <ul class="list">
        <li v-for="it in hotel.Gastronomie" :key="it"><span>{{it}}</span></li>
      </ul>
    </p>
  </template>

  <template v-if="isNotEmpty(hotel.Parken)">
    <br>
    <h2>{{$t('info_parken')}}</h2>
    <p class="text-col">
      <ul class="list">
        <li v-for="it in hotel.Parken" :key="it"><span>{{it}}</span></li>
      </ul>
    </p>
  </template>

  <template v-if="hotel.weitereinfos!=''">
    <br>
    <h2>{{$t('info_weitereinfo')}}</h2>
    <p v-html="weitrereInfos"></p>
  </template>

  <br>
  <div class="alert alert--warning" role="alert">
    {{$t('info_disclaimer')}}
  </div>

  <br>

      <button class="btn btn--primary" @click="goBack()">{{ $t("zurück") }}</button>
      <!-- <button style="margin-left: 5px" class="btn btn--primary" @click="buchen()">{{ $t("button_jetztbuchen") }}</button> -->
  
  <!-- <div class="row">
    <div class="col-6">
    </div>
    <div class="col-6">
    </div>
  </div> -->
  <!-- <a @click="buchen" href="#" class="buchenAbstand btn btn--right btn--secondary buchenbutton">
  </a> -->
  <!-- {{loaded}} -->

  </div>
  <!-- / v-else -->
</div>
</template>

<script>
import dev from '@/lib/devtools'
// import hop from '@/lib/hoptools'
import axios from 'axios';
import hop from './../../hop_connection';
// import hop_btn_googlemap from './../buttons/hop_btn_googlemap';
import hop_txt_hoteladresse from './../buttons/hop_txt_hoteladresse';
import hop_slideshow from './../formular/hotel/hop_reg_hotel_slideshow';
import hop_txt_tab_entfernungen from './../buttons/hop_txt_tab_entfernungen.vue';
// import hop_txt_hoteltitel from './../buttons/hop_txt_hoteltitel';
import googlemaps from './../html/GoogleMapLoader'

export default {
  mixins: [dev],
  props:['test','hotelkey'],
  components: {
    // hop_btn_googlemap,
    hop_txt_hoteladresse,
    hop_slideshow,
    hop_txt_tab_entfernungen,
    // hop_txt_hoteltitel,
    googlemaps
  },
  computed: {
    hotelaustattung() {
      //hotel.Hotelausstattung
      let ret = [];
      this.hotel.Hotelausstattung.forEach(element => ret.push(element.replace("\n","<br>")));
        // return this.hotel.Hotelausstattung.replace("\n","<br>");
        for(var i = 0; i<this.hotel.Hotelausstattung.length; i++){
          console.log(i + " - " + this.hotel.Hotelausstattung[i].replace("\n","<br>"));
        }
        return ret;
    },
   mapConfig() {
      return { 
        // ...mapSettings, 51.3528984,12.4061528,13z
        center: { lat: 51.3528984, lng: 12.4061528 },
        zoom: 13
      }
    }
  },
  methods: {

    buchen: function() {

     this.$store.commit('selectedHotel', this.hotel);
     this.$store.commit('statusHotelInfo',false)
     this.$store.commit('status','formular');
     this.scrollToTop();
     },
    goBack: function(){
     this.$store.commit('statusHotelInfo',false)
     this.scrollToTop();
    },
    isNotEmpty: function(va){
      //  return true;
      try {
        // if(va) {  
          if( va[0]!='' && va[0]!=undefined){
            return true;
          }  else  {
            return false;
          }
        // }
       } catch(err) { 
        console.log(va)
        return false;
      } 
  },
     getHTMLAdresse: function(adr){
            try {
                let h2 = adr.replace(/(?:\\[r])+/g, "");
                h2 = h2.replace(/(?:\\[n])+/g, "[ENTER]");
                h2 = h2.split("[ENTER]");
                let h = h2[1] + ", "+ h2[2];
                return h;
                
            } catch (error) {
                return "";                
            }
        }
  }, 
  data() {
    return {
      loaded : '',
      hotel: {},
      showHint: true
    }
  },
  mounted() {
  //  http://localhost:8080/.../DomNav/TOOLS/Webhotel.nsf/cmd?openAgent&cmd=getHotelInfo&key=FD7468EA25C1C576C12585D50063D367
  let key = this.$store.state.statusHotelInfoKey;
   let url = hop.getHotelOverviewURL(window.location) + "&cmd=getHotelInfo&key=" + key + "&lng=" + this.$i18n.locale; //'FD7468EA25C1C576C12585D50063D367'; // this.hotelkey;

  axios.request({
        method: "get",
        url: url,
        crossDomain: true
      }).then(  resp => {  
        if(resp.data != undefined) {
          this.hotel = resp.data;
          this.showHint = false
        }
      })
  }
}
</script>

<style>
.adressenPosition {
   position: relative;
   top: -30px;
}
.dnBorderX {
  border: solid 1px red;
}

@media (max-width: 1000px) {
    .tab1 {
        display: none;
    }
    .tab2 { 
        display: block;
    }
}
@media (min-width: 1001px) {
    .tab1 {
        display: block;
    }
    .tab2 { 
        display: none;
    }
}
</style>