<template>
<span>
  <!-- <button @click="click"></button> -->
    <label class="label">{{label}}</label>
    <div class="datepicker js-datepicker" :id="idpicker">
        <input 
          :id="id1" 
          type="text" 
          class="input" 
          autocomplete="off" 
          v-model="dateValue" 
          @change="changeValue"
          aria-label="Wählen Sie ein Datum" 
          placeholder="Wählen Sie ein Datum" 
          />

        <input type="hidden" :id="id2" />
    </div>
</span>
</template>

<script>
/* eslint-disable no-undef */
import dev from '@/lib/devtools'
import hop from '@/lib/hoptools' 

export default {
  mixins: [dev, hop],
  props: ['label', 'idpicker', 'params'],
  data() {
    return {
      dateValue: '',
      id1: null,
      id2: null,
      id3: null,
      datepicker: null,
      dateLabelDE : {
              previousMonth : 'Vorheriger Monat',
              nextMonth     : 'Nächster Monat',
              months        : ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
              weekdays      : ['Sontag', 'Montag', 'Dienstag','Mittwoch','Donnerstag', 'Sonnabend'],
              weekdaysShort : ['So','Mo','Di','Mi','Do','Fr','Sa']
            },
      dateLabelEN : {
              previousMonth : 'previous month',
              nextMonth     : 'next month',
              months        : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
              weekdays      : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Saturday'],
              weekdaysShort : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            }
    }
  },
  watch:{
    //** reagiert auf  Spracheinstellung via 'computed':'lng' */
     lng: function(n,o){
      //  console.log("sprache => " + n);
      //  dev.logJsonL("labelDE", this.dateLabelDE,0);
      let dv = dev.getDate(this.dateValue);
       if(n == 'de') {
          this.datepicker.pikaday.i18n = this.dateLabelDE;
          this.datepicker.opts.i18n = this.dateLabelDE;
          this.datepicker.pikaday._o.i18n = this.dateLabelDE;
          // this.dateValue = dev.getDateStringDE(dv)
       } else {
          this.datepicker.pikaday.i18n = this.dateLabelEN;
          this.datepicker.opts.i18n = this.dateLabelEN;
          this.datepicker.pikaday._o.i18n = this.dateLabelEN;
          // this.dateValue = dev.getDateStringEN(dv)
       }
     },
    // reagiert auf Datums-Änderungen und setzt die VA-Vorgaben, über $store via 'computed':'vaDate'
    vaDate: function(n,o){
      // console.log("setDate=" + this.idpicker + "-" + n);
      // console.log("window.location.port="+window.location.port + " ... " + dev.isDomino());
      // this.datepicker.pikaday.setDate(dev.getDate(n))

      // let datStart = dev.getDate(this.$store.state.vaObj.start);
      // this.datepicker.pikaday.setMinDate(datStart)
      // let datEnde = dev.getDate(this.$store.state.vaObj.ende);
      // this.datepicker.pikaday.setMaxDate(datEnde)

      this.datepicker.pikaday.setDate(dev.getDate(n))
      this.datepicker.pikaday.gotoDate(dev.getDate(n))
     // this.datepicker.setInputValue(dev.getDate(n))
        let dv = dev.getDate(n);
        // if(this.isDE()){
          this.dateValue = dev.getDateStringDE(dv)
        // } else {
        //   this.dateValue = dev.getDateStringEN(dv)
        // }
       
       this.dateValue = n; //dev.getDateStringDE(dev.getDate(n));
    },
  },
  computed: {
      lng: function(){
          return this.$i18n.locale;
      },
      vaDate: function() {
        if(this.idpicker == 'idpicker_von'){
          return this.$store.state.vaObj.start;
        } else {
          return this.$store.state.vaObj.ende;
        }
      },
    },
  methods: {
    click: function(){
          var dateLabel = {
              previousMonth : 'previous month',
              nextMonth     : 'next month',
              months        : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
              weekdays      : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Saturday'],
              weekdaysShort : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            }
              this.datepicker.pikaday.i18n = this.dateLabelEN;
              this.datepicker.opts.i18n = this.dateLabelEN;
              this.datepicker.pikaday._o.i18n = this.dateLabelEN;
               
              //  console.log(dateLabel);
      // this.datepicker.pikaday.setDate('2022-09-07')
    },
    //** {'idpicker':idpicker : 'value':event.target.Value} */
      changeValue: function (event) {
          let dv = dev.getDate(event.target.value);
          let dvS ='';
          // if(this.isDE()){ 
            dvS = dev.getDateStringDE(dv)
          // } else {
          //    dvS = dev.getDateStringEN(dv)
          // }
          // console.log(this.idpicker + ".changeDate=" + dvS + "/" + dv);  
          this.datVal = dvS // event.target.value;
          this.$emit('changedate', { 'idpicker': this.idpicker, 'value': dvS })
      },
  },
  mounted(){
      this.id1 = this._uid + "1";
      this.id2 = this._uid + "1";

      dev.logg('mounted datepicker.' + this.idpicker + "/vaStartDate=" + this.$store.state.vaStartDate)
      dev.logg('mounted datepicker.' + this.idpicker + "/vaEndeDate=" + this.$store.state.vaEndeDate)
      // console.log("this.$store.state.vaStartDate=" + this.$store.state.vaStartDate);
      if (this.datepicker === null) {
          let dphtml = document.getElementById(this.idpicker);
          this.datepicker = new LM.Datepicker($(dphtml), this.lng)
          // dev.logg('mounted datePicker; id= ' + this.idpicker)
      
          this.dateValue = this.idpicker == 'idpicker_von' ? this.$store.state.vaStartDate : this.$store.state.vaEndDate;
          // console.log(this.$store.state.vaName);
          let vaO = this.$store.state.vaObj.name ? this.$store.state.vaObj : this.getVAByName(this.$store.state.vaName)
            // dev.logJsonL("vaO", vaO,0)
          if(vaO.name){
              let datStart = dev.getDate(vaO.start)//this.$store.state.vaObj.start);
              // this.datepicker.pikaday.setMinDate(datStart)

              let datEnde = dev.getDate(vaO.ende);
              // this.datepicker.pikaday.setMaxDate(datEnde)

              if(this.$store.state.vaStartDate){
                datStart = dev.getDate(this.$store.state.vaStartDate)
              } 
              if(this.$store.state.vaEndDate){
                datEnde = dev.getDate(this.$store.state.vaEndDate)
              } 
              if (this.idpicker == 'idpicker_von') {
                this.datepicker.pikaday.setDate(datStart)
                this.datepicker.pikaday.gotoDate(datStart)
                if(this.$store.state.vaStartDate) {
                  this.dateValue = this.$store.state.vaStartDate; //vaO.start;
                } else {
                  this.dateValue = vaO.start;
                }
                dev.logg('mounted datepicker.' + this.idpicker + ' Date = ' + dev.getDateStringDE(datStart) + "/" + this.dateValue)
                // dev.logJsonL("datepicker", this.datepicker, 0)
              } else {
                  this.datepicker.pikaday.setDate(datEnde)
                  this.datepicker.pikaday.gotoDate(datEnde)
                  if(this.$store.state.vaEndDate) {
                    this.dateValue = this.$store.state.vaEndDate;//vaO.ende;
                } else {
                    this.dateValue = vaO.ende;
                }
                dev.logg('mounted datepicker.' + this.idpicker + ' Date = ' + dev.getDateStringDE(datEnde) + "/" + this.dateValue)
              }
          } else {
            dev.logg('mounted datepicker.' + this.idpicker + ' kein VA gewählt? => ' + this.dateValue)
          }
      }
  }
}
</script>

<style>
</style>