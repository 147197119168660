<template>
  <div>
     <select id="tiel" class="input input--text" v-model="selectedValue" @change="changeValue">
        <option v-for="l in titelAuswahl" :key="l">
        {{ l }}
        </option>
    </select>
  </div>
</template>

<script>
// import hop from '@/lib/hoptools'
// import dev from '@/lib/devtools';
export default {
    // mixins:[dev,hop],
    props:["extratitel"],
    data() {
        return {
            selectedValue: '',
            de: ['Dr.','Prof.' ], //'Prof. Dr.', 'Dr. med.'
            en: ['Dr.','Prof.']
        }
    },
    computed: {
        titelAuswahl: function(){
            if( this.$i18n.locale == 'en' ){
                return this.en
            } else {
                return this.de
            }
        }
    },
    methods: {
        changeValue: function(event){
            // console.log("Titel = " + event.target.value)
            this.$emit("selectData", event.target.value);
        }
    },
    mounted (){
        const DE_TITEL = "Titel";
        const EN_TITEL = "title";
        this.de.unshift( this.extratitel == "" ? "" : DE_TITEL );

// this.selectedValue            
        this.en.unshift( this.extratitel == "" ? "" : EN_TITEL );
        let isDe = this.$i18n.locale == 'de' ? true : false;
            if(isDe) {
                this.selectedValue = DE_TITEL
            } else {
                this.selectedValue = EN_TITEL
            }
    }
}
</script>

<style>
#tiel {
    color: var(--color-brand-primary); 
}
</style> 