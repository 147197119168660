<template>
    <div class="abstandboxrechts">
        <div class="">

            <div  v-if="$store.state.ez != '-'" class="row">
                <div class="col-7">
                    <div v-if="hotel.isCaravan">
                        <span class="float-r labLinks"><strong>{{ $t("Preis") }}</strong></span>
                    </div>
                    <div v-else>
                        <span class="float-r labLinks"><strong>{{ $t("Einzelzimmerpreis") }}</strong></span>
                    </div>
                </div>
                <div class="col-5 ">
                     <span class="preisSpan"><span class="float-r" style="color: var(--brand-color)"><b>{{ formatPreis(hotel.ez) }}</b></span></span><br>
                </div>
            </div>

            <div v-if="$store.state.dz != '-'" class="row">
                <div class="col-7">
                    <div v-if="hotel.isCaravan">
                        <span class="float-r labLinks"><strong>{{ $t("Preis") }}</strong></span>
                    </div>
                    <div v-else>
                          <span class="float-r labLinks"><strong>{{ $t("Doppelzimmerpreis") }}</strong></span>
                    </div>
                </div>
                <div class="col-5 ">
                     <span class="preisSpan"><span class="float-r" style="color: var(--brand-color)"><b>{{ formatPreis(hotel.dz) }}</b></span></span><br>
                </div>
            </div>
            <div class="preisInfos float-r">
            <div v-if="hotel.isCaravan">
                <div>{{ $t("proZimmerNachtCaravan") }}</div>
            </div>
            <div v-else>
                <div>{{ $t("proZimmerNacht") }}</div>
                <div v-if="hotel.isGastro" style="float: right">{{ $t("inklFrühstück") }}</div>
            </div>
            </div>
            <div v-if="!hotel.isCaravan">
                <div class="preisInfos float-c">
                    <br><br><div>{{ $t("hotel_tax")}}</div>
                </div>        
            </div>

            <br class="fClear">
            <br>

        </div>

    <!-- Abstand bei für kleine Bildschirme -->
        <div class="TestHeader"></div>

       <!-- nobutton= {{ nobutton }} -->
       <br>
        <a v-if="nobutton==undefined" @click="buchen" href="javascript:" class="buchenAbstand btn btn--right btn--secondary buchenbutton">
            {{ $t("button_jetztbuchen") }}
        </a>

       <template v-if="nobutton=='1'">
           <br>
            <a href="javascript:" @click="$store.commit('status', 'search')" class="link link--icon link--icon--left float-r">
                <i class="icon icon-farmers-market-vendor"></i> 
                <span>{{ $t("button_neueSuchanfrage") }}</span>
            </a><br>
            <a href="javascript:" @click="$store.commit('status', 'select')" class="link link--icon link--icon--left float-r abstandboxrechts">
                <i class="icon icon-real-estate-location-house-pin"></i> 
                <span>{{ $t("button_anderesHotelwählen") }}</span>
            </a>
        </template> 

    </div>
</template>

<script>
import axios from 'axios';
// import hop from '@/lib/hoptools'
import dev from '@/lib/devtools';
export default {
    props: ['hotel','nobutton'],
    methods: {
        gesamtPreis: function(){
            let preis = this.hotel.ez.replace(',','.') * this.$store.getters.getCountEZ + this.hotel.dz.replace(',','.') * this.$store.getters.getCountDZ;
            return this.formatPreis(preis)  
        },
       formatPreis: function (p) {
        var formatter = new Intl.NumberFormat('de-DE', {
          // style: 'currency',
          // currency: 'EUR',
          minimumFractionDigits: 2
        });
        let r = formatter.format((p+'').replace(',','.'))
        return r + " EUR";
     }, 
     buchen: function() {
      //  this.prebooking();
        this.$store.commit('selectedHotel', this.hotel);
        this.$store.commit('status','formular');
        this.scrollToTop();
     },
    getFormData: function(){
        
            let fd = '{';
            fd += `"rez": "${this.$store.state.ez}" ,`;
            fd += `"rdz": "${this.$store.state.dz}" ,`;
            fd += `"rvon": "${dev.getDateStringDE(dev.getDate(this.$store.state.vaStartDate))}" ,`;
            fd += `"rbis": "${dev.getDateStringDE(dev.getDate(this.$store.state.vaEndDate))}" ,`;
            fd += `"rhotel":  "${this.convertString(this.$store.state.selectedHotel.name)}" ,`;
            fd += `"rva": "${this.$store.state.vaObj.rid}" ,`;
            fd += `"rvaname": "${this.convertString(this.$store.state.vaName)}" ,`;
            fd += `"rpreis":  "${this.gesamtPreis()}" ,`;
            fd += `"rpreisez": "${this.$store.state.selectedHotel.ez}" ,`;
            fd += `"rpreisdz": "${this.$store.state.selectedHotel.dz}" ,`;

            fd += ` "sprache": "${this.$i18n.locale}" ,`;
            fd += ` "status" : "ok"`;
            fd += `}`;

            return fd;
    }
  }
}
</script>

<style scoped>
.TestHeader {
        height: 40px;
        /* background-color: black; */
    }

    @media only screen and (min-width: 768) {
        .TestHeader {
            height: 20px;
            /* background-color: white; */
        }
    }

.labLinks {
    position: absolute;
    right: -7px;
}
.preisInfos{
    font-size: .8em;
}
.float-c{
    padding-top: 5%;
    margin: 0%;
    text-align: right;
    /* clear: both; */
}
.float-r{
    padding: 0%;
    margin: 0%;
    float: right;
}
.preisSpan {
    clear: both;
    text-align: right;
    /* width: 10px; */
}
.buchenbutton {
    float: right;
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.abstandboxrechts{       
    padding-top: 10px;
}
</style>
