<template>
  <div class="container">
     <!-- {{ $store.state.hotelSearchResult.count }} - Hotels wurden gefunden -->
     <!-- v-if="hotelCount>0" -->
    <template v-if="hotelCount>0">

    <!-- <hop_sortresult :hotelCount="hotelCount" />  -->

      <span
        v-for="h in $store.state.hotelSearchResult.hotels"
        :key="h.name"
      >
        <hop_result_hotel
          class="hotelFound"
          :hotel="h"
          @buchen="buchen(h)"
        />
        <!-- {{ h.name }} <br /> -->
      </span>

    </template>
    <template v-else>
      <div class="alert alert--danger" role="alert">
        <strong><u>{{ $t("hotel_nichtgefunden1") }}</u></strong><br>
        {{ $t("hotel_nichtgefunden2") }}
          <br>
          <a href="javascript:" @click="$store.commit('status', 'search')" class="link link--icon link--icon--left">
            <i class="icon icon-farmers-market-vendor"></i> 
          <span>{{ $t("button_neueSuchanfrage") }}</span>
        </a>
      </div>
    </template>
      <!-- <button v-on:click="click">test</button> -->
    <!-- </span> -->

  </div>
</template>

<script>
import hop_result_hotel from "./hop_result_hotel";
import hop from './../../hop_connection'
import Vue from "vue"

export default {
  props: ["hotels"],
  data: function() {
    return {
      hotelSearchResultFilter: [],
    };
  },
  components: {
    // hotel,
    hop_result_hotel,
    // hop_sortresult,
  },
  methods: {
    buchen: function(h) {
      // console.log("buchen Hotelliste / Key => " + h.name);
      this.$emit("buchen", h)
    },
    click() {
      // console.log("click..." + typeof this.hotels + "=>" + this.hotels);
    },
  },
  computed: {
      hotelsFound() {
        return this.$store.state.hotelSearchResult.hotels;
      },
      hotelCount: function(){
        return this.$store.state.hotelSearchResult.hotels.length
    },
    searchResult: function(){
      var ret = this.hotels.length + " Hotels wurden gefunden für "
      ret += this.$store.getters.getBookingDays + " Übernachtungen und " 
      ret += this.$store.getters.getCountZimmer + " Zimmer "
      return ret;
    }, 
    foundHotels: function() {
      return this.hotels.length > 0;
    },
  },
  ready() {
    // console.log("ready load ... hop result hotelliste ...");
    // console.log(typeof this.hotels);
  },
};
</script>
.hotelFound { padding: 10px; margin: 10px; }
<style>
.hotelFound {
  /* width: 100%; */
  margin-top: 15px;
}
</style>
