<template>
  <div>
      <label class="label la" for="veranstaltung">{{ $t("search_Veranstaltung") }}</label>
        <!-- :value="getVASelect" -->
      <select
        id="veranstaltung"
        ref="VA"
        
        @change="changeVASelect"
        v-model="selected"
        class="select input input--text">
        <option v-for="va in vers" :key="va.rid">
          <!-- <template v-if="va.selectable='0'"> -->
          {{ va.name }}
        </option>
      </select>

  </div>
</template>

<script>
import dev from '@/lib/devtools';
import hop from '@/lib/hoptools';

export default {
  mixins: [dev],
  props:['vers'],
  data() {
    return {
      selected : '',
    }
  },
  computed:{
    vaName: function(){
     return this.$store.state.vaName
    }
  },
  watch:{
    vaName: function(n,o){
      // console.log("watch-New vaName " + n);
      this.selected = n;
    }
  },
  methods:{
    changeVASelect: function(o) {
      this.$emit('changeva',  o.target.value); 
      // this.$store.commit('vaStartDate', '')    
      // this.$store.commit('vaEndDate', '')    
  }
  },
  mounted(){
    // dev.logg("mounted vas .. " + this.$store.state.vaName );
    this.selected = this.$store.state.vaName;
   // console.log("mounted=" + this.selected)
  }

}
</script>

<style>

</style>