import dev from '@/lib/devtools';
import axios from 'axios';
import con from '@/hop_connection'

export default {
    methods: {
        isLogging: function() {
            return false;
        },
        hoptest: function(){
            console.log("hop-tools");
        },
        isDE: function(){
            if(this.getLanguage() == 'de' || this.getLanguage() == 'DE') {
                return true
            } else {
                return false
            }
        },
        getLanguage: function(){
            return this.$i18n.locale
        },
        //**  */
        getVAByName: function(name){
            let ret = {};
            // this.$store.state.veranstaltungen.find(va => va.name == name)
            this.$store.state.veranstaltungen.forEach(va => {
                if( va.name == name || va.nameen == name ){
                    ret = va;
                    // console.log("Yes => " + name + " -- " + va.name + " <--> " + va.nameen);
                } else {
                    //   console.log("Nö => " + name + " -- " + va.name + " <--> " + va.nameen);
                }
            });
            return ret
        },
        loadVA: function(){
            let up = dev.getUrlParameter("va"); 
            up = up == undefined ? "" : up; 
            // let u = window.location.href;
            let url = con.getHotelOverviewURL(window.location) + "&cmd=vas";
            if(up != ""){
                 url += "&v=" + up
            } 

            dev.logg("load => " + url);
            let rid = dev.getUrlParameter('va');
            // dev.logg("va=" + rid);    
            axios.get(url).then(  resp => {  
                let vas = resp.data.vas;
                let vasN = [];
                // dev.logg(resp.data.vas);    
                for (let index = 0; index < vas.length; index++) {
                    const va = vas[index];
                    if(va.selectable != '1'){
                        vasN.push(va);
                    }
                }
                
                let isSingleVALoaded = false;
                let isDe = (dev.getUrlParameter('lg') == "en" ||  dev.getUrlParameter('lng') == 'en') ? false : true;
                if (rid!='' && rid!=undefined){
                    // dev.logg("Log-2")
                    let v = resp.data.vas.find(el => el.rid == rid)
                    // dev.logg(v)
                    if(v) {
                        // dev.logg(v);
                        vasN.push(v);
                        isSingleVALoaded = true;
                        this.$store.commit('veranstaltungen', vasN);
                        this.veranst = vasN;
                                // console.log("name="+name);
                        this.$store.commit('veranstaltungen', vasN);
                        this.$store.commit('vaName', v.name);
                        this.$store.dispatch('changeVAName',v.name);
                    } else {
                        this.$store.commit('status', "vanotfound");
                        this.isLoadedVA = true;
                        return;
                    }
                    this.editableVA = false
                    // setTimeout( () => {
                    //   this.$forceUpdate();
                    // }, 10);
                }

                if(isSingleVALoaded == false) {
                    this.$store.commit('veranstaltungen', vasN);
                    this.veranst = vasN;
                }

                try {
                    this.$store.dispatch('changeVAName',this.veranst[0].name);
                } catch (error) {
                    dev.logg(error)                    
                }

                this.isLoadedVA = true;
                if( dev.getUrlParameter('lg') == "en" ||  dev.getUrlParameter('lng') == 'en') {
                    this.$i18n.locale = "en";
                    this.lngSelect = "EN"
                }

                this.$store.commit('formcycle', resp.data.formcycle);
            }
            );
        }
        
    }
}