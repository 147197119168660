<template>
 <div> 
    <!-- <button type="button" class="btn" value="OK" @click="click">OK2</button> -->
    <div class="google-map bx" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
        :google="google"
        :map="map"
      />
    </template>
  </div>
</template>

<script>
// var GoogleMapsApiLoader = require('google-maps-api-loader');
import axios from 'axios';
import GoogleMapsApiLoader from 'google-maps-api-loader';
 
export default {
    props: {
        adresse: String,
        direction: String,
        title: String,
        mapConfig: Object,
        apiKey: String,
    },
    data() {
        return {
             GOOGLE_MAPS_API_KEY : "AIzaSyCyTMsQML37Rc0wsHEAeQgmG8NwrW7lqCw",
            google: null,
            map: null
        }
    },
    methods: {
        click: function() {
        //   console.log("OKey");
          this.setGeoDaten('01099 Dresden, Hoyerswerdaer Str. 27A')
        
      },
      // Karte ausrichten und einen Hotel-Marker setzen 
      setGeoDaten: function (OrtBeschreibung){
        let urlJSON = "https://maps.googleapis.com/maps/api/geocode/json?address=" + OrtBeschreibung + "&key=" + this.GOOGLE_MAPS_API_KEY;
        axios.get(urlJSON).then( resp => {
        //   console.log(resp.data);
          let lng = resp.data.results[0].geometry.location.lng;
          let lat = resp.data.results[0].geometry.location.lat;
        //   console.log("lat=" + lat + "; lng=" + lng);
          this.map.setCenter(new window.google.maps.LatLng(lat, lng));     
          this.map.setZoom(15);
          new window.google.maps.Marker( {
				position :new window.google.maps.LatLng(lat, lng),
                map : this.map,
                title: this.title,
                animation: window.google.maps.Animation.DROP,
			});
        } );
      },
    initializeMap() {
        const mapContainer = this.$refs.googleMap
        this.map = new window.google.maps.Map(
        // this.map = new this.google.maps.Map(
            mapContainer, this.mapConfig
        );
        if( this.adresse!=''){
            this.setGeoDaten(this.adresse)
        }        
        // if( this.direction!=''){
        //     console.log('oo');
        //     const directionsService = new window.google.maps.DirectionsService();
        //     const directionsRenderer = new window.google.maps.DirectionsRenderer();
        //     directionsRenderer.setMap(this.map);

        //     directionsService.route( 
        //         {
        //             origin: {
        //                 query: 'Keilstraße 2, 04105 Leipzig',
        //             },
        //             destination: {
        //                 query: 'Bahnhof, 04105 Leipzig',
        //             },
        //              travelMode: window.google.maps.TravelMode.DRIVING,
        //         },
        //         (response, status) => {
        //             if (status === "OK") {
        //                 directionsRenderer.setDirections(response);
        //             } else {
        //                 window.alert("Directions request failed due to " + status);
        //             }
        //         }
        //     );

        // }
        }
    },
    async mounted() {
        const googleMapApi = await GoogleMapsApiLoader({
            libraries: ['places'],
            apiKey: this.apiKey
        }).then(
        function(googleApi) {
            var autocomplete = new googleApi.maps.places.AutocompleteService();
            // console.table(autocomplete);
    }, function(err) {
        console.error(err);
    }
        );
        this.google = googleMapApi
        this.initializeMap()
    }
}
</script>

<style>
.bx {
    border: solid 1px var(--color-brand-primary);
    height: 500px;
}
</style>