<template>
  <div>
    <div v-if="isDe">
      <h2>Hotel Reservation Team</h2>
          <p>
      <a href="mailto:hotelreservation@leipziger-messe.de">hotelreservation@leipziger-messe.de</a><br>
      Telefon: +49 341 678-6860<br>
      <br>
      Servicezeit:<br>
      Montag bis Freitag: 09:00 – 17:00 Uhr (CET)<br>
      Außerhalb dieser Zeit nehmen wir Ihre Anfragen gern per E-Mail entgegen: <a href="mailto:hotelreservation@leipziger-messe.de">hotelreservation@leipziger-messe.de</a><br>
      <br>
          </p>
          <hr>
        <a href="javascript:" @click="$store.commit('status', 'search')" class="link link--icon link--icon--left">
            <i class="icon icon-farmers-market-vendor"></i> 
            <span>zurück zur Suche</span>
        </a>
      </div>

      <div v-else>

      <h2>Hotel Reservation Team</h2>
          <p>
      <a href="mailto:hotelreservation@leipziger-messe.de">hotelreservation@leipziger-messe.de</a><br>
      Phone: +49 341 678-6860<br>
      <br>
      Service time:<br>
      Monday to Friday: 09:00 - 17:00 (CET)<br>
      Outside of this time, we will gladly accept your inquiries by e-mail: <a href="mailto:hotelreservation@leipziger-messe.de">hotelreservation@leipziger-messe.de</a><br>
      <br>
          </p>
          <hr>
        <a href="javascript:" @click="$store.commit('status', 'search')" class="link link--icon link--icon--left">
            <i class="icon icon-farmers-market-vendor"></i> 
            <span>back to search</span>
        </a>
      </div>

      </div>

  <!-- </div> -->
</template>

<script>
export default {
computed:{
  isDe:function() {
    return this.$i18n.locale == "de"
   }
}
}
</script>

<style>

</style>