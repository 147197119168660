<template>
  <div>
    <label for="datepicker" class="label">{{ label }}</label>
    <div class="datepicker js-datepicker">
      <input
        :id="id"
        type="text"
        class="input"
        :value="dateValue"
        @change="changeValue" 
        autocomplete="off"
      />
      <input type="hidden" id="datepicker-timestamp" />
    </div>
  </div>
</template>

<script>
/*global LM, $, Datepicker */
import dev from "@/lib/devtools";
import hop from "@/lib/hoptools";

export default {
  mixins: [dev, hop],
  props: ["dateValue","label", "id", "value"],
  data() {
    return {
    //   dateValue: this.value,
      datepicker: null,
      dateLabelDE: {
        previousMonth: "Vorheriger Monat",
        nextMonth: "Nächster Monat",
        months: [
          "Januar",
          "Februar",
          "März",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Dezember",
        ],
        weekdays: [
          "Sontag",
          "Montag",
          "Dienstag",
          "Mittwoch",
          "Donnerstag",
          "Sonnabend",
        ],
        weekdaysShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      },
      dateLabelEN: {
        previousMonth: "previous month",
        nextMonth: "next month",
        months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        weekdays: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Saturday",
        ],
        weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      },
    };
  },
  methods: {
    changeValue:function(event) {
        // console.log("push Value=" + event.target.value);
        this.$emit("selectData", event.target.value); // Das ausgewählte Datum an das Elternkomponente zurückgeben
    },
  },
  watch: {
    "$i18n.locale": function (n) {
      if (n == "de") {
        this.datepicker.pikaday.i18n = this.dateLabelDE;
        this.datepicker.opts.i18n = this.dateLabelDE;
        this.datepicker.pikaday._o.i18n = this.dateLabelDE;
        //  this.dateValue = dev.getDateStringDE(dv)
      } else {
        this.datepicker.pikaday.i18n = this.dateLabelEN;
        this.datepicker.opts.i18n = this.dateLabelEN;
        this.datepicker.pikaday._o.i18n = this.dateLabelEN;
      }
    }    
  },
  mounted() {
    $(".js-datepicker").each(function () {
      this.datepicker = new LM.Datepicker($(this), "");
    });
    if (this.datepicker === null) {
      let dphtml = document.getElementById(this.id);
      this.datepicker = new LM.Datepicker($(dphtml), "de");
    }
  },
};
</script>

<style></style>
