<template>
  <!-- <div class="container"> -->
  <div class="card js-card">
    <div class="card__title card__title--highlight">
      <div class="card__title__left abstandlinks">
        <br />
        <h1>{{ $t("reg_ihreDaten") }}</h1>
      </div>
    </div>
    <div class="card__body abstandlinks">
      <!-- Form -->
      <form style="margin-right: 8px">
        <!-- <label for="BeA" class="label">Anrede</label> -->
        <div class="row">
          <div class="col-12 col-md-6 form-group">
            <form_anrede class="aLeft custom-radio" v-model="BAnrede" />
          </div>
          <div class="col-12 col-md-6 form-group">
            <!-- <label for="titel" class="label">{{ $t("reg_titel") }}</label> -->
            <form_titel v-on:selectData="selectUserTitel"></form_titel>
         </div>
        </div>

        <!-- Vor- Nachname  -->
        <div class="row">
          <div
            class="col-12 col-md-6 form-group"
            v-bind:class="[showError && BNachname == '' ? 'is-invalid' : '']"
          >
            <label for="BNachname" class="label">{{ $t("reg_name") }} *</label>
            <input
              id="BNachname"
              class="input input--text"
              v-model="BNachname"
            />
          </div>
          <div
            class="col-12 col-md-6 form-group"
            v-bind:class="[showError && BVorname == '' ? 'is-invalid' : '']"
          >
            <label for="BVorname" class="label"
              >{{ $t("reg_vorname") }} *</label
            >
            <input id="BVorname" class="input input--text" v-model="BVorname" />
          </div>
        </div>

        <!-- Firma Strasse -->
        <div class="row">
          <!-- v-bind:class="[showError && BFirma=='' ? 'is-invalid':'']" -->
          <div class="col-12 col-md-6 form-group">
            <label for="BFirma" class="label">{{ $t("reg_firma") }}</label>
            <input id="BFirma" class="input input--text" v-model="BFirma" />
          </div>
          <div
            class="col-12 col-md-6 form-group"
            v-bind:class="[showError && BStrasse == '' ? 'is-invalid' : '']"
          >
            <label for="BStrasse" class="label"
              >{{ $t("reg_strasse") }} *</label
            >
            <input id="BStrasse" class="input input--text" v-model="BStrasse" />
          </div>
        </div>

        <!-- PLZ Ort -->
        <div class="row">
          <div
            class="col-12 col-md-6 form-group"
            v-bind:class="[showError && BPLZ == '' ? 'is-invalid' : '']"
          >
            <label for="BPLZ" class="label">{{ $t("reg_plz") }} *</label>
            <input id="BPLZ" class="input input--text" v-model="BPLZ" />
          </div>
          <div
            class="col-12 col-md-6 form-group"
            v-bind:class="[showError && BOrt == '' ? 'is-invalid' : '']"
          >
            <label for="BOrt" class="label">{{ $t("reg_ort") }} *</label>
            <input id="BOrt" class="input input--text" v-model="BOrt" />
          </div>
        </div>

        <!-- Tel E-MAil -->
        <div class="row">
          <div
            class="col-12 col-md-6 form-group"
            v-bind:class="[showError && BTel == '' ? 'is-invalid' : '']"
          >
            <label for="BTel" class="label"
              >{{ $t("reg_telefonnummer") }} *</label
            >
            <input id="BTel" class="input input--text" v-model="BTel" />
          </div>
          <div
            class="col-12 col-md-6 form-group"
            v-bind:class="[showError && BEMail == '' ? 'is-invalid' : '']"
          >
            <label for="BEMail" class="label">{{ $t("reg_email") }} *</label>
            <input id="BEMail" class="input input--text" v-model="BEMail" />
          </div>
        </div>

        <!-- Land -->
        <div class="row">
          <div
            class="col-12 col-md-6 form-group"
            v-bind:class="[showError && BLand == '' ? 'is-invalid' : '']"
          >
            <label for="BLand" class="label">{{ $t("reg_land") }} *</label>
            <select id="BLand" class="input input--text" v-model="BLand">
              <option v-for="l in laender" :key="l">
                {{ l }}
              </option>
            </select>
          </div>
          <div class="col-12 col-md-6 form-group" />
        </div>

        <!-- 
        <div class="row">
           <form_anrede class="col-12" v-model="P1Anrede"/>
        </div>
        <div class="row">
          <div class="col-lg-6 form-group" v-bind:class="[showError && P1Vorname=='' ? 'is-invalid':'']">
            <label class="label" for="P1Vorname">Vorname</label>
            <input id="P1Vorname" v-model="P1Vorname" class="input input--text">
          </div>
          <div class="col-lg-6 form-group" v-bind:class="[showError && P1Nachname=='' ? 'is-invalid':'']">
            <label class="label" for="P1Nachname">Nachname</label>
            <input id="P1Nachname" v-model="P1Nachname" class="input input--text">
          </div>
        </div>

        <hr> -->

        <!-- Anreisewunsch -->
        <div class="row">
          <div class="col-12 col-md-12 form-group">
            <span class="custom-checkbox">
              <input id="BWAnreise" type="checkbox" v-model="BAnreiseTermin" @change="changeDateWunsch"/>
              <label for="BWAnreise" value="1">{{ $t("reg_anreisewunsch") }}</label>
            </span>
          </div>
        </div>

<!-- Anreiswunsch Datumseingaben , v-if="BAnreiseTermin"-->
          <div class="row" v-if="BAnreiseTermin">
            <div class="col-12 col-md-6 form-group">
              <datePicker :dateValue="this.$store.state.vaObj.start" v-on:selectData="selectValueAW1" :label="labelAnreise" id="dpWunsch1"></datePicker>
            </div>
            <div class="col-12 col-md-6 form-group">
              <datePicker :dateValue="this.$store.state.vaObj.ende" v-on:selectData="selectValueAW2" :label="labelAbreise" id="dpWunsch2"></datePicker>
            </div>
          </div>

        <!-- Bemerkung -->
        <div class="row">
          <div class="col-12 form-group">
            <label for="BBemerkung" class="label">{{
              $t("reg_wünsche")
            }}</label>
            <textarea id="BBemerkung" class="textarea" v-model="BBemerkung" />
          </div>
        </div>

        <!-- Spätanreise -->
        <div class="row">
          <div class="col-12 form-group">
            <span class="custom-checkbox">
              <input id="BSpat" type="checkbox" v-model="BSpaetanreise" />
              <label for="BSpat" value="1">{{ $t("reg_spätanreise") }}</label>
            </span>
          </div>
        </div>

        <h3>{{ $t("reg_anreisendeGäste") }}</h3>
        <!-- Reise selber an -->

        <!-- <div class="row">
          <div class="col-12 form-group">
            <span class="custom-checkbox">
              <input id="BAnreise" type="checkbox" v-model="BAnreise">
              <label for="BAnreise" value="1" >Ich reise selbst an.</label>
            </span>
          </div>
        </div> -->

        <!-- v-bind:anreise="BAnreise" v-bind:v1="BVorname" v-bind:v2="BNachname"  v-bind:v3="BAnrede" -->
        <form_userdata
          v-if="countPersonen >= 1"
          :personlabel="getPersonLabel(1)"
          v-model="Person1"
        />
        <form_userdata
          v-if="countPersonen >= 2"
          :personlabel="getPersonLabel(2)"
          v-model="Person2"
        />
        <form_userdata
          v-if="countPersonen >= 3"
          :personlabel="getPersonLabel(3)"
          v-model="Person3"
        />
        <form_userdata
          v-if="countPersonen >= 4"
          :personlabel="getPersonLabel(4)"
          v-model="Person4"
        />
        <form_userdata
          v-if="countPersonen >= 5"
          :personlabel="getPersonLabel(5)"
          v-model="Person5"
        />
        <form_userdata
          v-if="countPersonen >= 6"
          :personlabel="getPersonLabel(6)"
          v-model="Person6"
        />
        <form_userdata
          v-if="countPersonen >= 7"
          :personlabel="getPersonLabel(7)"
          v-model="Person7"
        />
        <form_userdata
          v-if="countPersonen >= 8"
          :personlabel="getPersonLabel(8)"
          v-model="Person8"
        />

        <!-- AGB Messehotels -->
        <div class="row">
          <div
            class="col-12 form-group"
            v-bind:class="[showError && BAGB == '' ? 'is-invalid' : '']"
          >
            <span class="custom-checkbox">
              <input id="BAGB" type="checkbox" v-model="BAGB" />
              <label for="BAGB" value="1"
                >{{ $t("reg_hotelagb_beginn") }}
                <li class="sub-footer__link-list__item">
                  <a target="_blank" @click="urlagb()" href="javascript:">{{
                    $t("reg_hotelagb_link")
                  }}</a>
                </li>
                <br />
                {{ $t("reg_datenschutz_beginn") }}
                <li class="sub-footer__link-list__item">
                  <a target="_blank" @click="urldatenschutz()">{{
                    $t("reg_datenschutz_link")
                  }}</a>
                </li>
                {{ $t("reg_datenschutz_ende") }}
              </label>
            </span>
            <br />
            <small>{{ $t("reg_plicht") }}</small>
          </div>
        </div>
      </form>

      <hop_captcha v-model="captcha" />
      <button class="btn btn--primary" @click="checkBookingAndSend">
        {{ $t("reg_buttonabsenden") }}
      </button>

      <template v-if="isTestButtons">
        &#160;
        <button class="btn btn--primary" @click="setTestData">
          Test-Daten
        </button>
        <!-- <button class="btn btn--primary" @click="ttt">
          TTT
        </button> -->

        <!-- <button class="btn btn--primary" @click="test"> -->
        <!-- .
        </button> -->
      </template>

      <br /><br />
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
/*global LM, $, Datepicker */
import datePicker from "@/components/formular/form_DatePicker.vue"
import i18n from "./../../i18n.js";
import dev from "@/lib/devtools";
import form_anrede from "./form_anrede";
import form_titel from "./form_titel";
import form_userdata from "./form_userdata";
import hop from "./../../hop_connection";
import axios from "axios";
import hop_captcha from "./../captcha.vue";

export default {
  mixins: [dev, hop],
  props: [""],
  data() {
    return {
      helpPerNr: '',
      awDate1: this.$store.state.vaObj.start,
      awDate2: this.$store.state.vaObj.ende,
      BAnreiseTermin: false,
      captcha: { input: "", session: "" },
      P1Anrede: "",
      P1Vorname: "",
      P1Nachname: "",

      BAnrede: "",
      BVorname: "",
      BNachname: "",
      BTitel: "",
      BFirma: "",
      BStrasse: "",
      BPLZ: "",
      BOrt: "",
      BTel: "",
      BEMail: "",
      BLand: this.$i18n.locale == "en" ? "Germany" : "Deutschland",
      BBemerkung: "",
      BSpaetanreise: "",
      BAGB: "",

      Person1: { Anrede: "", Vorname: "", Nachname: "", Titel: "" },
      Person2: { Anrede: "", Vorname: "", Nachname: "", Titel: "" },
      Person3: { Anrede: "", Vorname: "", Nachname: "", Titel: "" },
      Person4: { Anrede: "", Vorname: "", Nachname: "", Titel: "" },
      Person5: { Anrede: "", Vorname: "", Nachname: "", Titel: "" },
      Person6: { Anrede: "", Vorname: "", Nachname: "", Titel: "" },
      Person7: { Anrede: "", Vorname: "", Nachname: "", Titel: "" },
      Person8: { Anrede: "", Vorname: "", Nachname: "", Titel: "" },
    };
  },
  components: {
    form_anrede,
    form_userdata,
    hop_captcha,
    datePicker,
    form_titel,
  },
  computed: {
    labelAnreise: function () { 
      return i18n.t("wunsch_anreise")
    },
    labelAbreise: function () { 
      return i18n.t("wunsch_abreise")
    },
    countPersonen: function () {
      return this.$store.getters.getCountPersonen;
    },
    laender: function () {
      return i18n.t("laender");
    },
    showError: function () {
      return this.$store.state.showError;
    },
    isTestButtons: function () {
      if (hop.getUrlParameter("test") == "test") {
        return true;
      } else {
        return false;
      }
    },
  },
  // watch: {
  //   BVorname: function (a, b) {
  //     this.$store.commit("bvorname", this.BVorname);
  //   },
  //   BNachname: function (a, b) {
  //     this.$store.commit("bnachname", this.BNachname);
  //   },
  //   BAnrede: function (a, b) {
  //     this.$store.commit("banrede", this.BAnrede);
  //   },
  // },
  methods: {
    selectUserTitel: function(event) {
      this.BTitel = event
    },
    selectValueAW2:function(event){
      this.awDate2 = event
      this.$store.commit('vaEndDateWunsch',event);
      // console.log("select Date-Value-2: " + this.awDate2)
    },
    changeDateWunsch: function(event) {
//      console.log("changeDateWunsch=" + this.BAnreiseTermin)
      this.$store.commit('isDateWunsch',this.BAnreiseTermin);
    },
    selectValueAW1: function(event){
      this.awDate1 = event
      this.$store.commit('vaStartDateWunsch',event);
      // console.log("select Date-Value-1: " + this.awDate1)
    },
    urlagb: function () {
      window.open(this.$t("link_agb"));
      return "";
    },
    urldatenschutz: function () {
      window.open(this.$t("link_datenschutz"));
      return "";
    },
    getPersonLabel: function (webPos) {
      let newLabel = '';
      let zimmerLabel = "";
      let countEinzelzimmer = 0
      if (this.$store.state.ezSearch != "-") {
        countEinzelzimmer = parseInt(this.$store.state.ezSearch);
      }
      if (countEinzelzimmer >= webPos) {
        newLabel = webPos + ". " + this.$t("Einzelzimmer")
        return newLabel
      }
      if (zimmerLabel == "" && this.$store.state.dzSearch != "-") {
        let z = (webPos - countEinzelzimmer) / 2; 
        let z2 = Math.round(z + 0.4);
        let personNr = (webPos - countEinzelzimmer);
        if (personNr>2) {
          do {
            personNr = personNr -2
          } while (personNr>2);
        }       
        newLabel = z2 + ". " + this.$t("Doppelzimmer") + " (" + personNr + ". " + this.$t("Person") + ")"
      }
      // return p + " " + this.$t("Person") + " " + zimmerLabel;
      return newLabel
    },
    getPersonLabel_new: function (posWeb) {
      let newLabel = '';
      let zimmerLabel = "";
      let zimmerPos = 0;
      let countEinzelzimmer = 0
      let countDoppelzimmer = 0;
      if (this.$store.state.ezSearch != "-") {
        countEinzelzimmer = parseInt(this.$store.state.ezSearch);
      }
      if (this.$store.state.dzSearch != "-") {
        countDoppelzimmer = parseInt(this.$store.state.dzSearch);
      }

        if (countEinzelzimmer >= posWeb) {
          zimmerLabel = " (" + posWeb + ". " + this.$t("Einzelzimmer") + ")";
          newLabel = posWeb + ". " + this.$t("Einzelzimmer") // p + ". " + 
          return newLabel
        }

        let personNr = (posWeb - countEinzelzimmer);
        if (personNr == 1 || personNr == 2) {
          newLabel =  (1 + countEinzelzimmer) + ". " +this.$t("Doppelzimmer") // + " " + "(1. " + this.$t("Person") +")" // z + ". " +
        } 
        if (personNr == 2 || personNr == 3) {
          newLabel =  (1 + countEinzelzimmer) + ". " +this.$t("Doppelzimmer") // + " " + "(1. " + this.$t("Person") +")" // z + ". " +
        } 
        if (personNr == 3 || personNr == 4) {
          newLabel =  (1 + countEinzelzimmer) + ". " +this.$t("Doppelzimmer") // + " " + "(1. " + this.$t("Person") +")" // z + ". " +
        } 
      // return p + " " + this.$t("Person") + " " + zimmerLabel;
      return newLabel
    },
    convertString: function (v) {
      let ret = encodeURI(v);
      return ret;
    },
    getFormData: function () {
      let fd = "{";
      fd += `"rez": "${this.$store.state.ez}" ,`;
      fd += `"rdz": "${this.$store.state.dz}" ,`;
      fd += `"rvon": "${dev.getDateStringDE(
        dev.getDate(this.$store.state.vaStartDate)
      )}" ,`;
      fd += `"rbis": "${dev.getDateStringDE(
        dev.getDate(this.$store.state.vaEndDate)
      )}" ,`;
      fd += `"rhotel":  "${this.convertString(
        this.$store.state.selectedHotel.name
      )}" ,`;
      fd += `"rva": "${this.$store.state.vaObj.rid}" ,`;
      fd += `"rvaname": "${this.convertString(this.$store.state.vaName)}" ,`;
      fd += `"rpreis":  "${this.gesamtPreis()}" ,`;
      fd += `"rpreisez": "${this.$store.state.selectedHotel.ez}" ,`;
      fd += `"rpreisdz": "${this.$store.state.selectedHotel.dz}" ,`;

      fd += ` "p9anrede": "${this.BAnrede}" ,`;
      fd += ` "p9titel": "${this.BTitel}" ,`;      
      fd += ` "p9name": "${this.convertString(this.BNachname)}" ,`;
      fd += ` "p9vorname": "${this.convertString(this.BVorname)}" ,`;
      fd += ` "p9firma": "${this.convertString(this.BFirma)}" ,`;
      fd += ` "p9strasse": "${this.convertString(this.BStrasse)}" ,`;
      fd += ` "p9plz": "${this.convertString(this.BPLZ)}" ,`;
      fd += ` "p9ort": "${this.convertString(this.BOrt)}" ,`;
      fd += ` "p9tel": "${this.convertString(this.BTel)}" ,`;
      fd += ` "p9email": "${this.convertString(this.BEMail)}" ,`;
      fd += ` "p9land": "${this.convertString(this.BLand)}" ,`;
      fd += ` "p9bem": "${this.convertString(this.BBemerkung)}" ,`;
      fd += ` "sprache": "${this.$i18n.locale}" ,`;
      fd += ` "spaetanreise":"${this.BSpaetanreise}" ,`;
      // For a = 1 To 5
      //   Call fMap.add("p" & a & "anrede", "co_anrede_" & a)
      //   Call fMap.add("p" & a & "name", "co_nachname_" & a)
      //   Call fMap.add("p" & a & "vorname", "co_vorname_" & a)
      //   Call fMap.add("p" & a & "sp", "co_sp" & a)
      //   Call fMap.add("p" & a & "nr", "co_nr" & a)
      //   Call fMap.add("p" & a & "hu", "co_hunde_" & a)
      // Next
      let countPerson = this.$store.getters.getCountPersonen;
      if (countPerson >= 1) {
        fd += ` "p1anrede": "${this.Person1.Anrede}" ,`;
        fd += ` "p1vorname": "${this.convertString(this.Person1.Vorname)}" ,`;
        fd += ` "p1name": "${this.convertString(this.Person1.Nachname)}" ,`;
        fd += ` "p1titel": "${this.convertString(this.Person1.Titel)}" ,`;
      }
      if (countPerson >= 2) {
        fd += ` "p2anrede": "${this.Person2.Anrede}" ,`;
        fd += ` "p2vorname": "${this.convertString(this.Person2.Vorname)}" ,`;
        fd += ` "p2name": "${this.convertString(this.Person2.Nachname)}" ,`;
        fd += ` "p2titel": "${this.convertString(this.Person2.Titel)}" ,`;
      }
      if (countPerson >= 3) {
        fd += ` "p3anrede": "${this.Person3.Anrede}" ,`;
        fd += ` "p3vorname": "${this.convertString(this.Person3.Vorname)}" ,`;
        fd += ` "p3name": "${this.convertString(this.Person3.Nachname)}" ,`;
        fd += ` "p3titel": "${this.convertString(this.Person3.Titel)}" ,`;
      }
      if (countPerson >= 4) {
        fd += ` "p4anrede": "${this.Person4.Anrede}" ,`;
        fd += ` "p4vorname": "${this.convertString(this.Person4.Vorname)}" ,`;
        fd += ` "p4name": "${this.convertString(this.Person4.Nachname)}" ,`;
        fd += ` "p4titel": "${this.convertString(this.Person4.Titel)}" ,`;
      }
      if (countPerson >= 5) {
        fd += ` "p5anrede": "${this.Person5.Anrede}" ,`;
        fd += ` "p5vorname": "${this.convertString(this.Person5.Vorname)}" ,`;
        fd += ` "p5name": "${this.convertString(this.Person5.Nachname)}" ,`;
        fd += ` "p5titel": "${this.convertString(this.Person5.Titel)}" ,`;
      }
      if (countPerson >= 6) {
        fd += ` "p6anrede": "${this.Person6.Anrede}" ,`;
        fd += ` "p6vorname": "${this.convertString(this.Person6.Vorname)}" ,`;
        fd += ` "p6name": "${this.convertString(this.Person6.Nachname)}" ,`;
        fd += ` "p6titel": "${this.convertString(this.Person6.Titel)}" ,`;
      }
      if (countPerson >= 7) {
        fd += ` "p7anrede": "${this.Person7.Anrede}" ,`;
        fd += ` "p7vorname": "${this.convertString(this.Person7.Vorname)}" ,`;
        fd += ` "p7name": "${this.convertString(this.Person7.Nachname)}" ,`;
        fd += ` "p7titel": "${this.convertString(this.Person7.Titel)}" ,`;
      }
      if (countPerson >= 8) {
        fd += ` "p8anrede": "${this.Person8.Anrede}" ,`;
        fd += ` "p8vorname": "${this.convertString(this.Person8.Vorname)}" ,`;
        fd += ` "p8name": "${this.convertString(this.Person8.Nachname)}" ,`;
        fd += ` "p8titel": "${this.convertString(this.Person8.Titel)}" ,`;
      }
      // const element = array[index];
      // fd += ` ,`;
      // fd += ` ,`;
      if(this.BAnreiseTermin){
        fd += ` "wAnreise": "${this.awDate1}",`;
        fd += ` "wAbreise": "${this.awDate2}",`;
      }

      fd += `"captchaInput" : "${this.captcha.input}", `;
      fd += `"captchaSession" : "${this.captcha.session}", `;
      fd += ` "status" : "ok"`;
      fd += `}`;

      return fd;
    },
    test: function () {
      let fdata = this.getFormData();
      let json = JSON.stringify(fdata);
      json = json.replace(/[\u007F-\uFFFF]/g, function (chr) {
        return "\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
      });

      let url = this.getHOPUrlJ(window.location.href) + "&cmd=request";
      // console.log("url=" + url);
      axios.post(url, json).then((resp) => {
        //  let ret = JSON.parse( resp.data);
        // console.log(resp.data);
      });
    },
    absenden: function () {
      // this.testAlert("Hello");
      let logS = false;
      let isFormOK = true;
      // console.log("Person2 = " + this.Person2.Vorname);
      // if(this.P1Vorname == '' || this.P1Nachname == '' || this.P1Anrede == ''){
      //   if (logS) console.log("verP1");
      //    isFormOK = false;
      // }
      // || this.BFirma == ''
      if (
        this.BVorname == "" ||
        this.BNachname == "" ||
        this.BStrasse == "" ||
        this.BPLZ == "" ||
        this.BOrt == "" ||
        this.BTel == "" ||
        this.BLand == "" ||
        this.BEMail == ""
      ) {
        if (logS) {
          console.log("BData");
        }
        isFormOK = false;
      }
      if (this.captcha.input == "") {
        if (logS) {
          console.log("captcha");
        }
        isFormOK = false;
      }
      if (this.BAGB == "") {
        if (logS) {
          console.log("verAGB");
        }
        isFormOK = false;
      }
      let cPersonen = this.$store.getters.getCountPersonen;
      if (logS) {
        console.log("Personen=" + cPersonen);
        console.log(
          this.Person2.Anrede +
            "/" +
            this.Person2.Vorname +
            "/" +
            this.Person2.Nachname
        );
      }
      // if( cPersonen >= 1) {
      if (
        this.Person1.Anrede == "" ||
        this.Person1.Vorname == "" ||
        this.Person1.Nachname == ""
      ) {
        if (logS) {
          console.log("verP1");
        }
        isFormOK = false;
      }
      // }
      if (cPersonen >= 2) {
        if (
          this.Person2.Anrede == "" ||
          this.Person2.Vorname == "" ||
          this.Person2.Nachname == ""
        ) {
          if (logS) {
            console.log("verP2");
          }
          isFormOK = false;
        }
      }
      if (cPersonen >= 3) {
        if (
          this.Person3.Anrede == "" ||
          this.Person3.Vorname == "" ||
          this.Person3.Nachname == ""
        ) {
          if (logS) {
            console.log("verP3");
          }
          isFormOK = false;
        }
      }
      if (cPersonen >= 4) {
        if (
          this.Person4.Anrede == "" ||
          this.Person4.Vorname == "" ||
          this.Person4.Nachname == ""
        ) {
          if (logS) {
            console.log("verP4");
          }
          isFormOK = false;
        }
      }
      if (cPersonen >= 5) {
        if (
          this.Person5.Anrede == "" ||
          this.Person5.Vorname == "" ||
          this.Person5.Nachname == ""
        ) {
          if (logS) {
            console.log("verP5");
          }
          isFormOK = false;
        }
      }
      if (cPersonen >= 6) {
        if (
          this.Person6.Anrede == "" ||
          this.Person6.Vorname == "" ||
          this.Person6.Nachname == ""
        ) {
          if (logS) {
            console.log("verP6");
          }
          isFormOK = false;
        }
      }
      if (cPersonen >= 7) {
        if (
          this.Person7.Anrede == "" ||
          this.Person7.Vorname == "" ||
          this.Person7.Nachname == ""
        ) {
          if (logS) {
            console.log("verP7");
          }
          isFormOK = false;
        }
      }
      if (cPersonen >= 8) {
        if (
          this.Person8.Anrede == "" ||
          this.Person8.Vorname == "" ||
          this.Person8.Nachname == ""
        ) {
          if (logS) {
            console.log("verP8");
          }
          isFormOK = false;
        }
      }

      // console.log(this.getFormData());
      // let formDataT  = JSON.parse(this.getFormData()); //    );
      // console.log("getCountPersonen=" + this.getCountPersonen);

      // console.log(formDataT);
      // let i = '1'
      // if(i === '1'){
      //       return;
      // }

      if (isFormOK) {
        this.$store.commit("showError", false);
      } else {
        this.$store.commit("showError", true);
        // console.log("Abbruch! Eingabedaten fehlen ...");
        return;
      }
      let formData = JSON.parse(this.getFormData()); //    );
      let url = hop.getHOPUrl(window.location.href) + "&cmd=request";
      let charset = "utf-8";
      // console.log("url-post (" + charset + ")=" + url);

      // let json = JSON.stringify(formData)
      // json  = json.replace(/[\u007F-\uFFFF]/g, function(chr) {
      //     return "\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4)
      // })
      axios
        .post(url, formData, {
          headers: {
            "content-type": "text/json;  charset=utf-8",
          },
        })
        .then((resp) => {
          //  let ret = JSON.parse( resp.data);
          if (resp.data.status == "ok") {
            this.$store.commit("banrede", this.BAnrede);
            this.$store.commit("bnachname", this.BNachname);
            this.$store.commit("bvorname", this.BVorname);
            this.$store.commit("bcode", resp.data.buchungscode);
            this.$store.commit("status", "final"); //emit("submitBooking", formData);
            this.scrollToTop();
          } else if (resp.data.status.indexOf("Captcha") !== -1) {
            this.$store.commit("showError", true);
          } else {
            // this.$t('fehlerhinweis')
            // console.log(resp.data.status + "/" + this.strRight(resp.data.status, 'Fehler=' ));
            //   alert("-" + "-" + this.strRight(resp.data.status, 'Fehler=' ));
          }
        });
    },
    strRight: function (fullString, subString) {
      if (fullString.indexOf(subString) == -1) {
        return "";
      } else {
        return fullString.substring(
          fullString.indexOf(subString) + subString.length,
          fullString.length
        );
      }
    },
    checkBookingAndSend: function () {
      let url = this.getHOPUrl(window.location.href) + "&cmd=checkbooking";
      url += "&va=" + this.$store.getters.getVAID;
      url +=
        "&an=" +
        dev.getDateStringDE(dev.getDate(this.$store.state.vaStartDate));
      url +=
        "&ab=" + dev.getDateStringDE(dev.getDate(this.$store.state.vaEndDate));
      url += "&ez=" + this.$store.state.ez;
      url += "&dz=" + this.$store.state.dz;
      url += "&hotel=" + this.$store.state.selectedHotel.name;
      url += "&code=" + this.$store.getters.getPrecode;
      axios.get(url).then((resp) => {
        if (resp.data.status != "ok") {
          this.$store.commit("status", "notprebooking");
        } else {
          this.absenden();
        }
      });
    },

    click: function () {
      // console.log("AnredeP1=" + this.P1Anrede);
    },
    ttt: function () {
      this.Person3.Vorname = "Enrico2";
      // console.log(this.Person3.Vorname);
      // console.log(JSON.stringify())
    },
    setTestData: function () {

      console.log(this.getFormData());
let t = true;
if (t) {
  return
}
      this.BAnrede = "Herr";
      this.BNachname = "Schulze";
      this.BVorname = "Enrico";
      this.BFirma = "Master of GmbH";
      this.BStrasse = "Dorftstrasse 1";
      this.BPLZ = "01099";
      this.BOrt = "Dresden";
      // this.BLand = ""
      this.BTel = "0177 999999";
      this.BEMail = "";
      this.BBemerkung = "... ?";
      this.BSpaetanreise = "1";
      this.BAGB = "1";

      // this.P1Vorname = "Enrico"
      // this.P1Nachname = "Schulze"
      // this.P1Anrede = "Herr"

      // geht nicht so ...
      // this.Person2.Anrede = "Herr"
      // this.Person2.Vorname = "Erik"
      // this.Person2.Nachname = "Meiselmann"
      // this.Person3= {Anrede:"Herr", Vorname:"Paul", Nachname:"Schulz"}
    },
    test_old: function () {
      //   console.log("P1Anrede = " + this.Person2);
      //   console.log( "=>" + i18n.t("Veranstaltung") );
      //  console.log( "=>" + i18n.t("Countrys") );
      //      var countrys = ["Ägypten", "Äthiopien", "Albanien", "Algerien", "Andorra", "Angola", "Argentinien", "Armenien", "Aruba", "Aserbaidschan", "Australien", "Bangladesch", "Belarus", "Belgien", "Bosnien-Herzegowina", "Brasilien", "Britische Jungferninseln", "Bulgarien", "Burkina Faso", "Chile", "Costa Rica", "Dänemark", "Deutschland", "Dominikanische Republik", "Ecuador", "El Salvador", "Eritrea", "Estland", "Finnland", "Frankreich", "Französisch Polynesien", "Gambia", "Georgien", "Ghana", "Gibraltar", "Griechenland", "Großbritannien", "Guatemala", "Honduras", "Hong Kong", "Indien", "Indonesien", "Iran", "Irland", "Island", "Israel", "Italien", "Japan", "Jordanien", "Kamerun", "Kanada", "Kasachstan", "Katar", "Kenia", "Kirgisistan", "Kolumbien", "Kroatien", "Kuba", "Kuwait", "Lettland", "Libanon", "Liechtenstein", "Litauen", "Luxemburg", "Madagaskar", "Malawi", "Malaysia", "Mali", "Malta", "Marokko", "Mazedonien", "Mexiko", "Monaco", "Mongolei", "Namibia", "Nepal", "Neuseeland", "Niederländische Antillen", "Niederlande", "Nigeria", "Nordkorea", "Norwegen", "Österreich", "Oman", "Pakistan", "Palästina", "Peru", "Philippinen", "Polen", "Portugal", "Republik Moldau", "Republik Montenegro", "Republik Serbien", "Ruanda", "Rumänien", "Russland", "San Marino", "Saudi-Arabien", "Schweden", "Schweiz", "Senegal", "Simbabwe", "Singapur", "Slowakei", "Slowenien", "Spanien", "Sri Lanka", "Sudan", "Südafrika", "Südkorea", "Syrien", "Taiwan", "Tansania", "Thailand", "Trinidad und Tobago", "Tschad", "Tschechische Republik", "Türkei", "Tunesien", "Turkmenistan", "Uganda", "Ukraine", "Ungarn", "Uruguay", "Usbekistan", "Vatikanstadt", "Venezuela", "Vereinigte Arabische Emirate", "Vereinigte Staaten von Amerika", "Vietnam", "Volksrepublik China", "Zypern"];
    },
  }
};
</script>

<style>
.board {
  border: solid 1px;
}
#P1AnredeH {
  margin-left: 5px;
}
.aLeft {
  margin-left: 10px;
}
.abstandAnrede {
  margin-left: 20px;
  margin-top: -5px;
}
</style>
