<template>
  <span>
    <label class="label mindestBreite" for="room">{{getLabel}}</label>
    <!-- :disabled="$store.state.status!='search'" -->
    <select id="room" :value="zValue" @change="onChange" class="select">
      <!-- js-select -->
      <option v-for="z in roomseelect" :key="z">
        {{ z }}
      </option>
    </select>
  </span>
</template>

<script>
export default {
  props: ['typ','value'],
  computed:{
    getLabel: function(){
    //  let ret = 
     return this.typ=='ez' ? this.$t("ez") : this.$t("ez")

    },
     zValue(){
      return this.typ === 'ez' ? this.$store.state.ez : this.$store.state.dz
    }
  },
  methods:{
    onChange: function(event) {
      this.$store.commit(this.typ,event.target.value);
    }
  },
  data() {
    return {
       roomseelect : this.typ == 'ez' ? ["-", "1", "2", "3", "4", "5"] : ["-", "1", "2", "3"]
    };
  },
};
</script>

<style>
</style>