<template>
  <footer class="footer js-footer">

	<!--  Social-Media  -->
	<div class="footer__row footer__social-media">
		<div class="container">
			<div class="h3 footer__headline">
				{{ $t("footer_folgen") }}
                <!-- Insta, FB, YT, Twitter -->
			</div>

			<ul class="social-bar">

                <!-- Instagramm -->
				<li class="social-bar__item">
					<a target="_blank" href="https://www.instagram.com/leipzigermesse/" class="social-btn social-btn--instagram">
						<i class="icon icon-social-instagram"></i>
					</a>
				</li>

                <!-- Facebook -->
				<li class="social-bar__item">
					<a target="_blank" href="https://www.facebook.com/leipzigermesse" class="social-btn social-btn--facebook">
						<i class="icon icon-social-media-facebook-1"></i>
					</a>
				</li>

                <!-- Youtube -->
				<li class="social-bar__item">
					<a target="_blank" href="https://www.youtube.com/user/LeipzigerMesse" class="social-btn social-btn--youtube">
						<i class="icon icon-social-video-youtube"></i>
					</a>
				</li>

                <!-- Twitter -->
				<li class="social-bar__item">
					<a target="_blank" href="https://twitter.com/leipzigermesse?lang=en" class="social-btn social-btn--twitter">
						<i class="icon icon-social-media-twitter"></i>
					</a>
				</li>

			</ul>
		</div>
	</div>

	<!--  Newsletter  -->
	

	<!--  Subfooter  -->
	<div class="sub-footer">
		<div class="container">
			<p>Leipziger Messe GmbH, Messe-Allee 1, 04356 Leipzig</p>
			<!--*/ Submarke / Subbrand */-->
			
			
			<!--*/ Legal Links */-->
			<ul class="sub-footer__link-list">
				<li class="sub-footer__link-list__item">
					<a  target="_blank" @click="urlagb()">{{ $t("footer_AGBHotelvermittlung") }}</a>
				</li>
				<li class="sub-footer__link-list__item">
					<a target="_blank" @click="urlimpressum()">{{ $t("footer_impressum") }}</a>
				</li>
				<li class="sub-footer__link-list__item">
					<a target="_blank" @click="urldatenschutz()">{{ $t("footer_datenschtz") }}</a>
				</li>
				<li class="sub-footer__link-list__item">
					<a @click="$store.commit('status', 'kontakt')" href="javascript:">{{ $t("footer_kontakt") }}</a>
				</li>
			</ul>
			<a href="javascript:window.print()" class="sub-footer__print-link link link--icon link--static link--secondary">
				<i class="icon icon-print-text"></i>
				<span>{{ $t("footer_drucken") }}</span>
			</a>
			<div class="sub-footer__text">© Leipziger Messe {{year}}. All rights reserved.</div>
		</div>
	</div>

</footer>

</template>

<script>
export default {
	computed: {
// new Date().getFullYear()
	},
	data() {
		return {
			year: new Date().getFullYear()
			}
		},
	methods: {
		urlagb: function() {
			window.open( this.$t('link_agb') );
			return "";
		},
		urldatenschutz: function() {
			window.open( this.$t('link_datenschutz') );
			return "";
		},
		urlimpressum: function() {
            window.open( this.$t('link_impressum') );
            return "";
        }
	},
}
</script>

<style>

</style>