<template>
<div class="overlay sorting js-sorting">
  
  <button class="overlay__open-button btn btn--secondary btn--icon js-overlay-open">
    <i class="icon icon-settings-slider-alternate"></i>
    <span>{{ $t("result_sortierenNach") }}</span>
  </button>

  <div class="overlay__content-wrapper">
    <div class="overlay__content-wrapper--inner">

      <div class="overlay__close-button js-overlay-close">
        <i class="icon icon-arrow-left"></i>
        <span>{{ $t("zurück") }}</span>
      </div>

      <div class="overlay__header overlay__header--mobile">
        {{ $t("Listenansicht") }}
      </div>

      <div class="overlay__content">

          <!-- Sorting Results -->
          <div class="sorting__results">
            {{ $store.state.hotelSearchResult.hotels.length }} {{ $t("result_hotelsGefunden") }}
          </div>
          
          <!-- Filter Accordion 1 -->
          <div class="filter-accordion accordion js-sorting-accordion">
            <div class="accordion__title js-accordion-trigger">
              <div class="filter-accordion__title">                  
                <div class="sub-header">{{ $t("result_sortierenNach") }}</div>
                <div class="selected">{{ $t(sort)}}</div>
              </div>
            </div>

            <div class="accordion__body">
              <!-- Mobile -->
              <div class="accordion__body__inner sorting__wrapper--mobile">
                <span class="custom-radio-btn">
                  <input id="sortierung_datum" type="radio" name="sortierung" value= "Entfernung" v-model="sort" @change="changeSort" checked>
                  <label for="sortierung_datum">{{ $t("Entfernung_zumVeranstaltungsort") }}</label>
                </span>
                <span class="custom-radio-btn">
                  <input id="sortierung_kategorie" type="radio" name="sortierung" value= "Kategorie" v-model="sort" @change="changeSort">
                  <label for="sortierung_kategorie">{{ $t("Kategorie_absteigend") }}</label>
                </span>
                <span class="custom-radio-btn">
                  <input id="sortierung_kategorie2" type="radio" name="sortierung" value= "KategorieAuf" v-model="sort" @change="changeSort">
                  <label for="sortierung_kategorie2">{{ $t("Kategorie_aufsteigend") }}</label>
                </span>
                <span class="custom-radio-btn">
                  <input id="sortierung_preis1" type="radio" name="sortierung" value= "Preis" v-model="sort" @change="changeSort">
                  <label for="sortierung_preis1">{{ $t("Preis_absteigend") }}</label>
                </span>
                <span class="custom-radio-btn">
                  <input id="sortierung_preis2" type="radio" name="sortierung" value= "PreisAuf" v-model="sort" @change="changeSort">
                  <label for="sortierung_preis2">{{ $t("Preis_aufsteigend") }}</label>
                </span>
                <span class="custom-radio-btn">
                  <input id="sortierung_datum" type="radio" name="sortierung" value= "Name" v-model="sort" @change="changeSort" checked>
                  <label for="sortierung_datum">{{ $t("Name_aufsteigend") }}</label>
                </span>
                <!-- <span class="custom-radio-btn">
                  <input id="sortierung_relevanz" type="radio" name="sortierung" value="Name" v-model="sort" @change="changeSort">
                  <label for="sortierung_relevanz">Name</label>
                </span> -->
              </div>

              <!-- Desktop -->
              <div class="sorting__wrapper sorting__wrapper--desktop">
                <select class="select" v-model="sort" @change="changeSort">
                  <option value="Entfernung" selected>{{ $t("Entfernung_zumVeranstaltungsort") }}</option>
                  <option value="Kategorie">{{ $t("Kategorie_absteigend") }}</option>
                  <option value="KategorieAuf">{{ $t("Kategorie_aufsteigend") }}</option>
                  <option value="Preis">{{ $t("Preis_absteigend") }}</option>
                  <option value="PreisAuf">{{ $t("Preis_aufsteigend") }}</option>
                  <option value="Name">{{ $t("Name_aufsteigend") }}</option>
                  <!-- <option value="Name">Name</option> -->
                </select>
                <br>
              </div>

            </div>
          </div>
          <!-- / Filter Accordion 1 -->

          <!-- Filter Accordion 2 -->
          <!-- <div class="filter-accordion accordion js-sorting-accordion"> -->
            
            <!-- <div class="accordion__title js-accordion-trigger">
              <div class="filter-accordion__title">
                <div class="sub-header">Ergebnisse pro Seite</div>
                <div class="selected">10</div>
              </div>
            </div> -->

            <!-- <div class="accordion__body"> -->
              
              <!-- Mobile -->
              <!-- <div class="accordion__body__inner sorting__wrapper--mobile">
                <span class="custom-radio-btn">
                  <input id="ergebnisAnzahl_10" type="radio" name="sortCounterRadio" v-model="counterMobil" checked>
                  <label for="ergebnisAnzahl_10">10</label>
                </span>
                <span class="custom-radio-btn">
                  <input id="ergebnisAnzahl_25" type="radio" name="sortCounterRadio"  v-model="counterMobil">
                  <label for="ergebnisAnzahl_25">25</label>
                </span>
                <span class="custom-radio-btn">
                  <input id="ergebnisAnzahl_50" type="radio" name="sortCounterRadio"  v-model="counterMobil">
                  <label for="ergebnisAnzahl_50">50</label>
                </span>
                <span class="custom-radio-btn">
                  <input id="ergebnisAnzahl_100" type="radio" name="sortCounterRadio"  v-model="counterMobil">
                  <label for="ergebnisAnzahl_100">100</label>
                </span>
              </div> -->

              <!-- Desktop -->
              <!-- <div class="sorting__wrapper sorting__wrapper--desktop">
                <select class="select" v-model="counterDesktop">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->

            <!-- </div> -->

          <!-- </div> -->
          <!-- / Filter Accordion 2 -->

      </div>

      <button class="overlay__submit-button btn btn--primary js-overlay-close" @click="setProps">
        {{$t('button_anwenden')}}
      </button>

    </div>
  </div>

    <!-- <button class="btn" @click="click"></button> -->
</div>
</template>

<script>
/*global LM,$ */
export default {
  data(){
    return {
        sort: 'Entfernung',
        sortDesktop: '',
        sortMobil: '',
        counterMobil: '10',
        counterDesktop: '10',
    }
  },
  methods: {
    changeSort: function() {
      // console.log("changeSort => " + this.sort);
      let hotelSearchResult = this.$store.state.hotelSearchResult 
      let hotels = [];
      for(let j in hotelSearchResult.hotels){
        hotels.push(hotelSearchResult.hotels[j])
      }
      let direct = 0;
      let filter = this.sort;
      this.$store.dispatch("sortHotelsByFilter", filter, direct);
    },
    click: function() {
        // console.log("Counter: " + this.counterDesktop + " / " + this.counterMobil);
        // console.log('Sort: ' + this.sortDesktop+ " / " + this.sortMobil);
        this.sortCounter = "25";
    },
    setProps: function() {
      // ergebnisAnzahl
    },
  },
  mounted(){
    if( typeof LM !== 'undefined'){
      $('.js-sorting').each(function () {
        new LM.Sorting( $(this) );
      });
      let sortVA = this.$store.state.vaObj.hotelsort;
      if(sortVA == "1"){
          this.sort = "Entfernung";
      } else if(sortVA == "2"){ 
          this.sort = "KategorieAuf";
      } else if(sortVA == "3"){
           this.sort = "Kategorie";
      } else if(sortVA == "4"){         
          this.sort = "Preis";
      } else if(sortVA == "5"){
          this.sort = "PreisAuf";
      } else if(sortVA == "6"){
          this.sort = "Name"
      }
      this.changeSort();
    }
    // console.log("Sorting => " + this.sort);
    // console.log(this.$store.state.vaObj.hotelsort);
  },
};
</script>

<style></style>
