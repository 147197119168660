<template>
  <header class="header js-header">
    <!-- Top row -->
    <div class="header__top-row">
      <div class="header__top-row__wrapper container">
        <!-- Optional: Upcoming events -->
        <div class="header__upcoming-event">
          <!-- <span class="header__upcoming-event__date">
            Hotel Online Portal
          </span> -->
          <span class="header__upcoming-event__name truncate-text">
            
          </span>
        </div>
        <!-- / Upcoming events -->

        <!-- Link list -->
        <!-- <ul class="header__link-list">
          <li class="header__link-list__item">
            <a class="link link--unstyled" href="#">News</a>
          </li>
          <li class="header__link-list__item">
            <a class="link link--unstyled" href="#">Presse</a>
          </li>
          <li class="header__link-list__item">
            <a class="link link--unstyled" href="#">Karriere</a>
          </li>
          <li class="header__link-list__item">
            <a class="link link--unstyled" href="#">Anfahrt</a>
          </li>
        </ul> -->
        <!-- / Link list -->

        <!-- Action list -->
        <ul class="header__action-list header__action-list--desktop">
          <!-- <li class="header__action-list__item">
            <a
              href="#"
              class="header__login-link link link--unstyled link--secondary"
            >
              <i class="icon icon-single-man-circle-alternate"></i>
              <span>Login</span>
            </a>
          </li> -->
          <li class="header__action-list__item">
            <label for="header-language" class="sr-only">{{ $t("head_link_selectSparche") }}</label>
              <!-- js-header-language-select -->
            <select
              id="header-language"
              class="select" @change="changeLang($event)"  
              v-model="lngSelect" >
              <option value="DE">
                DE
              </option>
              <option value="EN">
                EN
              </option>
            </select>
          </li>
        </ul>
        <!-- / Action list -->
      </div>
    </div>
    <!-- / Top row -->

    <!-- Main row -->
    <div class="header__main-row">

      <div class="header__main-row__wrapper container">

        <div>
          <!-- <a href="javascript:"> -->
          <template v-if="isLMLogo">
            <img class="header__branding__img" src='@/assets/images/ml-logo.png' alt="Leipziger Messe" title="Leipziger Messe" />
          </template>
          <template v-else>
            <img class="header__branding__img" src='@/assets/images/EFCS-logo.png' alt="Leipziger Messe" title="Leipziger Messe" />
          </template>
         <!-- </a> -->
         <br><br>
        </div>

        <!-- Eigenes Format für Überschrift, da <h1> zu viel Abstand besitzt ... -->
        <div class="titel1"  style="margin-left:50px; margin-bottom: 0px">
            <span style="font-size: xx-large; color: var(--color-neutral-grey-4)">{{ $t("head_titel") }}</span><br>
            <small style="color: black; margin-top:0px; margin-bottom:10px">{{ $t("head_infotext") }}</small>
            <br><br>
        </div> 

        <div class="titel2"  style="margin-left:50px; margin-bottom: 0px">
            <span style="color: var(--color-neutral-grey-4)">{{ $t("head_titel") }}</span><br>
            <span style="color: var(--color-neutral-grey-4); font-size: xx-small; margin-top:0px; margin-bottom:10px">{{ $t("head_infotext") }}</span>
            <br><br>
        </div> 


        <!-- Branding Messe Logo LINKS-->
        <!-- / Branding -->

        <!-- Search bar -->
        <!-- / Search bar -->

        <!-- Parent branding -->
        <div class="header__branding header__branding--parent">

          <!-- Optional Messe Logo Rechts -->
        </div>

        <!-- / Parent branding -->

        <!-- Mobile trigger -->
        <div class="header__mobile-triggers">
          <!-- <button class="btn btn--unstyled js-search-bar-toggle-trigger">
            <span class="sr-only">Suche ein-/ausblenden</span>
            <i class="icon icon-search-alternate"></i>
          </button> -->
          <button class="btn btn--unstyled js-navigation-open">
            <span class="sr-only">{{ $t("head_link_openMenue")}}</span>
            <i class="icon icon-navigation-menu"></i>
          </button>
        </div>
        <!-- / Mobile trigger -->
      </div>

      <div class="header__main-row__wrapper container">
         <!-- <small style="color: black; margin-top:0px; margin-bottom:10px">{{ $t("head_infotext") }}</small> -->
      </div>

    </div>
    <!-- / Main row -->

    <!-- Main row CLONE -->
    <div class="header__main-row--clone">
      <div class="header__main-row__wrapper container">
        <!-- Branding -->
        <div class="header__branding">
          <a href="#">
            <img class="header__branding__img header__branding__img--small" src="@/assets/images/ml-logo.png"  alt="Leipziger Messe" title="Leipziger Messe" />
          </a>
        </div>
        <!-- / Branding -->

        <!-- Parent branding -->
        <div class="header__branding header__branding--parent">
        </div>
        <!-- / Parent branding -->

        <!-- Mobile trigger -->
        <div class="header__mobile-triggers">
          <button class="btn btn--unstyled js-navigation-open">
            <span class="sr-only">{{ $t("head_link_openMenue")}}</span>
            <i class="icon icon-navigation-menu"></i>
          </button>
        </div>
        <!-- / Mobile trigger -->
      </div>
    </div>
    <!-- / Main row CLONE -->

    <!-- Navigation -->
    <div class="header__navigation-row js-navigation-wrapper">
      <div class="container">
        <nav class="navigation">
          <!-- Close button -->
          <button class="navigation__close-button btn btn--unstyled js-navigation-close">
            <span>{{ $t("head_link_close")}}</span>
            <i class="icon icon-close"></i>
          </button>

          <!-- Home link -->
          <a href="javascript:" class="navigation__home-link">
            <span>{{ $t("head_link_zurStartseite")}}</span>
            <i class="icon icon-arrow-right-1"></i>
          </a>

          <!-- Level 1 -->
          <ul class="navigation__list level-1">
            <!-- L1: Accordion -->
            <li class="navigation__list__item js-navigation-level-1-item">
              <!-- <a href="javascript:" class="navigation__list__text"> -->
              <a href="javascript:"  @click="openStartpage" class="navigation__list__text">
                <span>{{ $t("head_link_Startseite") }}</span>
              </a>

            </li>
            <!-- / L1: Accordion -->
              <!-- https://www.leipziger-messe.de/vertragshotels -->
            <li class="navigation__list__item js-navigation-level-1-item">
              <a target="_blank" :href="urlPartnerHotel" class="navigation__list__text">
                <span>{{ $t("head_link_PartnerHotels") }}</span>
              </a>
            </li>

            <li class="navigation__list__item js-navigation-level-1-item">
              <a @click="$store.commit('status', 'kontakt')" href="#" class="navigation__list__text">
                <span>{{ $t("head_link_Kontakt") }}</span>
              </a>
            </li>
          </ul>
          <!-- / Navigation list -->

          <!-- Additional content -->
          <div class="header__info-box">
            <!-- Action list -->
            <ul class="header__info-box__action-list">
              <li class="header__info-box__action-list__item">
                <label for="header-mobile-language" class="sr-only">Sprachwahl</label>
                <!-- js-header-language-select -->
                <select
                  id="header-mobile-language"
                  class="select" @change="changeLang($event)" 
                  v-model="lngSelect">
                  <option value="DE">
                    DE
                  </option>
                  <option value="EN">
                    EN
                  </option>
                </select>
              </li>
            </ul>

          </div>
          <!-- / Additional content -->
          
        </nav>
      </div>
    </div>
    <!-- / Navigation -->

    <!-- Navigation CLONE -->
    <div class="header__navigation-row--clone">
      <div class="container">
        <ul class="navigation__list level-1">
          <!-- L1: Accordion -->
          <li class="navigation__list__item js-navigation-clone-level-1-item">
            <a href="javascript:" class="navigation__list__text">
              <span>{{ $t("head_link_Startseite") }}</span>
            </a>
          </li>

<!-- www.leipziger-messe.de/vertragshotels -->
          <li class="navigation__list__item js-navigation-clone-level-1-item">
            <a target="_blank" :href="urlPartnerHotel" class="navigation__list__text">
              <span>{{ $t("head_link_PartnerHotels") }}</span>
            </a> 
          </li>

          <!-- <li class="navigation__list__item js-navigation-clone-level-1-item">
            <a target="_blank" href="https://www.leipziger-messe.de/besucher/unterkunft/nachhaltigkeit-partner-hotels/" class="navigation__list__text">
              <span>Nachhaltigkeit Partner-Hotels</span>
            </a>
          </li> -->

          <li class="navigation__list__item js-navigation-clone-level-1-item">
            <a @click="$store.commit('status', 'kontakt')" href="#" class="navigation__list__text">
              <span>{{ $t("head_link_Kontakt") }}</span>
            </a>
          </li>

        </ul>
      </div>
    </div>
    <!-- / Navigation CLONE -->

    <!-- <button class="btn" @click="click">.</button> -->

  </header>
</template>

<script>
import hop from './../../hop_connection';

/*global LM, $ */
export default {
  props:[],
  data() {
    return {
      lngSelect: 'DE',
      urlPartnerHotel: "https://www.leipziger-messe.de/de/hotels-sonderkonditionen/"
    }
  },
  watch:{
    '$i18n.locale': function(newVal, oldVal) {
      this.urlPartnerHotel = newVal == 'en' ? "https://www.leipziger-messe.de/en/hotels-specialconditions/" : "https://www.leipziger-messe.de/de/hotels-sonderkonditionen/";
    }
  },
  computed: {
    isLMLogo: function() {
      //https://hotelportal.leipziger-messe.de/LM/QAs/webhotel.nsf/HOPII/index.html?open&va=C1258A3F004D4D34&lg=de      
      return (hop.getUrlParameter('va') == "C1258A3F004D4D34" || hop.getUrlParameter('va') == "C125878B00268E13") ? false : true;
    },
  },
  methods: {
    getImgUrl() {
      return require('@/assets/images/ml-logo.png')
      // return './assets/images/ml-logo.png'
    },
    openStartpage: function() {
      this.$store.commit('statusGruppenFormular',false);
      this.$store.commit('status','search');
      this.$store.commit('statusHotelInfo',false);
    },
    click: function() {
      this.$i18n.locale == "en"
    //   }
    },
    changeLang: function(ev){
      if(  ev.target.value == "DE" ||  ev.target.value == "de") {
            this.$i18n.locale = "de";
      } else {
            this.$i18n.locale = "en";
     }
      // console.log("Sprache=>" + this.$i18n.locale );
    },
    setVA: function(vaName){
      this.$store.commit('statusGruppenFormular',false)
      this.$store.dispatch('changeVAName', vaName);
      this.$store.commit('status','search');
      this.$store.commit('statusHotelInfo',false);
      
  }
  },
  mounted() {
     if( hop.getUrlParameter('lg') == "en" ||  hop.getUrlParameter('lng') == 'en') {
        this.lngSelect = "EN"
      } else {
        this.lngSelect = "DE"
      }
  }
};
</script>

<style>

@media (max-width: 1000px) {
    .titel1 {
        display: none;
    }
    .titel2 { 
        display: block;
    }
}
@media (min-width: 1001px) {
    .titel1 {
        display: block;
    }
    .titel2 { 
        display: none;
    }
}
</style>
