<template>
<div class="slideshow-container">
<!-- <button class="btn" @click="click">.</button> -->
  <!-- https://www.w3schools.com/howto/howto_js_slideshow.asp -->
  <!-- https://stackoverflow.com/questions/43335477/how-to-use-setinterval-in-vue-component -->
  <!-- https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_image_overlay_slideright -->
    <!-- <div class="slideshow-container borderX" width="100%"> -->
  <!-- <div class="row"> -->
    <!-- <div class="col-12"> -->
    
    <span v-for="im in hotel.bilder.show" class="mySlides fade" :key="im" style="text-align: center" >
                    <!-- image--rounded :style="imgStyle" -->
      <img class="image image--container" :key="key" :src="hotel.bilder.path + im">
         <!-- <div class="image image--container" style="background-image: url('hotel.bilder.path + im');"></div>                             -->
   </span>
    <!-- </div> -->

    <!-- <div class="col-4"> -->
      <!-- {{hotelinfos}} -->


    <!-- </div> -->
  <!-- </div> -->

    <!-- Next and previous buttons -->
  <!-- <a class="prev" @click="plusSlides(-1)"><i class="icon icon-arrow-button-left-1-thick-bottom"></i>&#10094;</a>
  <a class="next" id="nextSwitcher" @click="plusSlides(1)"><i class="icon icon-arrow-button-right-thick-bottom"></i> &#10095;</a> -->

</div>
</template>

<script>
// import $ from 'jquery'
/*global $ */

export default {
// $store.state.selectedHotel
  props:['hotel','hotelinfos'],
    data() {
        return {
          p : 0,
          imgStyle: 'width: 940px', // 940px oder 640
          key: 9000,
          slideIndex : 1,
          timer: {}
        }
    },
    computed: {
       
    },
    created: function() {
      if( this.p==0) {
        // console.log('create slideshow .... timer');
        var slideShowTimer = this;
        this.timer = setInterval(function() {
            slideShowTimer.p++;
            if (slideShowTimer.p<4000) {
                 slideShowTimer.plusSlides(1);
                 slideShowTimer.p = 0;
            }
        }, 5000);
      }
    }, 
    methods: {
      isNotEmpty: function(va){
        if( va[0]!='' && va[0]!=undefined){
          return true;
        }  else  {
          return false;
        }
      },
      setScreenStyle: function(){
        let np = 0;
        if(  window.innerWidth <= 1038 ){
          np = 240;
          this.imgStyle = 'width: 540px'
        } else {
          np = 440
           this.imgStyle ='width: 840px' 
        }
        let wi = window.innerWidth
        let el = document.getElementById('nextSwitcher'); 
        if(el == null) {
          return;
        }
        el.style.left = ((wi- np)/2 + np - 40) + "px";
      },
      setScreenStyle_old: function(){
        let np = 0;
        if( window.innerWidth <= 1023 ){
          this.imgStyle = "width: " + (window.innerWidth -60) + "px"
        } else  if(  window.innerWidth <= 1538 ){
          np = 920;
          this.imgStyle = 'width: 920px'
        } else {
          np = 1200
           this.imgStyle ='width: 1200px' 
        }
        let wi = window.innerWidth
        let el = document.getElementById('nextSwitcher'); 
        if(el == null) {
          return;
        }
        el.style.left = ((wi- np)/2 + np - 40) + "px";
      },
      click: function(){
          // console.info(`${ screen.width } × ${ screen.height }` +  );
          // console.log('availWidth =' + window.innerWidth + " / " + this.imgStyle );
          if(  window.innerWidth <= 1023 ){
               this.imgStyle = "width: " + (window.innerWidth -20) + "px"
          } else if(  window.innerWidth <= 1538 ){
               this.imgStyle = "width: 940px"
          } else {
             this.imgStyle = "width: 1220px"
          }
          this.key +=1;
      },
        showSlides: function(n) {
          let i;
          let slides = document.getElementsByClassName("mySlides");
          if (slides.length == 0) {
            return;
          }
          this.slideIndex=n;
          if (this.slideIndex > slides.length) {this.slideIndex = 1}
          if (this.slideIndex < 1) {this.slideIndex = slides.length}
          for (i = 0; i < slides.length; i++) {
              slides[i].style.display = "none";
          }
          try {
            slides[this.slideIndex-1].style.display = "block";                      
          } catch(error) {
            console.error(error);
            console.warn((this.slideIndex-1) + " => " +  slides.length);
          }
          // setTimeout(() => {this.showSlides(1)},5000)
        },
        plusSlides: function(n) {
          // console.log("--"+this.p);
           this.showSlides(this.slideIndex += n);
        },
        reportWindowSize: function(params) {
          // console.log("resize=>" + window.innerWidth);
          this.setScreenStyle();
          this.key++;
        }
    },
    mounted() {
        this.showSlides(1);
        this.setScreenStyle()
        window.onresize = this.reportWindowSize;
        // setInterval(this.plusSlides(1), 5000); 
    },
   destroyed() {
      clearTimeout(this.timer);
  }
}
</script>

<style>
.mySlides {
  /* width: 100%; */
  margin: 10px;
  display: none;
  }

  /* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  /* margin-top: -22px; */
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 100px;
  text-align: right;
  border: solid 1px red;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}
</style>